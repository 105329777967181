import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import SettingRolesCreateDrawer from './SettingRolesCreateDrawer'
import SettingRolesTable from './SettingRolesTable'

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}
const SettingRolesGrid = ({
  createDrawer, listData, updateData,
  deleteData
}: Props) => {
  return (
    <>
      <SettingRolesTable
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
        listData={listData}
      />
      <SettingRolesCreateDrawer
        {...createDrawer}
      />
      <SettingRolesCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SettingRolesGrid
