import React from 'react'
import { Column } from 'primereact/column'
import TableUI from 'components/Table/TableUI'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import SearchUI from 'components/SearchUI/SearchUI'
import { path, pathOr } from 'ramda'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import Perms from '../../../../components/Perms'
import checkPermissions from '../../../../utils/checkPermissions'
import { TPagination } from '../../../../types'
import { numberFormat } from '../../../../utils/numberFormat'
import { trimString } from '../../../../utils/trimString'

const Card = styled.div`
  
`
const Photo = styled.div`
  margin-right: 178px;
`

const SettingSmsTemplatesTable = ({
  listData, createDrawer, updateData,
  deleteData
}) => {
  const value = pathOr([], ['data', 'data'], listData)
  const pagination = path<TPagination>(['data', 'pagination'], listData)

  const hasUpdatePerm = checkPermissions(['UPDATE'])
  const header = () => {
    return (
      <div className="flex justify-content-between">
        <Perms permissions={['CREATE']}>
          <Button
            onClick={createDrawer.onOpen}
            severity="success"
          >
            + Добавить SMS шаблон
          </Button>
        </Perms>
        <SearchUI/>
      </div>
    )
  }

  const OptionButton = (item) => {
    return (
      <Dropdown model={[
        hasUpdatePerm && {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            updateData.onOpen(item._id)
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => deleteData.onSubmit(item._id)
        }
      ].filter(Boolean)
      }/>
    )
  }

  return (
    <Card className={'w-full'}>
      <TableUI
        rowHover={true}
        pagination={pagination}
        loading={listData.loading}
        value={value}
        header={header()}
      >
        <Column field="body" header="Название" body={(item) => trimString(item.body, 150)} />
        <Column headerStyle={{ minWidth: '4rem' }} body={OptionButton} style={{ width: '5%' }} />
      </TableUI>
    </Card>
  )
}

export default SettingSmsTemplatesTable
