import React from 'react'
import {
  Eighteen,
  Eleven,
  Fifteen, FortyEight, FortyFive, FortyFour, FortyOne, FortySeven, FortySix, FortyThree, FortyTwo,
  Fourteen,
  Seventeen,
  Sixteen,
  EightyOne,
  EightyTwo,
  EightyThree,
  EightyFour,
  EightyFive,
  SeventyOne,
  SeventyTwo,
  SeventyThree,
  SeventyFour,
  SeventyFive,
  SixtyOne,
  SixtyTwo,
  SixtyThree,
  SixtyFour,
  SixtyFive,
  FiftyOne,
  FiftyTwo,
  FiftyThree,
  FiftyFour,
  FiftyFive,
  Thirteen, ThirtyEight, ThirtyFive, ThirtyFour, ThirtyOne, ThirtySeven, ThirtySix, ThirtyThree, ThirtyTwo,
  Twelve, TwentyEight, TwentyFive, TwentyFour,
  TwentyOne, TwentySeven, TwentySix, TwentyThree, TwentyTwo
} from '../media/icons/teeth'

export const TEN_TEETH = [
  {
    icon: Eleven,
    name: 'eleven',
    id: 11
  },
  {
    icon: Twelve,
    name: 'twelve',
    id: 12
  },
  {
    icon: Thirteen,
    name: 'thirteen',
    id: 13
  },
  {
    icon: Fourteen,
    name: 'fourteen',
    id: 14
  },
  {
    icon: Fifteen,
    name: 'fifteen',
    id: 15
  },
  {
    icon: Sixteen,
    name: 'sixteen',
    id: 16
  },
  {
    icon: Seventeen,
    name: 'seventeen',
    id: 17
  },
  {
    icon: Eighteen,
    name: 'eighteen',
    id: 18
  }
]

export const TWENTY_TEETH = [
  {
    icon: TwentyOne,
    name: 'twentyOne',
    id: 21
  },
  {
    icon: TwentyTwo,
    name: 'twentyTwo',
    id: 22
  },
  {
    icon: TwentyThree,
    name: 'twentyThree',
    id: 23
  },
  {
    icon: TwentyFour,
    name: 'twentyFour',
    id: 24
  },
  {
    icon: TwentyFive,
    name: 'twentyFive',
    id: 25
  },
  {
    icon: TwentySix,
    name: 'twentySix',
    id: 26
  },
  {
    icon: TwentySeven,
    name: 'twentySeven',
    id: 27
  },
  {
    icon: TwentyEight,
    name: 'twentyEight',
    id: 28
  }
]

export const THIRTY_TEETH = [
  {
    icon: ThirtyOne,
    name: 'thirtyOne',
    id: 31
  },
  {
    icon: ThirtyTwo,
    name: 'thirtyTwo',
    id: 32
  },
  {
    icon: ThirtyThree,
    name: 'thirtyThree',
    id: 33
  },
  {
    icon: ThirtyFour,
    name: 'thirtyFour',
    id: 34
  },
  {
    icon: ThirtyFive,
    name: 'thirtyFive',
    id: 35
  },
  {
    icon: ThirtySix,
    name: 'thirtySix',
    id: 36
  },
  {
    icon: ThirtySeven,
    name: 'thirtySeven',
    id: 37
  },
  {
    icon: ThirtyEight,
    name: 'thirtyEight',
    id: 38
  }
]

export const FORTY_TEETH = [
  {
    icon: FortyOne,
    name: 'fortyOne',
    id: 41
  },
  {
    icon: FortyTwo,
    name: 'fortyTwo',
    id: 42
  },
  {
    icon: FortyThree,
    name: 'fortyThree',
    id: 43
  },
  {
    icon: FortyFour,
    name: 'fortyFour',
    id: 44
  },
  {
    icon: FortyFive,
    name: 'fortyFive',
    id: 45
  },
  {
    icon: FortySix,
    name: 'fortySix',
    id: 46
  },
  {
    icon: FortySeven,
    name: 'fortySeven',
    id: 47
  },
  {
    icon: FortyEight,
    name: 'fortyEight',
    id: 48
  }
]

export const EIGHTY_TEETH = [
  {
    icon: EightyOne,
    name: 'eightyOne',
    id: 81
  },
  {
    icon: EightyTwo,
    name: 'eightyTwo',
    id: 82
  },
  {
    icon: EightyThree,
    name: 'eightyThree',
    id: 83
  },
  {
    icon: EightyFour,
    name: 'eightyFour',
    id: 84
  },
  {
    icon: EightyFive,
    name: 'eightyFive',
    id: 85
  }
]

export const SEVENTY_TEETH = [
  {
    icon: SeventyOne,
    name: 'seventyOne',
    id: 71
  },
  {
    icon: SeventyTwo,
    name: 'seventyTwo',
    id: 72
  },
  {
    icon: SeventyThree,
    name: 'seventyThree',
    id: 73
  },
  {
    icon: SeventyFour,
    name: 'seventyFour',
    id: 74
  },
  {
    icon: SeventyFive,
    name: 'seventyFive',
    id: 75
  }
]

export const SIXTY_TEETH = [
  {
    icon: SixtyOne,
    name: 'sixtyOne',
    id: 61
  },
  {
    icon: SixtyTwo,
    name: 'sixtyTwo',
    id: 62
  },
  {
    icon: SixtyThree,
    name: 'sixtyThree',
    id: 63
  },
  {
    icon: SixtyFour,
    name: 'sixtyFour',
    id: 64
  },
  {
    icon: SixtyFive,
    name: 'sixtyFive',
    id: 65
  }
]

export const FIFTY_TEETH = [
  {
    icon: FiftyOne,
    name: 'fiftyOne',
    id: 51
  },
  {
    icon: FiftyTwo,
    name: 'fiftyTwo',
    id: 52
  },
  {
    icon: FiftyThree,
    name: 'fiftyThree',
    id: 53
  },
  {
    icon: FiftyFour,
    name: 'fiftyFour',
    id: 54
  },
  {
    icon: FiftyFive,
    name: 'fiftyFive',
    id: 55
  }
]
