import * as API from 'constants/api'
import React from 'react'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DrawerDialog from 'components/Drawer/DrawerDialog'
import { InputTextUI } from 'components/Form'
import { TOnSubmit } from 'types'
import { normalizePhone } from 'utils/normalize'
import { DrawerBottomActions } from 'components/Drawer'
import { ButtonUI } from 'components/Button'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { path, prop } from 'ramda'
import { UniversalSearchField } from '../../../../components/Form/Select'
import Fields from '../../../../components/Form/Fields'
import YandexMapField from '../../../../components/Form/Map/YandexMapField'
import ImagesUrlField from '../../../../components/Form/ImagesUrlField'
import ImageUploadField from '../../../../components/Form/Upload/ImageUploadField'
import * as STATES from '../../../../constants/states'
import checkRole from '../../../../utils/checkRole'

interface Props {
  open: boolean;
  initialValues?: Record<string, any>;
  onClose: () => void;
  onSubmit: TOnSubmit;
  loading: boolean;
  failed: boolean;
}

const AddressInfo = styled.div`
`

export const fields = [
  'name',
  'email',
  'phoneNumber',
  'region',
  'images',
  'district',
  'roles',
  'workingDays',
  'workingTime',
]
const SettingClinicsCreateDrawer = (props: Props) => {
  const profile = useSelector(prop(STATES.PROFILE))
  const data = path(['data', 'data'], profile)
  const userId = path(['_id'], data)
  const isDirector = checkRole(['superadmin'])

  const { open, onClose, onSubmit, loading, initialValues } = props
  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="p-fluid">
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'name'}
                    label={'Название'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    autoComplete="off"
                    name={'phoneNumber'}
                    parse={normalizePhone}
                    defaultValue={'+998'}
                    component={InputTextUI}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Регион"
                    name="region"
                    itemText={['name']}
                    valueText={['id']}
                    api={API.REGIONS}
                    component={UniversalSearchField}
                    withDetail={false}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Район"
                    name="district"
                    withDetail={false}
                    api={API.DISTRICTS}
                    itemText={['name']}
                    valueText={['region_id']}
                    component={UniversalSearchField}
                  />
                </FieldWrapper>
                <AddressInfo>
                  <Fields
                    names={[
                      'address.location',
                      'address.address',
                      'address.referencePoint'
                    ]}>
                    {fields => <YandexMapField fields={fields}/>}
                  </Fields>
                </AddressInfo>
                <FieldWrapper>
                  <div className={'flex gap-3'}>
                    <FieldWrapper>
                      <Field
                        component={InputTextUI}
                        name={'startTime'}
                        label={'Начало рабочего дня'}
                        type={'time'}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <Field
                        component={InputTextUI}
                        name={'endTime'}
                        label={'Конец рабочего дня'}
                        type={'time'}
                      />
                    </FieldWrapper>
                  </div>
                </FieldWrapper>
                {isDirector && <FieldWrapper>
                  <div className={'flex align-items-center'}>
                    <p className={'font-semibold text-lg mr-6'}>
                      Avatar
                    </p>
                    <ImageUploadField url={`https://aqltish.uz/api/users/avatar/${userId}`} userId={userId}/>
                  </div>
                </FieldWrapper>}
                <FieldWrapper>
                  <Field
                    label={'Фотогалерея клиники'}
                    name="images"
                    component={ImagesUrlField}
                  />
                </FieldWrapper>
                <DrawerBottomActions>
                  <ButtonUI
                    loading={loading}
                    severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                    Сохранить
                  </ButtonUI>
                </DrawerBottomActions>
              </div>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
}

export default SettingClinicsCreateDrawer
