import React, { useState } from 'react'
import classNames from 'classnames'
import { getFieldError } from 'utils/form'
import { AutoComplete } from 'primereact/autocomplete'
import { ProgressSpinner } from 'primereact/progressspinner'
import styled from 'styled-components'
import LabelUI from './LabelUI'

const ProgressSpinnerS = styled(ProgressSpinner)`
    & .p-progress-spinner-circle {
        stroke: #fff !important;
    }
`

const AutoCompleteUI = (props) => {
  const { label, input, error, meta, items, loading, ...otherProps } = props
  const inputError = error || getFieldError(meta) || meta?.error
  const randomNumber = Math.random().toString()
  const [filteredItems, setFilteredItems] = useState(null)

  const searchItems = (event) => {
    let query = event.query
    let _filteredItems = []

    for (let i = 0; i < items.length; i++) {
      let item = items[i]
      if (item.value.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item)
      }
    }

    setFilteredItems(_filteredItems)
  }
  return (
    <>
      {label && <LabelUI htmlFor={randomNumber} required={otherProps?.required}>{label}</LabelUI>}
      <AutoComplete
        {...input}
        {...otherProps}
        onChange={(e) => input.onChange(e.value.value || e.value)}
        className={classNames({ 'p-invalid': inputError })}
        id={randomNumber}
        suggestions={filteredItems}
        completeMethod= {searchItems}
        dropdown
        field="label"
        disabled={loading}
        dropdownIcon={loading ? <ProgressSpinnerS style={{ width: '16px', height: '16px' }} /> : null}
      />
      {inputError && (
        <small className="p-error block mt-2">{inputError}</small>
      )}
    </>
  )
}

export default AutoCompleteUI
