import React, { FC } from 'react'

interface Props {
  children: any
}
const TitleSection = ({ children }: Props) => {
  return (
    <h2 className={'font-semibold text-3xl'}>
      {children}
    </h2>
  )
}

export default TitleSection
