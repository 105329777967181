import * as stateNames from 'constants/states'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import { useCreateModal, useDelete, useFetchList } from 'hooks'
import { getSerializedData } from 'utils/get'
import useUpdateModal from 'hooks/useUpdateModal'
import SettingSmsTemplatesGrid from './components/SettingSmsTemplatesGrid'
import {
  smsTemplatesCreateAction, smsTemplatesDeleteAction,
  smsTemplatesListAction,
  smsTemplatesUpdateAction
} from './settingSmsTemplatesActions/settingSmsTemplatesActions'
import { fields } from './components/SettingSmsTemplatesCreateDrawer'

const SettingSmsTemplatesContainer = props => {
  const listData = useFetchList({
    action: smsTemplatesListAction,
    stateName: stateNames.SMS_TEMPLATES_LIST
  })
  const createDrawer = useCreateModal({
    serializer: (values) => getSerializedData(fields, values),
    action: smsTemplatesCreateAction,
    successAction: smsTemplatesListAction,
    stateName: stateNames.SMS_TEMPLATES_CREATE,
  })
  const deleteData = useDelete({
    stateName: stateNames.SMS_TEMPLATES_DELETE,
    action: smsTemplatesDeleteAction,
    successAction: smsTemplatesListAction
  })

  const updateData = useUpdateModal({
    listData,
    action: smsTemplatesUpdateAction,
    stateName: stateNames.SMS_TEMPLATES_CREATE,
    serializer: (values) => getSerializedData(fields, values),
    successAction: smsTemplatesListAction,
    initialValues: (data) => {
      return {
        ...data
      }
    }
  })

  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingSmsTemplatesGrid
        listData={listData}
        createDrawer={createDrawer}
        deleteData={deleteData}
        updateData={updateData}
      />
    </PermissionWrapper>
  )
}

export default SettingSmsTemplatesContainer
