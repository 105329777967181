import React from 'react'

const ActionButtons = ({ children }) => {
  return (
    <div className={'flex justify-content-end'}>
      {children}
    </div>
  )
}

export default ActionButtons
