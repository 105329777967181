import {
  map, find, pipe, prop,
  isEmpty,
  not,
  // @ts-ignore
  modifyPath,
  filter,
  findIndex, whereEq, uniqBy, append, propEq, equals, omit, where, includes, pick, reject, pluck, pathEq, pathOr
} from 'ramda'
import React, { createContext, Fragment, ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import { TStatus, TTeethService } from 'types'
import { useParams } from 'react-router-dom'
import { useToast } from '../../../context/ToastContext'
import { usePromiseDispatch } from '../../../etc/reducers'
import { calendarDetailAction } from '../../calendar/actions'
import { TTeethStatuses } from '../../../types/enums'

// export interface TTypeObject {
//   id: number | string,
//   name: string,
//   status: TStatus,
//   services: TTeethService[]
//   root: {status: TStatus, field: string, services: TTeethService[]}[]
//   crown: {status: TStatus, field: string, services: TTeethService[]}[]
// }

export interface TTeeth {
  id: number
  services: TTeethService[]
  name: string
  type: string
  status: TStatus,
  field: string
}

export type TTeethTable = TTeethService & {
  amount: number
  uniq: string
  status: string
  customPrice: number
  teeth: Pick<TTeeth, 'id' | 'name' | 'field'>
}

const defaultState = {
  type: 'molars',
  teeth: [],
  handleUpdateType: null,
  isDeleted: null,
  openActionBar: false,
  handleUpdateTeeth: null,
  handleDeleteService: null,
  teethSelected: [],
  handleSetStatusPending: null,
  handleDeleteOrAddTeeth: null,
  handleDeleteSelectedTeeth: null,
  selectedTeeth: null,
  setSelectedTeeth: null,
  openInvoiceBar: null,
  setOpenInvoiceBar: null,
  setOpenActionBar: null,
  setTeeth: null,
  handleAddServiceTeeth: null,
  handleEditPriceTeeth: null,
  handleSaveTeeth: null,
  serviceSelected: null,
  setSelectedService: null
}

const ServicesContext = createContext(defaultState)

interface Props {
  children: ReactNode
  itemData: any
}

const isEqualsTeeth = (prevState, nextState) => {
  return equals(prevState.id, nextState.id)
}

function ServicesProvider (props: Props): ReactElement {
  const { children, itemData } = props
  const dispatch = usePromiseDispatch()
  const { id }: {id: string} = useParams()
  const { toastRef } = useToast()
  const [type, setType] = useState('molars')
  const [teeth, setTeeth] = useState<TTeeth[]>([])
  const [openActionBar, setOpenActionBar] = useState(false)
  const [openInvoiceBar, setOpenInvoiceBar] = useState(false)
  const [selectedTeeth, setSelectedTeeth] = useState(null)
  const [serviceSelected, setSelectedService] = useState<TTeethService | null>(null)
  // id
  // services
  // name
  // type
  // status
  // field

  useEffect(
    () => {
      dispatch(calendarDetailAction(id)).then(({ value }) => {
        const resTeeth = pathOr([], ['data', 'teeth'], value)
        setTeeth(resTeeth)
      })
    },
    [id]
  )

  const isDeleted = (item) => {
    const pred = whereEq({ id: item?.id, status: TTeethStatuses.DELETED })
    return pipe(
      filter(pred),
      isEmpty,
      not
    )(teeth)
  }

  const handleUpdateType = (type) => ({})

  const handleSetStatusPending = (item: TTeeth) => {
    const pred = whereEq({ id: item?.id, type: item?.type, field: item?.field, status: TTeethStatuses.SAVED })
    const newArr = pipe(
      map((i: TTeeth) => {
        return pred(i)
          ? {
            ...i,
            status: TTeethStatuses.PENDING,
          }
          : i
      })
    )(teeth) as TTeeth[]
    setTeeth(newArr)
  }

  const handleCancel = (item: TTeeth) => {
    const pred = whereEq({ id: item?.id, type: item?.type, field: item?.field, status: TTeethStatuses.SAVED })
    const newArr = pipe(
      map((i: TTeeth) => {
        return pred(i)
          ? {
            ...i,
            status: TTeethStatuses.PENDING,
          }
          : i
      })
    )(teeth) as TTeeth[]
    setTeeth(newArr)
  }

  const handleUpdateTeeth = (item: TTeeth) => {
    const pred = whereEq({ id: item?.id, type: item?.type, field: item?.field })
    const newArray = find(pred)(teeth) ? teeth : [...teeth, item]
    const newArr = modifyPath(
      [findIndex(pred)(newArray), 'status'],
      (value) => value === TTeethStatuses.SELECTED ? null : TTeethStatuses.SELECTED,
      newArray
    ) as TTeeth[]
    setTeeth(newArr)
  }

  const handleAddServiceTeeth = (service: TTeethService) => {
    const permAdd = find(where({ status: (val) => equals(val, TTeethStatuses.PENDING) || equals(val, TTeethStatuses.SELECTED) }))(teeth)
    if (permAdd) {
      toastRef.current.show({ severity: 'info', summary: 'Добавление', detail: `Добавлена услуга: ${service.name}`, life: 3000 })
      const newArr = pipe(
        map((i: TTeeth) => {
          const status = prop('status', i)
          return (status === TTeethStatuses.SELECTED || i.status === TTeethStatuses.PENDING)
            ? {
              ...i,
              status: i.status === TTeethStatuses.SELECTED ? TTeethStatuses.PENDING : i.status,
              services: (i.status === TTeethStatuses.SELECTED || i.status === TTeethStatuses.PENDING) ? uniqBy(prop('_id'), [service, ...i.services]) : i.services
            }
            : i
        })
      )(teeth)
      setTeeth(newArr)
    } else {
      toastRef.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Выберите зуб', life: 3000 })
    }
  }

  const handleSaveTeeth = () => {
    const newArr = pipe(
      map((i: TTeeth) => {
        const status = prop('status', i)
        return status === TTeethStatuses.PENDING
          ? {
            ...i,
            status: i.status === TTeethStatuses.PENDING ? TTeethStatuses.SAVED : i.status,
          }
          : i
      })
    )(teeth)
    setTeeth(newArr)
  }

  const handleEditPriceTeeth = (newData: TTeethTable) => {
    const pred = where({
      services: pipe(
        find(whereEq({ _id: newData?._id, name: newData?.name, status: newData?.status })),
        Boolean
      )
    })
    const newArray = map((i: TTeeth) => {
      return pred(i)
        ? {
          ...i,
          services: map((service) => ({
            ...service,
            cost: propEq(newData?._id, '_id', service) ? Number(newData.customPrice) : service.cost
          }), i.services)
        }
        : i
    }, teeth)
    setTeeth(newArray)
  }

  const handleDeleteService = (data: TTeeth, service: TTeethService) => {
    const pred = where({ id: equals(data.id), type: equals(data.type), field: equals(data.field), services: includes(service) })
    const newArray = map((i: TTeeth) => {
      return pred(i)
        ? {
          ...i,
          services: reject(whereEq(service), i.services)
        }
        : i
    }, teeth)
    setTeeth(newArray)
  }
  const handleDeleteSelectedTeeth = () => {
    const newArray = teeth.filter(item => {
      // @ts-ignore
      return !find(pathEq(item.id, ['teeth', 'id']), selectedTeeth)
    })
    setTeeth(newArray)
  }

  const handleDeleteOrAddTeeth = (item: TTeeth) => {
    const hasBody = pipe(
      filter(whereEq({ type: 'body', id: item.id })),
      isEmpty,
      not
    )(teeth)
    const newArr = isDeleted(item)
      ? map((i: TTeeth): TTeeth => {
        return {
          ...i,
          services: isEqualsTeeth(i, item) ? [] : i.services,
          status: isEqualsTeeth(i, item) ? null : i.status
        }
      }, teeth)
      : hasBody
        ? pipe(
          map((i: TTeeth):TTeeth => {
            return {
              ...i,
              services: isEqualsTeeth(i, item) ? [] : i.services,
              status: i.type === 'body' && isEqualsTeeth(i, item) ? TTeethStatuses.DELETED : i.type === 'body' ? i.status : isEqualsTeeth(i, item) ? null : i.status
            }
          })
        )(teeth)
        : pipe(
          append(item),
          map((i: TTeeth):TTeeth => {
            return {
              ...i,
              services: isEqualsTeeth(i, item) ? [] : i.services,
              status: i.type === 'body' && isEqualsTeeth(i, item) ? TTeethStatuses.DELETED : i.type === 'body' ? i.status : isEqualsTeeth(i, item) ? null : i.status
            }
          })
        )(teeth)
    setTeeth(newArr)
  }

  const teethSelected = pipe(
    filter(propEq(TTeethStatuses.SELECTED, 'status')),
    // @ts-ignore
    uniqBy(prop('id')),
    pluck('id')
  )(teeth) as string[]

  const authProps = {
    type,
    openActionBar,
    setOpenActionBar,
    openInvoiceBar,
    setOpenInvoiceBar,
    selectedTeeth,
    setSelectedTeeth,
    handleDeleteSelectedTeeth,
    teeth,
    teethSelected,
    isDeleted,
    handleUpdateType,
    handleUpdateTeeth,
    handleAddServiceTeeth,
    setTeeth,
    handleEditPriceTeeth,
    handleDeleteOrAddTeeth,
    handleDeleteService,
    handleSetStatusPending,
    handleSaveTeeth,
    serviceSelected,
    setSelectedService,
  }
  return (
    <ServicesContext.Provider value={authProps}>
      <Fragment>
        {children}
      </Fragment>
    </ServicesContext.Provider>
  )
}

export function useServices () {
  return useContext(ServicesContext)
}

export { ServicesProvider }
