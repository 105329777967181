import {
  EIGHTY_TEETH, FIFTY_TEETH,
  FORTY_TEETH,
  SEVENTY_TEETH,
  SIXTY_TEETH,
  TEN_TEETH,
  THIRTY_TEETH,
  TWENTY_TEETH
} from 'constants/teeth'
import React, { useState } from 'react'
import { reverse } from 'ramda'
import styled from 'styled-components'
import { SelectButton } from 'primereact/selectbutton'
import { TTeethTypes } from 'types/enums'
import { confirmPopup } from 'primereact/confirmpopup'
import { Button } from 'primereact/button'
import { useToast } from '../../../../context/ToastContext'

const Teeth = styled.div`
  width: 58px;
  display: flex;
  justify-content: center;
`

const TopTeeth = styled.div`
  margin-bottom: 67px;
`

const paymentOptions = [
  { name: 'Коренные зубы', value: TTeethTypes.MOLARS },
  { name: 'Молочные зубы', value: TTeethTypes.BABY }
]

const TeethFields = props => {
  const [value, setValue] = useState(TTeethTypes.MOLARS)

  const topRow = value === TTeethTypes.MOLARS ? [...reverse(TEN_TEETH), ...TWENTY_TEETH] : [...reverse(FIFTY_TEETH), ...SIXTY_TEETH]
  const bottomRow = value === TTeethTypes.MOLARS ? [...reverse(FORTY_TEETH), ...THIRTY_TEETH] : [...reverse(EIGHTY_TEETH), ...SEVENTY_TEETH]
  return (
    <div className="card">
      <SelectButton unselectable className={'justify-content-center flex'} value={value} options={paymentOptions} optionLabel="name" onChange={(e) => setValue(e.value || value)} />
      <div className={'flex align-items-center flex-column pt-5 pb-8'}>
        <TopTeeth className={'flex align-items-end'}>
          {topRow.map(item => {
            return (
              <Teeth key={item.name}>
                <item.icon name={item.name} id={item.id} />
              </Teeth>
            )
          })}
        </TopTeeth>
        <div className={'flex'}>
          {bottomRow.map(item => {
            return (
              <Teeth key={item.name}>
                <item.icon name={item.name} id={item.id} />
              </Teeth>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TeethFields
