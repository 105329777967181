import React from 'react'
import { Column } from 'primereact/column'
import TableUI from 'components/Table/TableUI'
import styled from 'styled-components'
import { path, pathOr } from 'ramda'
import Dropdown from '../../../components/Dropdown/Dropdown'
import { numberFormat } from '../../../utils/numberFormat'
import {
  TGetDataFromState,
  TGetDataPagination,
  TPagination,
  TUseCreateModal,
  TUseDelete,
  TUseUpdateModal
} from '../../../types'
import { scrollHeightBase } from '../../../components/Table/utils'

const Card = styled.div`
  
`
const Photo = styled.div`
  margin-right: 178px;
`

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}

const SmsTable = ({
  listData, createDrawer, updateData,
  deleteData
}: Props) => {
  const value = pathOr([], ['data', 'data'], listData)
  const pagination = path<TPagination>(['data', 'pagination'], listData)

  const OptionButton = (item) => {
    return (
      <Dropdown model={[
        {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            updateData.onOpen(item._id)
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => deleteData.onSubmit(item._id)
        }
      ]}/>
    )
  }
  return (
    <Card className={'w-full'}>
      <TableUI
        pagination={pagination}
        rowHover={true}
        loading={listData.loading}
        scrollHeight={scrollHeightBase}
        value={value}
      >
        <Column field="name" header="Название" />
        <Column field="body" header="Текст сообщения" />
        <Column headerStyle={{ minWidth: '4rem' }} body={OptionButton} style={{ width: '5%' }} />
      </TableUI>
    </Card>
  )
}

export default SmsTable
