import React from 'react'

const DrawerBottomActions = ({ children }) => {
  return (
    <div className={'absolute pr-1'} style={{ right: '30px', left: '30px', bottom: '15px' }}>
      {children}
    </div>
  )
}

export default DrawerBottomActions
