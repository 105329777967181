// SignIn
export const SIGN_IN = 'signIn'
export const USER_INFO = 'userInfo'

// SignUp
export const SIGN_UP = 'signUp'
export const PROFILE = 'profile'
export const PROFILE_AVATAR = 'profileAvatar'

// prices
export const PRICE_LIST = 'priceList'
export const PATIENTS_CREATE = 'patientsCreate'
export const PATIENTS_DELETE = 'patientsDelete'
export const PATIENTS_LIST = 'patientsList'
export const PATIENTS_DETAIL = 'patientsDetail'
export const PATIENTS_CALENDAR_LIST = 'patientsCalendarList'

export const CLINICS_CREATE = 'clinicsCreate'
export const CLINICS_DELETE = 'clinicsDelete'
export const CLINICS_LIST = 'clinicsList'

export const DEBTS_LIST = 'debtsList'
export const REPORT_STATS_LIST = 'reportStatsList'

export const SERVICES_CREATE = 'servicesCreate'
export const SERVICES_DELETE = 'servicesDelete'
export const SERVICES_LIST = 'servicesList'
export const SERVICES_DIAGNOSIS_LIST = 'servicesDiagnosisList'

export const SMS_CREATE = 'smsCreate'
export const SMS_DELETE = 'smsDelete'
export const SMS_LIST = 'smsList'

export const ROLES_CREATE = 'rolesCreate'
export const ROLES_DELETE = 'rolesDelete'
export const ROLES_LIST = 'rolesList'

export const SMS_TEMPLATES_CREATE = 'smsTemplatesCreate'
export const SMS_TEMPLATES_DELETE = 'smsTemplatesDelete'
export const SMS_TEMPLATES_LIST = 'smsTemplatesList'

export const CALENDAR_CREATE = 'calendarCreate'
export const CALENDAR_DELETE = 'calendarDelete'
export const CALENDAR_CONFIRM = 'calendarConfirm'
export const CALENDAR_DOCTORS = 'calendarDoctors'
export const CALENDAR_LIST = 'calendarList'
export const CALENDAR_DETAIL = 'calendarDetail'

export const PERSON_CREATE = 'personCreate'
export const PERSON_LIST = 'personList'
export const PERSON_DELETE = 'personDelete'

export const GLOBAL_LOADER = 'globalLoader'
