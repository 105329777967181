import { replace } from 'ramda'

const DEFAULT = 3

const numberRound = (num, precision = DEFAULT, placePlus = false) => {
  const refactorNumber = num ? Number(Math.round(Number(num + `e+${precision}`)) + `e-${precision}`) : num
  return placePlus ? replace('-', '+', `${refactorNumber}`) : refactorNumber
}

export default numberRound
