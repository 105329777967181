import React, { FC } from 'react'

interface Props {
  children: any
  className?: string[]
}
const ProfileParagraf = ({ children, className = [] }: Props) => {
  return (
    <p className={['text-xl', ...className].join(' ')}>
      {children}
    </p>
  )
}

export default ProfileParagraf
