import React from 'react'
import useModal from '../../hooks/useModal'
import { OPEN_NOTIFICATION } from '../../constants/constants'
import NotificationDrawer from './components/NotificationDrawer'

const NotificationContainer = () => {
  const modal = useModal({ key: OPEN_NOTIFICATION })

  return (
    <NotificationDrawer {...modal}/>
  )
}

export default NotificationContainer
