import * as STATE from 'constants/states'
import { PROFILE } from 'constants/states'
import React, { FunctionComponent, ReactNode } from 'react'
import { useTypedSelector } from 'etc/reducers'
import { defaultTo, map, path, pipe, prop, join } from 'ramda'
import { getDataFromState } from 'utils/get'
import checkPermissions from '../../utils/checkPermissions'

type Props = {
  permissions?: Array<string>;
  children: ReactNode,
}

const getCodeNames = pipe(
  path<string[]>(['permissions']),
  join('-')
)
const Perms: FunctionComponent<Props> = props => {
  const hasPerms = checkPermissions(props.permissions)

  if (!hasPerms) {
    return null
  }

  return <>{props.children}</>
}

export default Perms
