import React from 'react'
import { path } from 'ramda'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

import ActionButtons from '../UI/ActionButtons'
import { closeConfirmDialogAction } from './actions'

const getStateData = state => ({
  open: path(['confirmDialog', 'open'], state),
  title: path(['confirmDialog', 'title'], state),
  type: path(['confirmDialog', 'type'], state),
  message: path(['confirmDialog', 'message'], state),
  onConfirm: path(['confirmDialog', 'onConfirm'], state),
  loading: path(['confirmDialog', 'loading'], state),
})

const ConfirmDialogUi = () => {
  const dispatch = useDispatch()
  const onClose = () => dispatch(closeConfirmDialogAction())
  const { title, message, open, onConfirm, loading, type } = useSelector(getStateData, shallowEqual)

  const footerContent = type === 'confirm' ? (
    <ActionButtons>
      <Button label="Отмена" severity="secondary" disabled={loading} onClick={onClose} />
      <Button label="Подтвердить" loading={loading} severity={'success'} onClick={onConfirm} autoFocus />
    </ActionButtons>
  ) : (
    <ActionButtons>
      <Button label="Отмена" severity="secondary" disabled={loading} onClick={onClose} />
      <Button label="Подтвердить" loading={loading} severity={'danger'} onClick={onConfirm} autoFocus />
    </ActionButtons>
  )

  return (
    <Dialog header={title} visible={open} style={{ width: '600px' }} onHide={onClose} footer={footerContent}>
      <p className="m-0">
        {message}
      </p>
    </Dialog>
  )
}

export default ConfirmDialogUi
