import React from 'react'
import styled from 'styled-components'
import Banner from 'media/banner.png'
import ButtonUI from 'components/Button/Button'
import { Field, Form } from 'react-final-form'
import { InputTextUI } from 'components/Form'

const LoginLayout = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${Banner}) center center / cover no-repeat rgb(106, 130, 194);
`

const Center = styled.div`
  max-width: 542px;
`

type Props = {
  onLogin: (data: any) => Promise<void | Pick<any, string | number | symbol>>
}
const SignIn = (props: Props) => {
  const { onLogin } = props

  return (
    <LoginLayout>
      <Center className="col-12 md:col-6">
        <div className="card p-fluid">
          <h4 className={'text-center'}>Dental Control</h4>
          <Form
            onSubmit={onLogin}
            render={({ handleSubmit, values, form, ...other }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <>
                    <div className="field">
                      <Field
                        component={InputTextUI}
                        name={'email'}
                        label={'Логин'}
                      />
                    </div>
                    <div className="field">
                      <Field
                        component={InputTextUI}
                        name={'password'}
                        label={'Пароль'}
                        type={'password'}
                      />
                    </div>
                    <div className="flex justify-content-center">
                      <ButtonUI label={'Войти'} classNames={['mt-6', 'max-w-12rem']}/>
                    </div>
                  </>
                </form>
              )
            }}
          />

        </div>
      </Center>
    </LoginLayout>
  )
}

export default SignIn
