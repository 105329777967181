import React from 'react'
import AppTopBar from 'components/AppTopBar'
import SearchUI from 'components/SearchUI/SearchUI'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import Container from '../../../components/Container'
import { UniversalSearchField, UniversalStaticSelectField } from '../../../components/Form/Select'
import * as API from '../../../constants/api'
import checkRole from '../../../utils/checkRole'
import DateUI from '../../../components/Form/DateUI'
import { getFullName } from '../../../utils/get'
import ReportStatusChart from './ReportStatusChart'
import PatientsTable from './PatientsTable'
import ReportDebtorsChart from './ReportDebtorsChart'
import { DoctorStatGrid } from './DoctorStatCard'

type Props = {
    listData: any
    statsData: any
    filterAction: any
}
const SimpleGrid = styled.div`
 display: grid;
  grid: 1fr/ 1fr 1fr;
  gap: 30px;
`

const StyledCont = styled(Container)`
  padding: 0px calc(35px - 3rem); 
  margin-bottom: 20px
`

const FullSize = styled.div`
  grid-column: 1/3;
  max-height:  80vh;
  overflow: auto;
`

export const fields = ['clinicId']
const ReportsGrid = ({ listData, statsData, filterAction }: Props) => {
  const isSuperadmin = checkRole(['superadmin'])
  return (
    <div>
      <Form
        onSubmit={() => null}
        initialValues={filterAction.initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <AppTopBar
                title={'Отчеты'}
                leftActions={(
                  <div className="flex ">
                    <div className={'mr-2'}>
                      <SearchUI/>
                    </div>
                    <div className={'w-18rem'}>
                      <Field
                        name="clinicId"
                        api={API.CLINICS_LIST}
                        component={UniversalSearchField}
                        placeholder={'Клиника'}
                        typeSelect={'simple'}
                        onChange={(value) => {
                          filterAction.onSubmit({ clinicId: value?._id })
                        }}
                      />
                    </div>
                  </div>
                )}
                rightActions={isSuperadmin && (
                  <div className="flex ">
                    <div className={'mr-2'}>
                      <Field
                        component={DateUI}
                        placeholder={'Дата'}
                        fullWidth
                        name={'date'}
                      />
                    </div>
                    <div className={' w-18rem'}>
                      <Field
                        required
                        name="doctorId"
                        typeSelect={'simple'}
                        api={API.DOCTORS_LIST}
                        itemText={['email']}
                        placeholder={'Доктор'}
                        getText={(obj) => getFullName(obj)}
                        component={UniversalSearchField}
                      />
                    </div>
                  </div>
                )}
              />
            </form>
          )
        }}
      />

      <StyledCont>
        <SimpleGrid>
          <div className={'card mb-0'}>
            <ReportDebtorsChart listData={listData}/>
          </div>
          <div className={'card mb-0'}>
            <ReportStatusChart statsData={statsData}/>
          </div>
          <DoctorStatGrid statsData={statsData} listData={listData}/>
          <FullSize>
            <PatientsTable listData={listData} statsData={statsData}/>
          </FullSize>
        </SimpleGrid>
      </StyledCont>
    </div>
  )
}

export default ReportsGrid
