import React from 'react'
import styled from 'styled-components'

interface Props {
  required?: boolean
  className?: string
  children: JSX.Element
  htmlFor?: string | undefined;
}
const Label = styled.label<{required?: boolean}>`
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0.5rem;
  &:after{
    content: ' *';
    display: ${({ required }) => required ? 'inline-block' : 'none'};
    margin-left: ${({ required }) => required && '4px'};
    color: ${({ theme }) => 'red'};
  }
`
export default function LabelUI ({ children, ...props }: Props) {
  return (
    <Label
      {...props}
    >
      {children}
    </Label>
  )
}
