import { DATE_TIME_FORMAT_ISO_8601 } from 'constants/dateFormat'
import { path, prop } from 'ramda'
import moment from 'moment'
import { numberWithoutSpaces } from '../../utils/numberFormat'

export const patientsCreateSerializer = params => {
  return {
    firstName: prop('firstName', params),
    lastName: prop('lastName', params),
    address: prop('address', params),
    phoneNumber: numberWithoutSpaces(prop('phoneNumber', params)),
    phoneNumbers: (prop('phoneNumbers', params) || []).map(i => numberWithoutSpaces(i.phoneNumber)),
    gender: path(['gender', '_id'], params) || path(['gender'], params),
    birthdate: prop('birthdate', params) ? moment(prop('birthdate', params)).format(DATE_TIME_FORMAT_ISO_8601) : null,
    doctorId: path(['doctorId', '_id'], params) || path(['doctorId'], params),
    clinicId: path(['clinicId', '_id'], params) || path(['clinicId'], params)
  }
}
