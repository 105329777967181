import themes from 'constants/themes'
import React, { useContext, useState } from 'react'
import { ThemeProvider as Provider } from 'styled-components'
import NormalizeCSS from 'themes/NormalizeCSS'
import MainCSS from 'themes/MainCSS'
import { PrimeReactContext, PrimeReactProvider } from 'primereact/api'
import classNames from 'classnames'
import { AppConfig } from '../../components/AppConfig'
import AppContentContext from '../../themes/appcontentcontext'

export const AppTheme = ({ children }) => {
  const switchTheme = (currentTheme, newTheme, linkElementId, callback) => {
    const linkElement = document.getElementById(linkElementId)
    const cloneLinkElement = linkElement.cloneNode(true)
    const newThemeUrl = linkElement.getAttribute('href').replace(currentTheme, newTheme)

    // @ts-ignore
    cloneLinkElement.setAttribute('id', linkElementId + '-clone')
    // @ts-ignore
    cloneLinkElement.setAttribute('href', newThemeUrl)
    cloneLinkElement.addEventListener('load', () => {
      linkElement.remove()
      // @ts-ignore
      cloneLinkElement.setAttribute('id', linkElementId)

      if (callback) {
        callback()
      }
    })
    linkElement.parentNode?.insertBefore(cloneLinkElement, linkElement.nextSibling)
  }

  const [darkMode, setDarkMode] = useState(false)
  const [theme, setTheme] = useState('lara-light-indigo')
  const [newsActive, setNewsActive] = useState(false)
  const [announcement, setAnnouncement] = useState(null)

  const appState = {
    darkMode,
    theme,
    newsActive,
    announcement,
    changeTheme: (newTheme, dark) => {
      if (newTheme !== theme) {
        switchTheme(theme, newTheme, 'theme-link', () => {
          setDarkMode(dark)
          setTheme(newTheme)
        })
      }
    },
    showNews: (message) => {
      setNewsActive(true)
      setAnnouncement(message)
    },
    hideNews: () => {
      setNewsActive(false)
    }
  }
  return (
    <AppContentContext.Provider value={appState}>
      <PrimeReactProvider>
        {children}
      </PrimeReactProvider>
    </AppContentContext.Provider>
  )
}
// Component
export const ThemeProvider = props => {
  const { children } = props
  const [configActive, setConfigActive] = useState(false)
  const { theme, darkMode, changeTheme, newsActive } = useContext(AppContentContext)
  const { ripple, inputStyle } = useContext(PrimeReactContext)

  const wrapperClassName = classNames('layout-wrapper', {
    'layout-news-active': newsActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-dark': darkMode,
    'layout-light': !darkMode
  })

  const toggleDarkMode = () => {
    let newTheme = null

    if (darkMode) {
      newTheme = theme.replace('dark', 'light')
    } else if (theme.includes('light') && theme !== 'fluent-light') {
      newTheme = theme.replace('light', 'dark')
    } else {
      newTheme = 'lara-dark-cyan'
    }

    // @ts-ignore
    changeTheme(newTheme, !darkMode)
  }
  return (
    <div className={wrapperClassName} data-p-theme={theme}>
      <Provider theme={themes()}>
        <>
          <NormalizeCSS/>
          <MainCSS/>
          {children}
        </>
        <AppConfig
          setConfigActive={setConfigActive}
          active={configActive} onHide={() => setConfigActive(false)} onDarkSwitchClick={toggleDarkMode}
        />
      </Provider>
    </div>
  )
}
