import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { equals, swap, find, groupBy, isEmpty, not, pathOr, pipe, where, findIndex, propEq } from 'ramda'
import useDidUpdate from 'hooks/useDidUpdate'
import Container from 'components/Container'
import { useServices } from '../../ServicesProvider'
import { TTeethService } from '../../../../../types'
import { TTeethStatuses } from '../../../../../types/enums'
import TeethService from './TeethService'

const Nav = styled('div')`
  .isActive {
    color: var(--primary-color);
  };
  & img {
    margin-bottom: 10px;
    max-height: 50px;
  }
`
const navClasses = ['flex', 'flex-column', 'justify-content-center', 'align-items-center', 'px-5', 'py-4']
const TeethMenuBarUi = ({ servicesData, servicesDiagnosisData, servicesModalData }) => {
  const { teeth, setOpenActionBar, serviceSelected, setSelectedService } = useServices()

  const onClick = (service) => {
    if (service._id === serviceSelected?._id) {
      return setSelectedService(null)
    } return setSelectedService(service)
  }
  useDidUpdate(() => {
    if (find(where({ status: equals(TTeethStatuses.PENDING), services: pipe(isEmpty, not) }), teeth)) {
      setOpenActionBar(true)
    } else {
      setOpenActionBar(false)
    }
  }, [teeth])

  const services: TTeethService[] = pathOr([], ['data', 'data'], servicesData).map(item => ({
    ...item,
    parentId: item?.parentId || null
  }))
  function childrenOf (partenId) {
    const grouped = groupBy(product => product.parentId, services)
    return (grouped[partenId] || []).map(product => ({
      ...product
    }))
  }
  const items = childrenOf(null)
  // @ts-ignore
  const itemsOrdering = swap(0, findIndex(propEq('Диагнозы', 'name'), items))(items)
  return (
    <>
      <div className={'card -mt-4 p-0 mb-0'}>
        <Nav className={'flex overflow-auto'}>
          {itemsOrdering.map((menu: TTeethService) => {
            const isActive = serviceSelected?._id === menu._id
            return (
              <a
                onClick={() => onClick({ name: menu.name, price: 3000, _id: menu._id, type: menu.type })}
                className={[isActive ? 'bg-primary' : 'hover:surface-hover', ...navClasses].join(' ')}
                key={menu._id}
              >
                <img src={menu.imageUrl} alt={''}/>
                <span>{menu.name}</span>
              </a>
            )
          })}
        </Nav>
      </div>
      <Container>
        <TeethService serviceSelected={serviceSelected} servicesDiagnosisData={servicesDiagnosisData} setSelectedService={setSelectedService} servicesData={servicesData} servicesModalData={servicesModalData}/>
      </Container>
    </>
  )
}

TeethMenuBarUi.propTypes = {

}

export default TeethMenuBarUi
