import styled from 'styled-components'

const FlexBorderBottom = styled('div')`
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 20px;
  :last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
  }
`

export default FlexBorderBottom
