import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import { Link } from 'react-router-dom'
import AppTopBar from '../../../components/AppTopBar'
import SearchUI from '../../../components/SearchUI/SearchUI'
import Button from '../../../components/Button/Button'
import { SETTING_SMS_TEMPLATES_URL } from '../../../constants/routes'
import SmsTable from './SmsTable'
import SmsCreateDrawer from './SmsCreateDrawer'

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}
const SmsGrid = ({
  createDrawer, listData, updateData,
  deleteData
}: Props) => {
  return (
    <>
      <AppTopBar
        title={'SMS'}
        leftActions={(
          <SearchUI/>
        )}
        rightActions={(
          <div className={'flex gap-4'}>
            <Link to={SETTING_SMS_TEMPLATES_URL}>
              <Button
                style={{ height: '100%' }}
                severity="info"
              >
                SMS шаблоны
              </Button>
            </Link>
            <Button
              onClick={createDrawer.onOpen}
              severity="success"
            >
                + Новая рассылка
            </Button>
          </div>
        )}
      />
      <SmsTable
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
        listData={listData}
      />
      <SmsCreateDrawer
        {...createDrawer}
      />
      <SmsCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SmsGrid
