import * as stateNames from 'constants/states'
import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Column } from 'primereact/column'
import { groupBy, isEmpty, omit, path, pathOr } from 'ramda'
import { usePromiseDispatch } from 'etc/reducers'
import TableUI from 'components/Table/TableUI'
import { numberFormat } from 'utils/numberFormat'
import { TTeethService } from 'types'
import Button from 'components/Button/Button'
import SearchUI from 'components/SearchUI/SearchUI'
import { useCreateModal, useQuery } from 'hooks'
import { getSerializedData } from 'utils/get'
import SettingServicesCreateDrawer, { fields } from '../../../settingServices/components/SettingServicesCreateDrawer'
import {
  servicesCreateAction,
  servicesListAction
} from '../../../settingServices/settingServicesActions/settingServicesActions'
import { useServices } from '../ServicesProvider'

const ServicesModal = ({ onClose, open, servicesData, servicesDiagnosisData, loading }) => {
  const dispatch = usePromiseDispatch()

  const { serviceSelected } = useServices()
  const isDiagnosis = serviceSelected?.type === 'diagnosis'

  const { handleAddServiceTeeth } = useServices()
  const createDrawer = useCreateModal({
    serializer: (values) => getSerializedData(fields, values),
    action: servicesCreateAction,
    onSuccess: () => dispatch(servicesListAction({ size: 1000 })),
    stateName: stateNames.SERVICES_CREATE,
  })

  const search = useQuery('search')

  const servicesDiagnosisList = pathOr([], ['data', 'data'], servicesDiagnosisData)
  const services: TTeethService[] = pathOr([], ['data', 'data'], servicesData).map(item => ({
    ...item,
    parentId: item?.parentId || null
  }))

  const grouped = groupBy(product => product.parentId, services)
  function childrenOf (parentId) {
    return (grouped[parentId] || []).map(service => ({
      ...service,
      children: childrenOf(service._id)
    }))
  }
  const serviceTree = childrenOf(null)
  const getService = (data) => data.map(service => {
    const hasChildren = !isEmpty(service.children)
    return [hasChildren ? null : service, ...getService(service.children)]
  }).flat().filter(Boolean)

  const activeServices = isDiagnosis ? servicesDiagnosisList : getService(serviceTree)
  const filteredServices = activeServices.filter((el) => {
    const searchValue = el.name.toLowerCase()
    return search ? searchValue.indexOf(search.toLowerCase()) !== -1 : true
  })
  const header = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          onClick={createDrawer.onOpen}
          severity="success"
        >
          + Новая услуга
        </Button>
        <SearchUI/>
      </div>
    )
  }
  return (
    <>
      <Dialog header={isDiagnosis ? 'Диагнозы' : 'Процедуры'} visible={open} style={{ width: '600px', zIndex: '996' }} baseZIndex={996} onHide={onClose} position={'top'}>
        <TableUI
          onRowClick={({ data }) => handleAddServiceTeeth(data)}
          style={{ padding: '0px' }}
          rowHover={true}
          loading={loading}
          value={filteredServices}
          header={header()}
        >
          <Column field="name" header="Название" />
          <Column body={(item) => numberFormat(item.cost, 'сум')} header="Цена" />
          <Column field="parentId" header="Родительская категория" />
        </TableUI>
      </Dialog>
      <SettingServicesCreateDrawer
        {...createDrawer}
      />
    </>

  )
}

export default ServicesModal
