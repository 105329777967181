import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import SettingProfileGrid from './components/SettingProfileGrid'

const SettingProfileContainer = props => {
  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingProfileGrid/>
    </PermissionWrapper>
  )
}

export default SettingProfileContainer
