import React from 'react'
import ReactHighcharts from 'react-highcharts'

const PieChart = (props) => {
  const {
    listSeries,
    chartHeight
  } = props
  const config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: chartHeight,
      type: 'pie'
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.z}</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true
      }
    },

    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginTop: 10
    },

    series: listSeries,

    credits: {
      enabled: false
    }
  }

  return (
    <ReactHighcharts config={config} neverReflow={true} isPureConfig={true}/>
  )
}

export default PieChart
