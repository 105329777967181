import { filter, isEmpty, map, pick, pipe } from 'ramda'
import equals from 'react-fast-compare'
import { useHistory } from 'react-router-dom'

import { getDataFromState, getParamsFormHistory } from 'utils/get'
import { DEFAULT_PICK_PARAMS } from 'utils/isEquals'
import { usePromiseDispatch, useTypedSelector } from 'etc/reducers'
import {
  TUseFetchListParams,
  TGetDataFromState,
  THistory
} from 'types'
import useCompareEffect from './useCompareEffect'

export const getListParams = (history: THistory, keys: Array<string>) =>
  pipe(
    getParamsFormHistory,
    // @ts-ignore
    pick(keys)
  )(history)

const useFetchList = <D = any>(params: TUseFetchListParams) => {
  const {
    stateName,
    shallQuery,
    action,
    mapper = getListParams,
    pickParams = DEFAULT_PICK_PARAMS
  } = params

  const dispatch = usePromiseDispatch()
  const history = useHistory()

  const searchParams = getListParams(history, pickParams)

  const data = useTypedSelector<TGetDataFromState<D>>(state => getDataFromState(stateName, state), equals)
  useCompareEffect(() => {
    if (shallQuery) {
      const isHasQueries = pipe(
        map(i => {
          // @ts-ignore
          if (searchParams[i]) return true
          return false
        }),
        filter(i => i === false),
        isEmpty
      )(shallQuery)
      isHasQueries && dispatch(action(mapper(history, pickParams)))
    } else {
      dispatch(action(mapper(history, pickParams)))
    }
  }, [searchParams])

  return data
}

export default useFetchList
