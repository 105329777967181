import React, { FC } from 'react'
import { ReactDefaultTypes, SvgIconOptional } from 'types/svgImages'
import { TeethSwitcher } from 'components/Form/Checkbox'
import SvgIcon from '../../SvgIcon'
import useTeethData from '../../../../hooks/useTeethData'
import { TEETH_BODY_PROPS, TEETH_CROWN_PROPS, TEETH_ROOT_PROPS } from '../teethData'

const ThirtyOne: FC<SvgIconOptional & ReactDefaultTypes> = ({
  color, id,
  name, ...props
}) => {
  const {
    dataRoot,
    dataCrown,
    dataBody
  } = useTeethData({
    id,
    name
  })
  return (
    <SvgIcon width="34" height="169" viewBox="0 0 34 169" fill={color} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.834 3.17456C11.2459 2.31522 11.0924 2.05412 12.1136 2.05412C12.9802 2.05412 13.6536 3.97472 11.4738 3.97472C11.4738 4.77946 11.3299 4.69129 11.3139 5.41548C12.3812 5.41548 13.2337 5.27499 13.2337 6.85585C13.2337 8.19314 11.3226 8.95551 10.8336 6.85585C10.0224 6.85585 9.96165 7.0158 9.0733 7.0158C9.46002 8.67606 10.4625 9.41362 11.5249 9.57663H12.495C13.1623 9.47928 13.7857 9.19182 14.2213 8.8051C15.365 7.79077 15.3753 4.92646 13.5528 4.7749C13.6498 4.62983 15.8846 2.82602 13.8353 1.13102C12.4988 0.0250729 10.5163 0.42554 9.73221 1.59372C8.73888 3.07379 9.57301 3.0692 10.8332 3.17419L10.834 3.17456Z" fill="#2A2928"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.434 2.72027V4.32098C17.2582 4.25264 18.1095 3.57883 18.6738 3.20089V9.44301H20.434V0.479736H18.994C18.7566 1.49903 17.4697 2.47899 16.434 2.72027Z" fill="#2A2928"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5649 112.715C6.88205 101.693 -4.26373 78.3918 3.33209 73.7332C8.31593 70.6772 26.3406 69.9255 29.4855 74.3841C35.6009 83.0553 32.9782 93.5059 31.8005 111.407C21.6705 113.374 10.7374 112.751 10.5652 112.715L10.5649 112.715Z" fill="#FEFEFE"/>
      <TeethSwitcher
        isBody
        data={dataBody()}
        {...TEETH_BODY_PROPS}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5649 112.715C6.88205 101.693 -4.26373 78.3918 3.33209 73.7332C8.31593 70.6772 26.3406 69.9255 29.4855 74.3841C35.6009 83.0553 32.9782 93.5059 31.8005 111.407C21.6705 113.374 10.7374 112.751 10.5652 112.715L10.5649 112.715Z" stroke="#2B2A29" strokeWidth="0.755879" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
      <TeethSwitcher
        data={dataRoot('one')}
        {...TEETH_ROOT_PROPS}
        {...props}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M19.598 155.245C19.2441 153.951 15.0028 129.414 14.1343 124.997C13.449 121.512 11.7368 116.749 10.8885 113.907C19.5651 114.354 26.8498 113.385 31.7191 112.749C32.1234 112.696 29.9863 119.896 30.3479 139.55C30.5972 153.105 34.4395 161.632 30.4502 167.883C28.4898 167.848 22.8685 167.206 19.598 155.245V155.245Z" fill="#FFF383" stroke="#2B2A29" strokeWidth="0.755879" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
      <TeethSwitcher
        data={dataRoot('two')}
        {...TEETH_ROOT_PROPS}
        {...props}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M26.1088 138.035C25.9077 125.124 27.6909 122.269 25.7 120.807C23.1273 119.844 20.4756 119.778 19.6316 120.586C18.1088 122.044 19.932 125.279 20.6081 138.472C20.9651 145.442 24.9429 156.437 28.4811 163.573C31.8787 162.464 26.2115 144.646 26.1088 138.035V138.035Z" fill="#FFF383" stroke="#2B2A29" strokeWidth="0.755879" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.4043 44.0603C25.0609 44.82 28.0867 51.2408 29.8611 53.0606C27.3728 54.5865 21.0918 55.6062 17.6873 56.1968C12.8863 57.0305 8.71445 57.817 3.95929 56.4831C4.11886 55.8795 4.95073 54.1364 5.28056 53.6356C6.64076 51.5741 8.55183 47.0003 9.05384 44.7387C13.3566 45.3858 11.436 46.4421 17.1937 46.3043C18.5829 46.2711 20.1217 46.1833 21.3441 45.7069C22.5589 45.2331 23.3964 44.3715 24.4043 44.0603Z" fill="#FEFEFE"/>
      <TeethSwitcher
        data={dataCrown('three')}
        {...TEETH_CROWN_PROPS}
        {...props}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M24.4043 44.0603C25.0609 44.82 28.0867 51.2408 29.8611 53.0606C27.3728 54.5865 21.0918 55.6062 17.6873 56.1968C12.8863 57.0305 8.71445 57.817 3.95929 56.4831C4.11886 55.8795 4.95073 54.1364 5.28056 53.6356C6.64076 51.5741 8.55183 47.0003 9.05384 44.7387C13.3566 45.3858 11.436 46.4421 17.1937 46.3043C18.5829 46.2711 20.1217 46.1833 21.3441 45.7069C22.5589 45.2331 23.3964 44.3715 24.4043 44.0603Z" stroke="#2B2A29" strokeWidth="0.266848" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.29619 56.1406C0.878138 54.9091 -0.0121157 52.4071 0.531506 48.8804C1.13506 44.9662 2.86405 35.3521 3.87456 31.7811C4.36703 30.0392 5.89406 25.5512 6.83624 24.5213C7.44743 25.3737 9.22411 30.6095 9.69863 32.009C10.1632 33.3788 10.1502 35.2837 10.1346 36.8382C10.1159 38.6943 9.53753 39.5804 9.24511 41.2212C8.68087 44.3913 6.82287 50.0096 5.14238 52.709L3.79363 55.2962C3.71804 55.4729 3.6703 55.6024 3.58936 55.744L3.29656 56.1406H3.29619Z" fill="#FEFEFE"/>
      <TeethSwitcher
        data={dataCrown('four')}
        {...TEETH_CROWN_PROPS}
        {...props}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M3.29619 56.1406C0.878138 54.9091 -0.0121157 52.4071 0.531506 48.8804C1.13506 44.9662 2.86405 35.3521 3.87456 31.7811C4.36703 30.0392 5.89406 25.5512 6.83624 24.5213C7.44743 25.3737 9.22411 30.6095 9.69863 32.009C10.1632 33.3788 10.1502 35.2837 10.1346 36.8382C10.1159 38.6943 9.53753 39.5804 9.24511 41.2212C8.68087 44.3913 6.82287 50.0096 5.14238 52.709L3.79363 55.2962C3.71804 55.4729 3.6703 55.6024 3.58936 55.744L3.29656 56.1406H3.29619Z" stroke="#2B2A29" strokeWidth="0.266848" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.4353 52.7465C29.303 51.4038 29.2335 51.2087 28.3078 49.7115C27.6431 48.6353 27.1171 47.5973 26.5128 46.525C25.9023 45.4438 25.3637 44.4039 24.7376 43.3228C24.1126 42.2447 23.4491 41.2621 22.8799 40.1951C19.9981 34.7947 21.146 29.0096 23.2304 23.7425C23.8061 24.0429 25.3934 25.7223 25.8141 26.3808C26.2944 27.1329 26.8533 29.0333 27.253 30.0568C29.563 35.9728 29.6611 35.4914 31.2759 41.6282C31.6374 43.0025 32.0528 44.2375 32.4433 45.5801C32.8083 46.835 33.3038 48.6792 32.9095 50.0604C32.58 51.2149 31.476 52.3628 30.4353 52.7461V52.7465Z" fill="#FEFEFE"/>
      <TeethSwitcher
        data={dataCrown('two')}
        {...TEETH_CROWN_PROPS}
        {...props}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M30.4353 52.7465C29.303 51.4038 29.2335 51.2087 28.3078 49.7115C27.6431 48.6353 27.1171 47.5973 26.5128 46.525C25.9023 45.4438 25.3637 44.4039 24.7376 43.3228C24.1126 42.2447 23.4491 41.2621 22.8799 40.1951C19.9981 34.7947 21.146 29.0096 23.2304 23.7425C23.8061 24.0429 25.3934 25.7223 25.8141 26.3808C26.2944 27.1329 26.8533 29.0333 27.253 30.0568C29.563 35.9728 29.6611 35.4914 31.2759 41.6282C31.6374 43.0025 32.0528 44.2375 32.4433 45.5801C32.8083 46.835 33.3038 48.6792 32.9095 50.0604C32.58 51.2149 31.476 52.3628 30.4353 52.7461V52.7465Z" stroke="#2B2A29" strokeWidth="0.266848" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
      <TeethSwitcher
        data={dataCrown('center')}
        {...TEETH_CROWN_PROPS}
        fill={'#B2B3B3'}
        {...props}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M24.0932 43.4033C23.8065 43.6916 21.7278 44.9048 21.2517 45.0907C19.4514 45.7931 14.6985 46.0455 12.9611 45.2671C11.8491 44.7689 10.5904 44.4203 9.3226 44.1592C10.1266 38.56 11.0867 40.1939 10.6206 32.8764C11.8605 33.3647 11.7742 33.4719 13.3967 33.4032C14.9443 33.3383 17.985 33.2509 19.0956 32.3141C19.7873 31.7307 20.095 31.013 20.6917 30.5591C20.5126 33.2223 20.5352 35.5117 21.2953 37.9755C21.9756 40.1821 23.0655 41.6782 24.0935 43.403L24.0932 43.4033Z" fill="#B2B3B3" stroke="#2B2A29" strokeWidth="0.266848" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7184 23.3359C21.9224 25.8307 21.7102 25.3653 20.9547 28.9294C20.8165 29.5818 20.5897 29.717 20.2626 30.0781C18.1877 32.3721 18.6882 32.5332 13.7903 32.7749C9.71882 32.9753 10.3342 31.6823 8.53118 27.0157C8.18837 26.1281 7.8715 25.2432 7.4928 24.3762C7.10608 23.4921 7.26719 23.6058 8.32122 22.07C8.8698 21.2706 9.39892 20.7209 9.94368 20.0207C10.6358 19.132 11.1256 19.1477 12.6099 19.0828C16.0896 18.9301 15.3555 18.4292 18.4183 20.2773C19.5086 20.9347 22.116 22.6075 22.7184 23.3367V23.3359Z" fill="#FEFEFE"/>
      <TeethSwitcher
        data={dataCrown('one')}
        {...TEETH_CROWN_PROPS}
        {...props}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M22.7184 23.3359C21.9224 25.8307 21.7102 25.3653 20.9547 28.9294C20.8165 29.5818 20.5897 29.717 20.2626 30.0781C18.1877 32.3721 18.6882 32.5332 13.7903 32.7749C9.71882 32.9753 10.3342 31.6823 8.53118 27.0157C8.18837 26.1281 7.8715 25.2432 7.4928 24.3762C7.10608 23.4921 7.26719 23.6058 8.32122 22.07C8.8698 21.2706 9.39892 20.7209 9.94368 20.0207C10.6358 19.132 11.1256 19.1477 12.6099 19.0828C16.0896 18.9301 15.3555 18.4292 18.4183 20.2773C19.5086 20.9347 22.116 22.6075 22.7184 23.3367V23.3359Z" stroke="#2B2A29" strokeWidth="0.266848" strokeMiterlimit="22.9256"/>
      </TeethSwitcher>
    </SvgIcon>
  )
}

export default ThirtyOne
