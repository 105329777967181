import * as stateNames from '../../constants/states'
import createThunkReducer from '../../utils/createThunkReducer'
import * as actionTypes from '../../constants/actionTypes'
import { PATIENTS_CALENDAR_LIST } from '../../constants/actionTypes'
import { PATIENTS_DETAIL } from '../../constants/api'

export default {
  [stateNames.PATIENTS_CREATE]: createThunkReducer(actionTypes.PATIENTS_CREATE),
  [stateNames.PATIENTS_LIST]: createThunkReducer(actionTypes.PATIENTS_LIST),
  [stateNames.PATIENTS_DETAIL]: createThunkReducer(actionTypes.PATIENTS_DETAIL),
  [stateNames.PATIENTS_CALENDAR_LIST]: createThunkReducer(actionTypes.PATIENTS_CALENDAR_LIST),
  [stateNames.PATIENTS_DELETE]: createThunkReducer(actionTypes.PATIENTS_DELETE),

}
