import * as ROUTES from 'constants/routes'
import React from 'react'
import { useFormError } from 'utils/form'
import { useHistory } from 'react-router'
import { usePromiseDispatch } from 'etc/reducers'
import { authAction } from './actions'
import SignIn from './components/SignIn'

const SignInContainer = () => {
  const history = useHistory()
  const dispatch = usePromiseDispatch()
  const { mapResponseToFormError } = useFormError()
  const onLogin = (data: any) => {
    return dispatch(authAction(data))
      .then((_) => {
        return history.push(ROUTES.ROOT_URL)
      })
      .catch(mapResponseToFormError)
  }

  return (
    <SignIn onLogin={onLogin} />
  )
}

export default SignInContainer
