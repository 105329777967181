import * as ROUTES from 'constants/routes'
import * as ACTION_TYPES from 'constants/actionTypes'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import expireDocumentCookie from 'utils/expireDocumentCookie'
import SettingGrid from './SettingGrid'

const SettingContainer = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const onLogout = () => {
    expireDocumentCookie()
    dispatch({
      type: `${ACTION_TYPES.PROFILE}_CLEAR`
    })
    dispatch({
      type: `${ACTION_TYPES.SIGN_IN}_CLEAR`
    })
    history.replace(ROUTES.SIGN_IN_URL)
    window.location.reload()
    localStorage.clear()
  }

  return (
    <SettingGrid
      onLogout={onLogout}
    />
  )
}

export default SettingContainer
