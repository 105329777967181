import { closeConfirmDialogAction, openConfirmDialogAction } from 'components/ConfirmDialogUi/actions'
import { usePromiseDispatch } from 'etc/reducers'
import { useToast } from 'context/ToastContext'
import moment from 'moment'
import dateFormat from 'utils/dateFormat'
import { prop } from 'ramda'
import { calendarDeleteAction, calendarUpdateAction } from '../actions'
import { clinicsUpdateAction } from '../../setting/SettingClinics/settingClinicsActions/settingClinicsActions'
import { DATE_FORMAT_ISO_8601 } from '../../../constants/dateFormat'

const useCalendarActions = (props) => {
  const { commit, calendarRef } = props
  const { toastRef } = useToast()
  const dispatch = usePromiseDispatch()
  const handleEventDelete = (id) => {
    const clickInfo = calendarRef?.current?.getApi().getEventById(id)
    dispatch(openConfirmDialogAction({
      message: `${clickInfo.title} от ${dateFormat(clickInfo.startStr)}, на ${moment(clickInfo.startStr).format('HH:mm')}`,
      onConfirm: () => {
        dispatch(openConfirmDialogAction({ loading: true }))
        commit({ activeEvent: clickInfo.id })
        return dispatch(calendarDeleteAction(clickInfo.id))
          .then((data) => {
            clickInfo.remove()
            toastRef.current.show({ severity: 'success', summary: 'Успех!', detail: `${clickInfo.title} удалено`, life: 3000 })
            dispatch(closeConfirmDialogAction())
          })
          .catch(() => {
            dispatch(closeConfirmDialogAction())
            toastRef.current.show({ severity: 'error', summary: 'Message', detail: 'Ошибка!', life: 3000 })
          })
      },
    }))
  }

  const handleEventChange = (eventDropInf) => {
    commit({ activeEvent: eventDropInf.event.id })
    const data = {
      note: prop('title', eventDropInf.event),
      date: moment(prop('startStr', eventDropInf.event)).format(DATE_FORMAT_ISO_8601),
      startTime: moment(prop('startStr', eventDropInf.event)).format('HH:mm:ss'),
      endTime: moment(prop('endStr', eventDropInf.event)).format('HH:mm:ss'),
      doctorId: prop('doctorId', eventDropInf.event.extendedProps),
      patientId: prop('patientId', eventDropInf.event.extendedProps),
      clinicId: prop('clinicId', eventDropInf.event.extendedProps),
    }
    dispatch(calendarUpdateAction(eventDropInf.event.id, data))
      .then((data) => {
        toastRef.current.show({ severity: 'success', summary: 'Успех!', detail: 'Запись обновлена', life: 3000 })
      })
      .catch((error) => {
        eventDropInf.revert()
        toastRef.current.show({ severity: 'error', summary: 'Ошибка!', detail: error?.message, life: 3000 })
      })
  }
  return {
    handleEventDelete,
    handleEventChange
  }
}

export default useCalendarActions
