import * as stateNames from 'constants/states'
import React from 'react'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import { useSelector } from 'react-redux'
import { compose, join, path, prop } from 'ramda'
import { useFetchList, useFilterActions } from 'hooks'
import { useHistory } from 'react-router-dom'
import { getParamsFormHistory } from '../../utils/get'
import ReportsGrid, { fields } from './components/ReportsGrid'
import { reportsDebsAction, reportsListAction } from './reportsActions'

const Permission = (props) => (
  <PermissionWrapper
    permissions={['READ']}
    {...props}
  />
)
const ReportsContainer = () => {
  const history = useHistory()
  const searchParams = getParamsFormHistory(history)

  const profile = useSelector(prop(stateNames.PROFILE))
  const data = path(['data', 'data'], profile)
  const clinicIds = path(['clinicIds'], data) || []

  const listData = useFetchList({
    action: reportsListAction,
    mapper: (history, pickParams) => ({
      search: prop('search', searchParams),
      // @ts-ignore
      clinicId: prop('clinicId', searchParams) || join(' ,', clinicIds),
    }),
    stateName: stateNames.DEBTS_LIST,
    pickParams: ['clinicId', 'search']
  })

  const statsData = useFetchList({
    action: reportsDebsAction,
    stateName: stateNames.REPORT_STATS_LIST,
    pickParams: ['clinicId', 'search'],
    mapper: (history, pickParams) => ({
      search: prop('search', searchParams),
      // @ts-ignore
      clinicId: prop('clinicId', searchParams) || join(' ,', clinicIds),
    }),
  })
  const filterAction = useFilterActions({ fields })
  return (
    <>
      <Permission>
        <ReportsGrid
          listData={listData}
          statsData={statsData}
          filterAction={filterAction}
        />
      </Permission>
    </>
  )
}

export default ReportsContainer
