import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import SettingMessagesCreateDrawer from './SettingMessagesCreateDrawer'
import SettingMessagesTable from './SettingMessagesTable'

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}
const SettingMessagesGrid = ({
  createDrawer, listData, updateData,
  deleteData
}: Props) => {
  return (
    <>
      <SettingMessagesTable
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
        listData={listData}
      />
      <SettingMessagesCreateDrawer
        {...createDrawer}
      />
      <SettingMessagesCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SettingMessagesGrid
