import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import AppTopBar from '../../../components/AppTopBar'
import SearchUI from '../../../components/SearchUI/SearchUI'
import Button from '../../../components/Button/Button'
import SettingServicesTable from './SettingServicesTable'
import SettingServicesCreateDrawer from './SettingServicesCreateDrawer'

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}
const SettingServicesGrid = ({
  createDrawer, listData, updateData,
  deleteData
}: Props) => {
  return (
    <>
      <AppTopBar
        title={'Услуги'}
        leftActions={(
          <SearchUI/>
        )}
        rightActions={(
          <Button
            onClick={createDrawer.onOpen}
            severity="success"
          >
            + Новая услуга
          </Button>
        )}
      />
      <SettingServicesTable
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
        listData={listData}
      />
      <SettingServicesCreateDrawer
        {...createDrawer}
      />
      <SettingServicesCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SettingServicesGrid
