import React from 'react'
import classNames from 'classnames'
import { getFieldError } from 'utils/form'
import { InputTextarea } from 'primereact/inputtextarea'
import LabelUI from './LabelUI'

const InputTextAreaUI = (props) => {
  const { label, input, error, meta, ...otherProps } = props
  const inputError = error || getFieldError(meta) || meta?.error
  const randomNumber = Math.random().toString()
  return (
    <>
      {label && <LabelUI htmlFor={randomNumber} required={otherProps?.required}>{label}</LabelUI>}
      <InputTextarea
        {...input}
        {...otherProps}
        className={classNames({ 'p-invalid': inputError })}
        id={randomNumber}
      />
      {inputError && (
        <small className="p-error block mt-2">{inputError}</small>
      )}
    </>
  )
}

export default InputTextAreaUI
