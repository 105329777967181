import * as API from 'constants/api'
import * as ACTION_TYPES from 'constants/actionTypes'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { sprintf } from 'sprintf-js'
import { prop } from 'ramda'

export const smsCreateAction = params => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .post(API.SMS_CREATE, params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.SMS_CREATE}`,
      payload
    })
  }
}

export const smsListAction = (params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.SMS_LIST, { params })
      .then((res) => {
        return prop('data', res)
      })
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.SMS_LIST}`,
      payload
    })
  }
}

export const smsUpdateAction = (id, params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .put(sprintf(API.SMS_UPDATE, id), params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.SMS_CREATE}`,
      payload
    })
  }
}
export const smsDeleteAction = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .delete(sprintf(API.SMS_DELETE, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.SMS_DELETE}`,
      payload
    })
  }
}
