import { useEffect, useState } from 'react'

export const getCookie = (name: string): any => {
  if (typeof document !== 'undefined') {
    const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'))
    return matches ? decodeURIComponent(matches[1]) : undefined
  }
}

export const getCookieHook = (name: string) => {
  const [myCookie, setMyCookie] = useState<string | undefined>(undefined)
  useEffect(() => {
    const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'))
    const findCookie = matches ? decodeURIComponent(matches[1]) : undefined
    setMyCookie(findCookie)
  }, [])
  return myCookie
}

export const setCookie = (name: string, value: any | never, expire): void => {
  const date = new Date()
  date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  if (typeof document !== 'undefined') {
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
  }
}

export const deleteCookie = (name: string): void => {
  setCookie(name, '', {
    'max-age': -1
  })
}
