import React from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { getFieldError } from '../../../utils/form'
import LabelUI from '../LabelUI'

const InputSwitchUi = (props) => {
  const { label, input, error, meta, ...otherProps } = props
  const inputError = error || getFieldError(meta) || meta?.error
  const randomNumber = Math.random().toString()
  return (
    <div className={'flex flex-column'}>
      {label && <LabelUI htmlFor={randomNumber} required={otherProps?.required}>{label}</LabelUI>}
      <InputSwitch checked={input.value} {...input} />
      {inputError && (
        <small className="p-error block mt-2">{inputError}</small>
      )}
    </div>
  )
}

export default InputSwitchUi
