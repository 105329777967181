import * as STATES from 'constants/states'
import React from 'react'
import styled from 'styled-components'
import { ProgressBar } from 'primereact/progressbar'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

// Styles
const Inner = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  z-index: 10000;
`
const BGMusk = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: ${({ open }) => open ? '1' : '0'};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  z-index: 9000;
`

// Component
const GlobalLoader = props => {
  const {
    open
  } = props

  // Data
  const globalLoader = useSelector(prop(STATES.GLOBAL_LOADER))

  // Const
  const openLoader = prop('open', globalLoader) || open

  // Render
  return openLoader && (
    <div>
      <Inner
        open={openLoader}
      >
        <ProgressBar mode="indeterminate" style={{ height: '4px', overflow: 'visible' }} />
      </Inner>
      <BGMusk
        open={openLoader}
      />
    </div>
  )
}

export default GlobalLoader
