import React from 'react'
import styled from 'styled-components'
import Container from '../Container'

const StyledCont = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 10px 35px;
`

const ActionsBar = ({ children }) => {
  return (
    <div className={'layout-topbar flex surface-900'}>
      <StyledCont>
        {children}
      </StyledCont>
    </div>
  )
}

export default ActionsBar
