import React, { useState } from 'react'
import { path, prop } from 'ramda'
import { Field } from 'react-final-form'
import FieldWrapper from '../FieldWrapper'
import AddressField from './AddressField'
import YandexMap from './YandexMap'

const CENTER = [41.30882292588138, 69.25220409208157]

const YandexMapField = props => {
  const { fields, required } = props

  const [open, setOpen] = useState(false)

  const addressInputs = prop('address.address', fields)
  const locationInputs = prop('address.location', fields)
  const onAddressChange = addressInputs.input.onChange
  const addressValue = addressInputs.input.value
  const onOpenToggle = () => setOpen(!open)
  const initialCenterLat = path(['value', 'lat'], locationInputs.input)
  const initialCenterLon = path(['value', 'lon'], locationInputs.input)
  const initialPosition = initialCenterLat ? [initialCenterLat, initialCenterLon] : CENTER

  return (
    <>
      <FieldWrapper>
        <Field
          name={'address.address'}
          component={AddressField}
          locationInputs={locationInputs}
          onIconClick={onOpenToggle}
          required={required}
        />
      </FieldWrapper>

      <YandexMap
        onChange={locationInputs.input.onChange}
        initialPosition={initialPosition}
        onToggle={onOpenToggle}
        open={open}
        addressValue={addressValue}
        onAddressChange={onAddressChange}
      />
    </>
  )
}

// YandexMapField.propTypes = {
//   fields: PropTypes.object
// }

export default YandexMapField
