import { equals as equal, path } from 'ramda'
import { useHistory, useParams } from 'react-router-dom'

import { getDataFromState } from 'utils/get'
import { useFormError } from 'utils/form'

import { useTypedSelector, usePromiseDispatch } from 'etc/reducers'
import { TGetDataFromState, TUseUpdateParams, TUseUpdate } from 'types'

const useUpdate = <T>(params: TUseUpdateParams): TUseUpdate => {
  const {
    stateName,
    action,
    redirectUrl,
    initialValues,
    key = 'id',
    onSuccess,
    serializer = (values) => values,
    reject = true
  } = params
  const { mapResponseToFormError } = useFormError()
  const paramsRoute = useParams()
  const history = useHistory()
  const dispatch = usePromiseDispatch()
  const state = useTypedSelector<TGetDataFromState<T>>(state => getDataFromState(stateName, state), equal)
  const id: string | undefined = path([key], paramsRoute)

  const onSubmit = (values: Record<string, any>) => {
    const serializeValues = serializer(values)

    return dispatch(action(id, serializeValues))
      .then(data => {
        if (onSuccess) {
          onSuccess(data, { values })
        } else if (redirectUrl) {
          history.push(redirectUrl)
        }
      })
      .catch((error) => {
        if (reject) {
          return mapResponseToFormError(error)
        }

        return Promise.reject(error)
      })
  }

  return { ...state, id, onSubmit, initialValues, isUpdate: true }
}

export default useUpdate
