import React, { useEffect, useState } from 'react'
import AppTopBar from 'components/AppTopBar'
import styled from 'styled-components'
import Container from 'components/Container'
import { useParams } from 'react-router-dom'
import { path, pathOr } from 'ramda'
import { sprintf } from 'sprintf-js'
import { useDispatch } from 'react-redux'
import { ButtonUI } from '../../../../components/Button'
import useFileUploads from '../../../../components/ImageUpload/useFileUploads'
import { UPLOAD_FILE_TYPES } from '../../../../constants/constants'
import { TUseUpdate } from '../../../../types'
import Button from '../../../../components/Button/Button'
import { useRedirect } from '../../../../hooks'
import { PATIENTS_DETAIL_URL } from '../../../../constants/routes'
import { getFullName } from '../../../../utils/get'
import PatientsLeftSide from './PatientsLeftSide'
import PatientsRightSide from './PatientsRightSide'

const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -999;
`
const Grid = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 300px 1fr;
`

const Label = styled('label')`
  display: inline-block;
`

type Props = {
  itemData: any
  values: any
  input: any
  updateData: TUseUpdate
}
const PatientsFilesGrid = ({ values, itemData, input, updateData }: Props) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const fullName = getFullName(path(['data', 'data'], itemData))
  const inputId = `imageInput-${input.name}`
  const { id }: {id: string} = useParams()
  const [state, onChange] = useFileUploads({
    input,
    onSuccess: (files) => updateData.onSubmit({ ...updateData.initialValues, files: (files || []).map((item) => item.replace(/.*\//, '')) }),
    isMulti: true
  })
  const { loading } = state
  const handleDelete = () => {
    const withoutSelected = (input.value || []).filter(image => image !== selectedImage)
    updateData.onSubmit({ ...updateData.initialValues, files: (withoutSelected || []).map((item) => item.replace(/.*\//, '')) }).then(() => {
      setSelectedImage(null)
      input.onChange(withoutSelected)
    })
  }

  const gotFiles = useRedirect(sprintf(PATIENTS_DETAIL_URL, id))

  return (
    <div>
      <AppTopBar
        title={'Файлы'}
        leftActions={(
          <Button
            onClick={gotFiles}
            type="button" label={fullName} icon="pi pi-user" outlined />
        )}
        rightActions={(
          <div>
            {selectedImage && (
              <ButtonUI
                onClick={handleDelete}
                classNames={['mr-3']}
                severity="danger"
              >
                Удалить выбранный файл
              </ButtonUI>
            )}
            <Input
              onChange={onChange}
              type="file"
              id={inputId}
              accept={UPLOAD_FILE_TYPES.IMAGES}
            />
            <Label htmlFor={inputId}>
              <div className="p-button p-component">
                Загрузить файл +
              </div>
            </Label>
          </div>
        )}
      />
      <Container>
        <Grid>
          <PatientsLeftSide loading={loading || updateData.loading} input={input} setSelectedImage={setSelectedImage} selectedImage={selectedImage} />
          <PatientsRightSide loading={loading} input={input} selectedImage={selectedImage}/>
        </Grid>
      </Container>
    </div>
  )
}

export default PatientsFilesGrid
