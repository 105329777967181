import React from 'react'
import styled from 'styled-components'
import { path } from 'ramda'
import moment from 'moment'
import { numberFormat } from '../../../../utils/numberFormat'
import { getFullName } from '../../../../utils/get'

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.div`
  border: 2px solid black;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Info = styled.div`
  text-align: right;
`

const Section = styled.div`
  margin: 20px 0;
`

const ServiceItem = styled.div`
  display: flex;
  justify-content: space-between;
`

const Totals = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
`

const ContactInfo = styled.div`
  margin-top: 30px;
`

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
`

const ServiceInvoice = ({ data, itemData, totalPrice }) => {
  const dataDetail = path(['data', 'data'], itemData)

  const patientName = getFullName(dataDetail?.patient)
  const patientId = path(['patientId'], dataDetail)

  const doctorName = getFullName(dataDetail?.doctor)
  const clinicName = path(['clinic', 'name'], dataDetail)
  const address = path(['clinic', 'location', 'address'], dataDetail)
  const phoneNumber = path(['clinic', 'phoneNumber'], dataDetail)
  return (
    <Container>
      <Header>
        <Logo>Logo</Logo>
        <Info>
          <p>SANASI: {moment().locale('uz').format('DD-MMMM YYYY')}</p>
          <p>VAQT: {moment().format('HH:mm')}</p>
        </Info>
      </Header>

      <Section>
        <p>PAKTIYENTNING ISMI: {patientName}</p>
        <p>PAKTIYENT ID: {patientId}</p>
        <p>SHIFOKOR: Dr. {doctorName}</p>
        <p>XIZMAT KO‘RSATISH: {clinicName}</p>
      </Section>

      <Section>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <h3>Xizmatlar Ma'lumoti</h3>
        {data.map((item, index) => {
          const price = path(['cost'], item) * item.amount
          const service = path(['name'], item)

          return (
            <ServiceItem key={index}>
              <span>{index + 1}. {service} x{item.amount}</span>
              <span>{numberFormat(price, 'so`m')} </span>
            </ServiceItem>
          )
        })}
      </Section>

      <Totals>
        <p>JAMI SUMMA: {numberFormat(totalPrice, 'so`m')}</p>
      </Totals>

      <Section>
        <p>IMZO: __________________________</p>
      </Section>

      <ContactInfo>
        <p>TIBBIYOT MARKAZI MANZILI:</p>
        <p>{address}</p>
        <p>Telefon: {phoneNumber}</p>
      </ContactInfo>

      <Footer>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>"{clinicName}" stomatologiya markazini tanlaganingiz uchun rahmat!</p>
        <p>Ushbu chekni o‘z yozuvingiz uchun saqlang.</p>
      </Footer>
    </Container>
  )
}

export default ServiceInvoice
