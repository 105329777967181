import React from 'react'
import { TOnSubmit } from 'types'
import { Sidebar } from 'primereact/sidebar'
import styled from 'styled-components'
import { TabPanel, TabView } from 'primereact/tabview'
import { Badge } from 'primereact/badge'
import Button from '../../../components/Button/Button'
import Notifications from './notifications'

interface Props {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: TOnSubmit;
}

const Header = styled('div')`
    & h3 {
        margin-bottom: 0px;
    };
    .p-tabview-panels {
        padding: 0px !important;
    }
`

const NotificationDrawer = (props: Props) => {
  const { open, onClose, onSubmit, loading } = props
  const tab3HeaderTemplate = (name) => {
    return (
      <div className="flex align-items-center gap-2" style={{ cursor: 'pointer' }}>
        <span className="font-bold white-space-nowrap">{name}</span>
        {/* @ts-ignore */}
        <Badge value="2" severity="secondary" />
      </div>
    )
  }

  React.useEffect(() => {
    if (open) document.body.style.overflow = 'hidden'
    else { // @ts-ignore
      document.body.style = null
    }
  }, [open])
  return (
    <Sidebar
      visible={open}
      position="right"
      onHide={onClose}
      className={'lg:w-30rem'}
      content={({ closeIconRef, hide }) => (
        <div className="min-h-screen flex relative lg:static surface-ground">
          <div id="app-sidebar-2" className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none w-full">
            <div className="flex flex-column h-full">
              <Header className="flex-shrink-0 shadow-1">
                <div className={'flex align-items-center justify-content-between px-4 pt-3 mb-2'}>
                  <h3>Уведомления</h3>
                  <span>
                    {/* @ts-ignore */}
                    <Button type="button" ref={closeIconRef} onClick={(e) => hide(e)} icon="pi pi-times" rounded
                      outlined className="h-2rem w-2rem"></Button>
                  </span>
                </div>
                <TabView>
                  <TabPanel header={tab3HeaderTemplate('Все')} />
                  <TabPanel header={tab3HeaderTemplate('Записи')} leftIcon="pi pi-calendar mr-2" />
                  <TabPanel header={tab3HeaderTemplate('Системные')} leftIcon="pi pi-info-circle mr-2" />
                </TabView>
              </Header>
              <Notifications/>
            </div>
          </div>
        </div>
      )}
    ></Sidebar>
  )
}

export default NotificationDrawer
