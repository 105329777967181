const ID = ':id(\\d+)'
const IDSecond = ':idSecond(\\d+)'

// Root
export const ROOT_URL = '/'

export const SIGN_IN_URL = '/sign-in'

export const PATIENTS_URL = '/patients'
export const PATIENTS_DETAIL_URL = '/patients/%s'
export const PATIENTS_DETAIL_PATH = '/patients/:id'
export const PATIENTS_DETAIL_FILES_PATH = `${PATIENTS_DETAIL_PATH}/files`
export const PATIENTS_DETAIL_FILES_URL = `${PATIENTS_DETAIL_URL}/files`
export const PATIENTS_QUESTIONNAIRE_URL = `${PATIENTS_DETAIL_URL}/services`
export const PATIENTS_QUESTIONNAIRE_PATH = `${PATIENTS_DETAIL_PATH}/services`
export const PATIENTS_UPDATE_URL = `${PATIENTS_DETAIL_URL}/update`
export const PATIENTS_UPDATE_PATH = `${PATIENTS_DETAIL_PATH}/update`

export const CALENDAR_URL = '/calendar'

export const REPORTS_URL = '/reports'

export const SMS_URL = '/sms'

export const NOTIFICATION = '/notification'

export const SETTING = 'setting'
export const SETTING_URL = `/${SETTING}`
export const SETTING_PROFILE_URL = `/${SETTING}/`
export const SETTING_PASSWORD_URL = `/${SETTING}/password`

export const PERSON = 'person'
export const SETTING_PERSON_URL = `/${SETTING}/${PERSON}`
export const PERSON_CREATE_URL = `/${PERSON}/create`

export const CLINICS = 'clinics'
export const SETTING_CLINICS_URL = `/${SETTING}/${CLINICS}`

export const SMS_TEMPLATES = 'sms-templates'
export const SETTING_SMS_TEMPLATES_URL = `/${SETTING}/${SMS_TEMPLATES}`

export const SMS_MESSAGES = 'messages'
export const SMS_MESSAGES_URL = `/${SETTING}/${SMS_MESSAGES}`

export const SERVICES = 'services'
export const SERVICES_URL = `/${SERVICES}/`

export const ROLES = 'roles'
export const SETTING_ROLES_URL = `/${SETTING}/${ROLES}`

// queries
export const ORDERING_QUERY = 'ordering'
export const SEARCH_QUERY = 'search'
