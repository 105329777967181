import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import SettingSmsTemplatesCreateDrawer from './SettingSmsTemplatesCreateDrawer'
import SettingSmsTemplatesTable from './SettingSmsTemplatesTable'

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}
const SettingSmsTemplatesGrid = ({
  createDrawer, listData, updateData,
  deleteData
}: Props) => {
  return (
    <>
      <SettingSmsTemplatesTable
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
        listData={listData}
      />
      <SettingSmsTemplatesCreateDrawer
        {...createDrawer}
      />
      <SettingSmsTemplatesCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SettingSmsTemplatesGrid
