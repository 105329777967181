import { omit, pick, join, isEmpty, pipe, prop } from 'ramda'
import { FORM_ERROR } from 'final-form'
import { useToast } from '../context/ToastContext'

// const transform = pipe(prop('1'), map(item => Object.values(item)))
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormError = props => {
  const { toastRef } = useToast()
  const mapResponseToFormError = (data) => {
    const errors = prop('errors', data) || []
    if (errors && !isEmpty(errors)) {
      toastRef.current.show({ severity: 'error', summary: 'Message', detail: 'Ошибка!', life: 3000 })
      const fieldErrors = errors.reduce((result, obj) => {
        result[obj.property] = obj.message
        return result
      }, {})
      return fieldErrors
    } else if (data?.message) {
      toastRef.current.show({ severity: 'error', summary: 'Ошибка', detail: data?.message, life: 3000 })
    } else {
      toastRef.current.show({ severity: 'error', summary: 'Message', detail: 'Ошибка!', life: 3000 })
    }
    return {
      ...errors,
      [FORM_ERROR]: join(', ', errors)
    }
  }
  return {
    mapResponseToFormError
  }
}

const toArray = err => {
  if (!err) {
    return []
  }

  if (Array.isArray(err)) {
    return err
  }

  return [err]
}
export const getFieldError = pipe(
  prop('submitError'),
  toArray,
  join(',')
)
