import {
  find,
  propOr, whereEq, isEmpty
} from 'ramda'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { OverlayPanel } from 'primereact/overlaypanel'
import React, { Fragment, useRef, useState } from 'react'
import { confirmDialog } from 'primereact/confirmdialog'
import { Tooltip } from 'primereact/tooltip'
import { Menu } from 'primereact/menu'
import { TTeeth, useServices } from 'containers/patients/services/ServicesProvider'
import { useToast } from 'context/ToastContext'
import { Button } from 'primereact/button'
import styled from 'styled-components'
import { TStatus, TTeethService } from '../../../types'
import { TTeethStatuses } from '../../../types/enums'

const OverlayPanelStyled = styled(OverlayPanel)<{top?: string}>`
    width: 300px;
    top: ${({ top }) => top ? `${top} !important` : ''}
`

const MenuStyled = styled(Menu)<{top?: string}>`
    top: ${({ top }) => top ? `${top} !important` : ''}
`
const TeethSwitcher = props => {
  const {
    fill,
    activeFill,
    isBody,
    pendingFill,
    savedFill,
    selectedFill,
    children,
    data
  } = props
  const [OPStyles, setOPStyles] = useState<{top?: string}>({})
  const uniqId = `${data.field}-${data.id}-${data.type}`
  const { toastRef } = useToast()
  const menu = useRef(null)
  const op = useRef(null)
  const { teeth, handleUpdateTeeth, handleDeleteOrAddTeeth, isDeleted, handleDeleteService, handleSetStatusPending } = useServices()

  const pred = whereEq({ id: data?.id, type: data?.type, field: data?.field })
  const currentTeeth = find(pred)(teeth)
  const hasService = currentTeeth?.services && !isEmpty(currentTeeth?.services)
  const status = propOr<TStatus, TTeeth, TStatus>(null, 'status', currentTeeth)

  const currentServices = propOr([], 'services', currentTeeth) as TTeethService[]
  // saved
  // pending
  // selected

  const colorActive = status === TTeethStatuses.SELECTED
    ? selectedFill
    : status === TTeethStatuses.PENDING
      ? pendingFill
      : ((status === TTeethStatuses.SAVED || status === TTeethStatuses.PAID || status === TTeethStatuses.ACTIVE) && isBody)
        ? '#f9c37d'
        : (status === TTeethStatuses.SAVED || status === TTeethStatuses.PAID || status === TTeethStatuses.ACTIVE)
          ? savedFill
          : status === TTeethStatuses.DELETED
            ? '#FF0000'
            : null
  const onClick = (event) => {
    if (status === TTeethStatuses.SAVED || status === TTeethStatuses.ACTIVE) {
      setOPStyles({ top: `${event.pageY}px` })
      op.current.toggle(event)
    } else {
      handleUpdateTeeth(data)
    }
  }
  const DeleteButton = (service) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-row-editor-init p-link" type={'button'}
        onClick={() => handleDeleteService(data, service)}
      />
    )
  }

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Добавить"
        icon="pi pi-plus"
        className="p-button-info ml-auto"
        onClick={() => handleSetStatusPending(data)}
      />
    </div>
  )
  const finalProps = {
    class: uniqId,
    onClick: isDeleted(data) || status === TTeethStatuses.PAID ? null : onClick,
    style: {
      fill: colorActive || fill,
      cursor: isDeleted(data) ? 'not-allowed' : 'pointer'
    }
  }

  const acceptDeleteAdd = (currentData) => {
    handleDeleteOrAddTeeth(currentData)
    isDeleted(currentData)
      ? toastRef.current.show({ severity: 'info', summary: 'Подтерждение', detail: 'Зуб добавлен', life: 3000 })
      : toastRef.current.show({ severity: 'warn', summary: 'Подтерждение', detail: 'Зуб удален', life: 3000 })
  }

  const handleCommand = () => confirmDialog({
    message: isDeleted(data) ? 'Вы действительно хотите добавить зуб?' : 'Вы действительно хотите удалить зуб',
    header: 'Подтверждение',
    icon: 'pi pi-exclamation-triangle',
    acceptClassName: isDeleted(data) ? 'p-button-info' : 'p-button-danger',
    accept: () => acceptDeleteAdd(data)
  })
  const items = status === TTeethStatuses.DELETED
    ? [
      {
        label: 'Опции',
        items: [
          {
            label: 'Добавить зуб',
            icon: 'pi pi-plus',
            command: handleCommand
          }
        ]
      }
    ]
    : [
      {
        label: 'Опции',
        items: [
          {
            label: 'Добавить услугу',
            icon: 'pi pi-plus',
            command: () => handleUpdateTeeth(data)
          },
          {
            label: 'Удалить зуб',
            icon: 'pi pi-times',
            command: handleCommand
          }
        ]
      }
    ]

  const confirm2 = (event, currentData) => {
    menu.current.toggle(event)
    setOPStyles({ top: `${event.pageY}px` })
  }

  const bodyProps = {
    onClick: (event) => confirm2(event, data),
    class: uniqId,
    style: {
      fill: colorActive,
      cursor: 'pointer',
      position: 'relative',
    }
  }
  return (
    <Fragment>
      {hasService && (
        <Tooltip target={`.${uniqId}`}>
          {currentServices.map(service => (
            <>
              {service.name}
              <br/>
            </>
          ))}
        </Tooltip>
      )}
      {React.cloneElement(children, isBody ? bodyProps : finalProps)}
      {!isBody && (
        <>
          <OverlayPanelStyled ref={op} showCloseIcon id="overlay_panel" className="overlaypanel-demo" top={OPStyles?.top}>
            <DataTable
              header={header}
              value={currentServices}
              rows={15}
              selectionMode="single"
              onSelectionChange={() => null}
            >
              <Column field="name" header="Услуга" />
              <Column rowEditor headerStyle={{ minWidth: '4rem' }} body={DeleteButton} style={{ width: '5%' }} />
            </DataTable>
          </OverlayPanelStyled>
        </>
      )}
      {isBody && (
        <MenuStyled model={items} popup ref={menu} top={OPStyles?.top} id="popup_menu" />
      )}
    </Fragment>
  )
}

export default TeethSwitcher
