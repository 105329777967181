import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import moment from 'moment'
import { useCreateModal, useDelete, useFetchList } from '../../hooks'
import * as stateNames from '../../constants/states'
import { getFullName, getListParamsFromProps, getSerializedData } from '../../utils/get'
import useUpdateModal from '../../hooks/useUpdateModal'
import { TTeethService } from '../../types'
import { DATE_BACKEND } from '../../constants/dateFormat'
import SmsGrid from './components/SmsGrid'
import {
  smsCreateAction, smsDeleteAction,
  smsListAction,
  smsUpdateAction
} from './smsActions/smsActions'
import { fields } from './components/SmsCreateDrawer'

const SmsContainer = props => {
  const listData = useFetchList({
    action: smsListAction,
    stateName: stateNames.SMS_LIST
  })
  const createDrawer = useCreateModal({
    serializer: (values) => ({
      ...getSerializedData(fields, values),
    }),
    action: smsCreateAction,
    successAction: smsListAction,
    stateName: stateNames.SMS_CREATE,
  })
  const deleteData = useDelete({
    stateName: stateNames.SMS_DELETE,
    action: smsDeleteAction,
    successAction: smsListAction
  })
  const updateData = useUpdateModal({
    listData,
    action: smsUpdateAction,
    stateName: stateNames.SMS_CREATE,
    serializer: (values) => ({
      ...getSerializedData(fields, values),
    }),
    successAction: smsListAction,
    initialValues: (data: any) => {
      return {
        ...data,
        gender: data?.gender || null,
        dates: (data?.dates || []).map((d) => moment(d, DATE_BACKEND).toDate()),
        patients: (data?.patientsFull || []).map(i => ({ ...i, name: getFullName(i) })),
      }
    }
  })

  return (
    <PermissionWrapper permissions={['READ']}>
      <SmsGrid
        listData={listData}
        createDrawer={createDrawer}
        deleteData={deleteData}
        updateData={updateData}
      />
    </PermissionWrapper>
  )
}

export default SmsContainer
