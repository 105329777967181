import * as ROUTES from 'constants/routes'
import React, { FC } from 'react'
import { Badge } from 'primereact/badge'
import { OPEN_NOTIFICATION } from './constants'

export const MENU_KEYS = {
  PRICES: 'prices',
  SETTING: 'setting',
  REPORTS: 'reports',
  SERVICE: 'service',
  SMS: 'SMS',
  NOTIFICATION: 'NOTIFICATION',
  PATIENTS: 'patients',
  CALENDAR: 'calendar'
}

export type MenusTypesObject = {
  label: string
  key: string
  query?: any
  role?: string[]
  url: string
  exact: boolean
  icon: JSX.Element
  isAuth: boolean
  withParams?: boolean
  children?: JSX.Element
}

export default [
  {
    label: 'Услуги',
    exact: true,
    key: MENU_KEYS.SERVICE,
    icon: (<i className="pi pi-wallet" />),
    url: ROUTES.ROOT_URL,
    isAuth: true
  },
  {
    label: 'Пациенты',
    exact: true,
    key: MENU_KEYS.PATIENTS,
    icon: (<i className="pi pi-users" />),
    url: ROUTES.PATIENTS_URL,
    isAuth: true
  },
  {
    label: 'Календарь',
    exact: true,
    key: MENU_KEYS.CALENDAR,
    icon: (<i className="pi pi-calendar" />),
    url: ROUTES.CALENDAR_URL,
    isAuth: true
  },
  {
    label: 'Настройки',
    exact: false,
    key: MENU_KEYS.SETTING,
    icon: (<i className="pi pi-cog" />),
    url: ROUTES.SETTING_URL,
    isAuth: true
  },
  {
    label: 'Отчеты',
    exact: true,
    key: MENU_KEYS.REPORTS,
    icon: (<i className="pi pi-chart-bar" />),
    url: ROUTES.REPORTS_URL,
    isAuth: true
  },
  {
    label: 'SMS',
    exact: true,
    key: MENU_KEYS.SMS,
    icon: (<i className="pi pi-envelope" />),
    url: ROUTES.SMS_URL,
    isAuth: true
  },
  {
    label: 'Уведомления',
    exact: true,
    key: MENU_KEYS.NOTIFICATION,
    icon: (<i className="pi pi-bell p-overlay-badge" >
      <Badge value="5+" severity="danger"></Badge>
    </i>),
    role: ['superadmin'],
    query: {
      [OPEN_NOTIFICATION]: true
    },
    isAuth: true,
    withParams: true
  }
] as MenusTypesObject[]
