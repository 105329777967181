import * as API from 'constants/api'
import { PERMISSIONS_LIST } from 'constants/api'
import React from 'react'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DrawerDialog from 'components/Drawer/DrawerDialog'
import { InputTextUI } from 'components/Form'
import { TOnSubmit } from 'types'
import UniversalMultiSelectField from 'components/Form/Select/UniversalMultiSelectField'
import { DrawerBottomActions } from 'components/Drawer'
import { ButtonUI } from 'components/Button'
import { find, map, pathOr, pipe, propEq } from 'ramda'
import { useStore } from 'react-redux'
import { UniversalSearchField } from '../../../../components/Form/Select'
import { getOptions } from '../../../../utils/searchField'
import toSnakeCase from '../../../../utils/toSnakeCase'
import axios from '../../../../utils/axios'
import { PERMISSION_KEYS } from '../../../../constants/constants'

interface Props {
  open: boolean;
  initialValues?: Record<string, any>;
  onClose: () => void;
  onSubmit: TOnSubmit;
  loading: boolean;
  failed: boolean;
}

export const fields = [
  'name',
  'clinicId',
  'permissions',
  'note'
]
const SettingRolesCreateDrawer = (props: Props) => {
  const { open, onClose, onSubmit, loading, initialValues } = props

  const store = useStore()
  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="p-fluid">
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'name'}
                    label={'Название'}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Клиника"
                    name="clinicId"
                    api={API.CLINICS_LIST}
                    component={UniversalSearchField}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Разрешения"
                    name="permissions"
                    api={API.PERMISSIONS_LIST}
                    getOptionsCustom={(store, { api, params, search }) => {
                      const defaultParams = toSnakeCase({ search, ...params })
                      return axios(store)
                        .get(api, { params: defaultParams })
                        .then((response) => {
                          const permissions = pathOr({}, ['data', 'data', 'permissions'], response)
                          const perms = Object.keys(permissions)
                          return map((key) => find(propEq(key, 'key'), PERMISSION_KEYS), perms)
                        })
                    }}
                    component={UniversalMultiSelectField}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={InputTextUI}
                    name={'note'}
                    label={'Заметка'}
                  />
                </FieldWrapper>
                <DrawerBottomActions>
                  <ButtonUI
                    loading={loading}
                    severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                  </ButtonUI>
                </DrawerBottomActions>
              </div>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
}

export default SettingRolesCreateDrawer
