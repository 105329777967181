import React from 'react'
import { Calendar, CalendarProps } from 'primereact/calendar'
import { getFieldError } from 'utils/form'
import classNames from 'classnames'
import { FieldRenderProps } from 'react-final-form'
import { LabelUI } from './index'

type Props = {

} & FieldRenderProps<any> & CalendarProps
const DateUI = (props: Props) => {
  const { label, input, required, error, meta, ...otherProps } = props
  const inputError = error || getFieldError(meta)
  const randomNumber = Math.random().toString()
  return (
    <>
      {label && <LabelUI htmlFor={randomNumber}
        required={required}
      >{label}</LabelUI>}
      <Calendar
        dateFormat="d M yy"
        className={classNames({ 'p-invalid': inputError })}
        id={randomNumber}
        readOnlyInput
        {...input}
        {...otherProps}
        required={required}
      />
    </>
  )
}

export default DateUI
