import React from 'react'
import styled from 'styled-components'
import { isEmpty, pathOr } from 'ramda'
import { ProgressSpinner } from 'primereact/progressspinner'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`

const Image = styled.div<{isSelected?: boolean}>`
  background: var(--highlight-bg);
  cursor: pointer;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  height: 150px;
  border: ${({ isSelected }) => isSelected ? '1px solid var(--primary-color)' : '1px solid transparent'};
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

const Wrapper = styled('div')`
  
`

type Props = {
  loading: boolean
  input: any
  selectedImage: any
  setSelectedImage: (image) => void
}
const PatientsLeftSide = ({ input, setSelectedImage, selectedImage, loading }: Props) => {
  const photos = pathOr([], ['value'], input) || []
  return (
    <Wrapper className={'card'}>
      {isEmpty(photos) ? (
        <Image>
          <i className="text-5xl mb-3 mt-6 text-color-secondary pi pi-images"></i>
          <p>
            <b>
                Загрузите фото
            </b>
          </p>
        </Image>
      ) : (
        <Grid>
          {photos.map((image, index) => {
            const withoutDomen = image
            const isLast = (index + 1) === photos.length
            const isSelected = selectedImage === withoutDomen
            return isLast && loading ? (
              <Image key={image}>
                <div className="flex justify-content-center h-30rem align-items-center">
                  <ProgressSpinner/>
                </div>
              </Image>
            ) : (
              <Image key={image} onClick={() => setSelectedImage(withoutDomen)} isSelected={isSelected}>
                <img src={image}/>
              </Image>
            )
          })}
        </Grid>
      )}
    </Wrapper>
  )
}

export default PatientsLeftSide
