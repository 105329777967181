import * as actionTypes from 'constants/actionTypes'
import * as stateNames from 'constants/states'
import { combineReducers } from 'redux'
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import { compose, forEach, toPairs } from 'ramda'
import createThunkReducer from 'utils/createThunkReducer'
import { AsyncReducers, TGetDataFromState } from 'types'
import snackbarReducer from 'utils/snackbarReducer'
import confirmDialogReducer from 'components/ConfirmDialogUi/reducer'
import errorReducer from './errorReducer'
import person from './person'
import patients from './patients'
import clinics from './clinics'
import roles from './roles'
import services from './services'
import calendar from './calendar'
import reports from './reports'
import sms from './sms'

export type RootState = {
  login: TGetDataFromState<any>;
  userInfo: any;
}

export const makeRootReducer = (asyncReducers: AsyncReducers) =>
  combineReducers({
    [stateNames.SIGN_IN]: createThunkReducer(actionTypes.SIGN_IN),
    [stateNames.PROFILE]: createThunkReducer(actionTypes.PROFILE),
    [stateNames.PROFILE_AVATAR]: createThunkReducer(actionTypes.PROFILE_AVATAR),

    ...calendar,
    ...patients,
    ...clinics,
    ...roles,
    ...sms,
    ...services,
    ...reports,
    ...person,
    // other
    snackbar: snackbarReducer(),
    error: errorReducer(),
    confirmDialog: confirmDialogReducer,
    ...asyncReducers
  })

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export const injectReducers = (store, reducers) =>
  compose(
    forEach(([key, reducer]) => injectReducer(store, { key, reducer })),
    // @ts-ignore
    toPairs
    // @ts-ignore
  )(reducers)

type ThunkResult = {
  type: string;
  value: Record<string, any>;
}
export type PromiseThunksResult = (action: any) => Promise<ThunkResult>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const usePromiseDispatch: () => (action: any) => Promise<any> = useDispatch
