import { PATIENTS_DETAIL_FILES_URL } from 'constants/routes'
import React from 'react'
import AppTopBar from 'components/AppTopBar'
import Button from 'components/Button/Button'
import styled from 'styled-components'
import Container from 'components/Container'
import { useRedirect } from 'hooks'
import { sprintf } from 'sprintf-js'
import { useParams } from 'react-router-dom'
import PatientsLeftSide from './PatientsLeftSide'
import PatientsRightSide from './PatientsRightSide'

const Grid = styled.div`
  display: grid;
  gap: 30px;
  height: 70vh;
  grid-template-columns: 300px 1fr;
`
type Props = {
}
const PatientsDetailGrid = ({ itemData, deleteData }) => {
  const { id }: {id?: string} = useParams()
  const gotFiles = useRedirect(sprintf(PATIENTS_DETAIL_FILES_URL, id))

  return (
    <div>
      <AppTopBar
        title={'Пациенты'}
        leftActions={(
          <Button
            onClick={gotFiles}
            type="button" label="Файлы" icon="pi pi-folder" outlined />
        )}
        rightActions={(
          <Button
            onClick={() => deleteData.onSubmit(id)}
            severity="danger"
          >
            Удалить пациента
          </Button>
        )}
      />
      <Container>
        <Grid>
          <PatientsLeftSide itemData={itemData}/>
          <PatientsRightSide/>
        </Grid>
      </Container>
    </div>
  )
}

export default PatientsDetailGrid
