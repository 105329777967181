import * as stateNames from 'constants/states'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import { useCreateModal, useDelete, useFetchList } from 'hooks'
import { getSerializedData } from 'utils/get'
import useUpdateModal from 'hooks/useUpdateModal'
import { find, map, pathOr, pipe, propEq } from 'ramda'
import { PERMISSION_KEYS } from '../../../constants/constants'
import SettingRolesGrid from './components/SettingRolesGrid'
import {
  rolesCreateAction, rolesDeleteAction,
  rolesListAction,
  rolesUpdateAction
} from './settingRolesActions/settingRolesActions'
import { fields } from './components/SettingRolesCreateDrawer'

const SettingRolesContainer = props => {
  const listData = useFetchList({
    action: rolesListAction,
    stateName: stateNames.ROLES_LIST
  })
  const createDrawer = useCreateModal({
    serializer: (values) => getSerializedData(fields, values),
    action: rolesCreateAction,
    successAction: rolesListAction,
    stateName: stateNames.ROLES_CREATE,
  })
  const deleteData = useDelete({
    stateName: stateNames.ROLES_DELETE,
    action: rolesDeleteAction,
    successAction: rolesListAction
  })

  const updateData = useUpdateModal({
    listData,
    action: rolesUpdateAction,
    stateName: stateNames.ROLES_CREATE,
    serializer: (values) => getSerializedData(fields, values),
    successAction: rolesListAction,
    initialValues: (data) => {
      return {
        ...data,
        permissions: pipe(
          pathOr([], ['permissions']),
          map((_id) => find(propEq(_id, '_id'), PERMISSION_KEYS))
        )(data)
      }
    }
  })

  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingRolesGrid
        listData={listData}
        createDrawer={createDrawer}
        deleteData={deleteData}
        updateData={updateData}
      />
    </PermissionWrapper>
  )
}

export default SettingRolesContainer
