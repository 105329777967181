const mainColor = '#cb533c'
const themes = () => {
  return {
    palette: {
      primary: mainColor
    },
    background: {
      primary: '#F6F6F6'
    },
    color: {
      primary: '#2E384C'
    },
    fontSize: {
      primary: '16px'
    },
    scrollbar: {
      thumb: '#DEDEDE'
    },
    transition: {
      fast: '.1s ease-in-out',
      medium: '.2s ease-in-out',
      long: '.3s ease-in-out'
    },
    container: {
      maxWidth: '1575px'
    },
    gradient: {

    }
  }
}

export default themes
