import axios, { getPayloadFromError, getPayloadFromSuccess } from '../../utils/axios'
import * as API from '../../constants/api'
import * as ACTION_TYPES from '../../constants/actionTypes'

export const reportsListAction = params => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.DEBTS_LIST, { params })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.DEBTS_LIST}`,
      payload
    })
  }
}

export const reportsDebsAction = params => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.STATS_LIST, { params })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.REPORT_STATS_LIST}`,
      payload
    })
  }
}
