import React, { FC } from 'react'
import { useStore } from 'react-redux'
import {
  getOption,
  getOptions,
  defaultGetText,
  defaultGetValue
} from 'utils/searchField'
import isEqual from 'react-fast-compare'
import { FieldRenderProps } from 'react-final-form'
import { Merge } from 'types'
import SearchField from './SearchField'

type Props = {
  api: string,
  apiDetail?: string,
  params: { },
  itemText?: string[]
  getText?: (any) => string
}
const UniversalSearchField: FC<Merge<FieldRenderProps<any>, Props>> = props => {
  const { api, params, valueText, apiDetail, itemText, getText, ...rest } = props

  const store = useStore()

  return (
    <SearchField
      getText={getText || defaultGetText(itemText)}
      getValue={defaultGetValue(valueText)}
      getOptions={search => getOptions(store, { api, params, search })}
      getOption={getOption(store, { api, apiDetail, params })}
      isClearable={true}
      itemText={itemText}
      {...rest}
    />
  )
}

UniversalSearchField.defaultProps = {
  itemText: ['name'],
  valueText: ['_id']
}

export default React.memo(UniversalSearchField, isEqual)
