import React, { useRef } from 'react'
import styled from 'styled-components'
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { pathOr, prop } from 'ramda'

const Image = styled.div`
  height: calc(100vh - 125px - 125px);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`
type Props = {
  loading: boolean
  input: any
  selectedImage: any
}
const PatientsRightSide = ({ input, selectedImage }: Props) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)
  const photos = pathOr([], ['value'], input) || []

  // @ts-ignore
  const selectedPhoto = selectedImage || prop('0', photos) || ''
  const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current
      zoomToElement('imgExample')
    }
  }

  const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <>
      <button onClick={() => zoomIn()}>+</button>
      <button onClick={() => zoomOut()}>-</button>
      <button onClick={() => resetTransform()}>x</button>
    </>
  )
  return (
    <div style={{ width: '100%' }}>
      {selectedPhoto ? <TransformWrapper
        initialScale={1}
        ref={transformComponentRef}
      >
        {(utils) => (
          <TransformComponent
            wrapperStyle={{ width: '100%' }}
            contentStyle={{ width: '100%' }}
          >
            <Image>
              <img src={selectedPhoto}/>
            </Image>
          </TransformComponent>
        )}
      </TransformWrapper> : (
        <div className={'card'}>
          <Image>
            <i className="text-7xl mb-3 mt-6 text-color-secondary pi pi-file-excel"></i>
            <p>
              <b>
                Ничего не выбрано
              </b>
            </p>
          </Image>
        </div>
      )}
    </div>
  )
}

export default PatientsRightSide
