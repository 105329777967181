import settingMenus from 'constants/settingMenus'
import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Block = styled.div`

`
const navClasses = ['py-3', 'pl-7', 'block']
const activeClasses = ['surface-hover']

const SettingMenu = props => {
  return (
    <div className={'text-xl mt-6'}>
      {settingMenus.map((item, index) => {
        return (
          <Block key={index} className={'mt-3'}>
            <p>{item.label}</p>
            <div className={'mt-3'}>
              {item.menus.map((menu, key) => {
                return (
                  <div
                    key={key}
                  >
                    <NavLink
                      style={{ color: 'var(--primary-color)' }}
                      to={menu.url}
                      exact={menu?.exact}
                      className={isActive => {
                        const classes = isActive ? [...activeClasses, ...navClasses].join(' ') : navClasses.join(' ')
                        return classes
                      }}
                    >
                      {menu.label}
                    </NavLink>
                  </div>
                )
              })}
            </div>
          </Block>
        )
      })}
    </div>
  )
}

export default SettingMenu
