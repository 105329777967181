import React, { FC } from 'react'
import {
  getStaticOption,
  getStaticOptions,
  defaultGetText,
  defaultGetValue
} from 'utils/searchField'
import { Merge } from 'types'
import { FieldRenderProps } from 'react-final-form'
import SearchField from './SearchField'

export interface ObjectStaticItem {
  _id: number | string,
  name: string
}
type Props = {
  list: ObjectStaticItem[],
  itemText: string[]
}
const UniversalStaticSelectField: FC<Merge<FieldRenderProps<any, HTMLElement>, Props>> = (props) => {
  const { list, itemText } = props
  return (
    <SearchField
      getText={defaultGetText(itemText)}
      getValue={defaultGetValue(['_id'])}
      getOptions={search => getStaticOptions(search, list)}
      getOption={id => getStaticOption(id, list)}
      isStatic={true}
      {...props}
    />
  )
}

UniversalStaticSelectField.defaultProps = {
  itemText: ['name']
}

export default UniversalStaticSelectField
