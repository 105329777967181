import equal from 'react-fast-compare'
import { useTypedSelector, usePromiseDispatch } from 'etc/reducers'
import { TUseUpdateModal, TUseUpdateModalParams } from 'types/hooks'
import { TGetDataFromState } from 'types'
import { getDataFromState, getListParamsFromProps } from 'utils/get'
import { useHistory } from 'react-router-dom'
import { find, pathOr, pipe, propEq } from 'ramda'
import toSnakeCase from '../utils/toSnakeCase'
import { useFormError } from '../utils/form'
import { DEFAULT_PICK_PARAMS } from '../utils/isEquals'
import useCustomModal from './useCustomModal'
import { getListParams } from './useFetchList'

export const onOpenModal = ({ value, params, history, onOpen }) => {
  onOpen()
  if (params.onOpen) {
    params.onOpen(params.key, value, history)
  }
}

export const onCloseModal = ({ onClose, params, history }) => {
  onClose()
  if (params.onClose) {
    params.onClose(params.key, history)
  }
}

const useUpdateModal = <T extends any>(params: TUseUpdateModalParams): TUseUpdateModal<T> => {
  const {
    listData,
    key = 'updateModal',
    action,
    stateName,
    onSuccess,
    serializer = toSnakeCase,
    mapper = getListParams,
    pickParams = DEFAULT_PICK_PARAMS,
    initialValues,
    autoClose = true,
    successAction
  } = params

  const { open, onOpen, onClose, query } = useCustomModal({ key, autoClose })

  const { mapResponseToFormError } = useFormError()

  if (!stateName) {
    throw Error('useCreateModal hook requires stateName!')
  }

  const dispatch = usePromiseDispatch()
  const state = useTypedSelector<TGetDataFromState<T>>(state => getDataFromState(stateName, state), equal)

  const history = useHistory()
  const onSubmit = (values: Record<string, any>) => {
    const serializeValues = serializer(values)
    return dispatch(action(query, serializeValues))
      .then(data => {
        if (onSuccess) onSuccess(data, values)
        else if (successAction) dispatch(successAction(mapper(history, pickParams)))
      })
      .then(() => onClose())
      .catch(mapResponseToFormError)
  }

  const initialData = pipe(
    pathOr([], ['data', 'data']),
    find(propEq(query, '_id'))
  )(listData) || {}
  return {
    open,
    query,
    onOpen,
    onClose,
    onSubmit,
    initialValues: initialValues ? initialValues(initialData) : (initialData || {}),
    ...state
  }
}

export default useUpdateModal
