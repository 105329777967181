import React from 'react'
import { Column } from 'primereact/column'
import TableUI from 'components/Table/TableUI'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import SearchUI from 'components/SearchUI/SearchUI'
import { path, pathOr } from 'ramda'
import { getFullName } from '../../../../utils/get'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import { TPagination } from '../../../../types'

const Card = styled.div`
  
`
const Photo = styled.div`
  margin-right: 178px;
`

const NameBody = (item) => {
  return getFullName(item)
}
const SettingPersonTable = ({ listData, createDrawer, deleteData, updateData }) => {
  const value = pathOr([], ['data', 'data'], listData)
  const pagination = path<TPagination>(['data', 'pagination'], listData)

  const header = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          onClick={createDrawer.onOpen}
          severity="success"
        >
            + Новый пользователь
        </Button>
        <SearchUI/>
      </div>
    )
  }

  const OptionButton = (item) => {
    return (
      <Dropdown model={[
        {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            updateData.onOpen(item._id)
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => deleteData.onSubmit(item._id)
        }
      ]}/>
    )
  }

  return (
    <Card className={'w-full'}>
      <TableUI
        pagination={pagination}
        loading={listData.loading || deleteData.loading}
        value={value}
        header={header()}
        rowHover={true}
      >
        <Column field="email" header="Email" />
        <Column field="email" header="Имя" body={NameBody} />
        <Column field="phoneNumber" header="Номер телефона" />
        <Column headerStyle={{ minWidth: '4rem' }} body={OptionButton} style={{ width: '5%' }} />
      </TableUI>
    </Card>
  )
}

export default SettingPersonTable
