import React, { useRef } from 'react'
import { Menu } from 'primereact/menu'
import { ButtonUI } from '../Button'

const Dropdown = ({ model }) => {
  const menuLeft = useRef(null)
  return (
    <>
      <ButtonUI icon="pi pi-ellipsis-v" rounded text aria-label="Filter" onClick={(event) => menuLeft.current.toggle(event)} />
      <Menu model={model} popup ref={menuLeft} id="popup_menu_left" />
    </>
  )
}

export default Dropdown
