import { curry, map, pipe, prop, sum } from 'ramda'
import toNumber from './toNumber'

const sumBy = curry((key, list: any[]) => pipe(
  map(pipe(
    prop(key),
    toNumber)
  ),
  sum
)(list))

export default sumBy
