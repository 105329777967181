import * as ACTION_TYPES from 'constants/actionTypes'
import * as API from 'constants/api'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { deleteCookie, setCookie } from 'utils/cookie'
import { path } from 'ramda'

export const authAction = params => {
  return (dispatch, getState) => {
    const ONE_YEAR = 365
    const payload = axios({ dispatch, getState })
      .post(API.SIGN_IN, params)
      .then(response => {
        const token = path(['data', 'data', 'access_token'], response)
        setCookie('token', token, ONE_YEAR)
        return { token }
      })
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.SIGN_IN}`,
      payload
    })
  }
}

export const authClearAction = () => {
  return {
    type: `${ACTION_TYPES.SIGN_IN}_CLEAR`
  }
}

export const unauthAction = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .delete(API.SIGN_IN)
      .then(() => {
        deleteCookie('token')
        dispatch(authClearAction())
        return null
      })
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.SIGN_IN}`,
      payload
    })
  }
}
export const profileAction = (token) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState, getToken: token }, false)
      .get(API.PROFILE)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.PROFILE}`,
      payload
    })
  }
}
