import React from 'react'
import { Button } from 'primereact/button'
import styled, { css } from 'styled-components'

export interface DrawerProps {
  withHeader?: boolean
  width?: string
  children: JSX.Element
  open: boolean
  onClose: () => void
}

const Mask = styled('div')<{open: boolean}>`
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(98, 98, 98, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  transition: all 300ms;
  ${props => props.open && css`
    opacity: 0.54;
    visibility: visible;
  `}
`

const SideWrapper = styled.div<{open: boolean, width?: string}>`
  box-sizing: border-box;
  box-shadow: ${props => props.open ? '0px 4px 15px rgba(211, 216, 224, 0.5)' : ''};
  border-radius: unset;
  width: ${({ width }) => width || '570px'};
  position:fixed;
  right: -570px;
  top: 0;
  background-color: var(--surface-overlay);
  bottom: 0;
  padding: 0;
  transition: right 200ms;
  z-index: 999;
  max-height: 100vh;
  overflow-y: auto;
  .layout-drawer-header-close {
    box-shadow: none !important;
    background: var(--surface-900);
  };
  ${props => props.open && css`
    right: 0;
  `}
`
const DrawerClose = styled(Button)`
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 999;
`
const DrawerBody = styled.div`
  position: relative;
  padding: 27px 30px 90px;
  min-height: calc(100% - 100px);
`

const Drawer = ({ children, withHeader, open, onClose, width }: DrawerProps) => {
  return (
    <>
      <Mask open={open} onClick={onClose} />
      <SideWrapper
        width={width}
        open={open}
      >
        {withHeader && open
          ? (
            <div className={'flex py-4 px-5 layout-drawer-header-close'}>
              <Button
                className={'p-button p-button-secondary p-component p-button-icon-only '}
                icon="pi pi-times"
                onClick={onClose}
              />
            </div>
          )
          : open && (
            <DrawerClose
              className="p-button-danger p-button-rounded p-button-text"
              icon="pi pi-times"
              onClick={onClose}
            />
          )}
        <DrawerBody>
          {open && children}
        </DrawerBody>
      </SideWrapper>
    </>
  )
}

export default Drawer
