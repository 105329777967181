import * as STATES from 'constants/states'
import React from 'react'
import { Divider } from 'primereact/divider'
import { useSelector } from 'react-redux'
import { path, prop } from 'ramda'
import ImageUploadField from 'components/Form/Upload/ImageUploadField'
import ProfileParagraf from 'components/Text/ProfileParagraf'
import TitleBlockProfile from 'components/UI/TitleBlockProfile'
import TitleSection from 'components/Text/TitleSection'
import { getFullName } from 'utils/get'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import FieldWrapper from '../../../../components/Form/FieldWrapper'
import { InputTextUI } from '../../../../components/Form'
import { normalizePhone } from '../../../../utils/normalize'
import DateUI from '../../../../components/Form/DateUI'
import * as API from '../../../../constants/api'
import { UniversalSearchField } from '../../../../components/Form/Select'
import { DrawerBottomActions } from '../../../../components/Drawer'
import { ButtonUI } from '../../../../components/Button'
import * as stateNames from '../../../../constants/states'

const Card = styled.div`
  
`
const Photo = styled.div`
  margin-right: 178px;
`

const SettingPasswordGrid = ({ updateData }) => {
  const profile = useSelector(prop(stateNames.PROFILE))
  const data = path(['data', 'data'], profile)
  const userId = path(['_id'], data)
  return (
    <Card className={'p-5 w-full card'}>
      <h3>Смена пароля</h3>
      <Divider />
      <Form
        onSubmit={(values) => updateData.onSubmit({ userId, ...values })}
        render={({ handleSubmit, values }) => {
          const passwordValue = values?.password
          return (
            <form onSubmit={handleSubmit}>
              <>
                <div className="p-fluid">
                  <FieldWrapper>
                    <Field
                      required
                      component={InputTextUI}
                      name={'password'}
                      label={'Новый пароль'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      validate={passwordConfirm => {
                        const passwordsMatching = passwordValue === passwordConfirm
                        return passwordsMatching ? undefined : 'Пароли не совпадают'
                      }}
                      component={InputTextUI}
                      name={'confirmPassword'}
                      label={'Подтверждение пароля'}
                    />
                  </FieldWrapper>
                  <div className={'w-21rem ml-auto'}>
                    <ButtonUI
                      type={'submit'}
                      loading={updateData.loading}
                      severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                    </ButtonUI>
                  </div>
                </div>
              </>
            </form>
          )
        }}
      />
    </Card>
  )
}

export default SettingPasswordGrid
