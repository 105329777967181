import React from 'react'
import { propOr } from 'ramda'
import { Field } from 'react-final-form'
import { Button } from 'primereact/button'
import FieldWrapper from '../FieldWrapper'
import { normalizePhone } from '../../../utils/normalize'
import { InputTextUI } from '../index'

const ClientListField = props => {
  const { fields, isUpdate, onCreate } = props
  const onAdd = () => isUpdate ? onCreate() : fields.push({})
  const onRemove = index => fields.remove(index)

  return (
    <div>
      <FieldWrapper>
        <div className={'flex gap-2'}>
          <Field
            autoComplete="off"
            name={'phoneNumber'}
            required
            parse={normalizePhone}
            defaultValue={'+998'}
            component={InputTextUI}
          />
          <Button icon="pi pi-plus" type={'button'} onClick={onAdd} />
        </div>
      </FieldWrapper>
      {fields.map((name, index) => {
        return (
          <FieldWrapper key={index}>
            <div className={'flex gap-2'}>
              <Field
                autoComplete="off"
                name={`${name}.phoneNumber`}
                required
                parse={normalizePhone}
                defaultValue={'+998'}
                component={InputTextUI}
              />
              <Button icon="pi pi-trash" type={'button'}
                severity={'danger'}
                onClick={() => onRemove(index)}/>
            </div>
          </FieldWrapper>
        )
      })}
    </div>
  )
}

export default ClientListField
