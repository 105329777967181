import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import SettingClinicsCreateDrawer from './SettingClinicsCreateDrawer'
import SettingClinicsTable from './SettingClinicsTable'

type Props = {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  deleteData: TUseDelete
  updateData: TUseUpdateModal<any>
}
const SettingClinicsGrid = ({
  createDrawer, listData, updateData,
  deleteData
}: Props) => {
  return (
    <>
      <SettingClinicsTable
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
        listData={listData}
      />
      <SettingClinicsCreateDrawer
        {...createDrawer}
      />
      <SettingClinicsCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SettingClinicsGrid
