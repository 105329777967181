import * as stateNames from 'constants/states'
import React from 'react'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import moment from 'moment/moment'
import { find, prop, propEq } from 'ramda'
import { useCreateModal, useDelete, useFetchList } from '../../hooks'
import useUpdateModal from '../../hooks/useUpdateModal'
import { GENDERS } from '../../constants/constants'
import { normalizePhone } from '../../utils/normalize'
import PatientsGrid from './components/PatientsGrid'
import { patientsAction, patientsDeleteAction, patientsListAction, patientsUpdateAction } from './patientsActions'
import { patientsCreateSerializer } from './patientsSerializer'

const Permission = (props) => (
  <PermissionWrapper
    permissions={['READ']}
    {...props}
  />
)
const PatientsContainer = () => {
  const listData = useFetchList({
    action: patientsListAction,
    stateName: stateNames.PATIENTS_LIST
  })

  const createDrawer = useCreateModal({
    action: patientsAction,
    serializer: patientsCreateSerializer,
    successAction: patientsListAction,
    stateName: stateNames.PATIENTS_CREATE,
  })

  const deleteData = useDelete({
    stateName: stateNames.PATIENTS_DELETE,
    action: patientsDeleteAction,
    successAction: patientsListAction
  })

  const updateData = useUpdateModal({
    listData,
    action: patientsUpdateAction,
    stateName: stateNames.PATIENTS_CREATE,
    serializer: patientsCreateSerializer,
    successAction: patientsListAction,
    initialValues: (data) => {
      return {
        ...data,
        phoneNumbers: (prop('phoneNumbers', data) || []).map(({ phoneNumber }) => normalizePhone(phoneNumber)),
        phoneNumber: normalizePhone(prop('phoneNumber', data)),
        birthdate: data?.birthdate ? moment(data.birthdate).toDate() : null
      }
    }
  })

  return (
    <>
      <Permission>
        <PatientsGrid
          createDrawer={createDrawer}
          listData={listData}
          updateData={updateData}
          deleteData={deleteData}
        />
      </Permission>
    </>
  )
}

export default PatientsContainer
