import React from 'react'
import AppTopBar from 'components/AppTopBar'
import SearchUI from 'components/SearchUI/SearchUI'
import Button from 'components/Button/Button'
import { TUseCreateModal, TUseDelete, TUseUpdate, TUseUpdateModal } from '../../../types'
import PatientsCreateDrawer from './PatientsCreateDrawer'
import PatientsTable from './PatientsTable'

type Props = {
    createDrawer: TUseCreateModal<any>
    listData: any
    deleteData: TUseDelete
    updateData: TUseUpdateModal<any>
}
const PatientsGrid = ({ createDrawer, listData, deleteData, updateData }: Props) => {
  return (
    <div>
      <AppTopBar
        title={'Пациенты'}
        leftActions={(
          <SearchUI/>
        )}
        rightActions={(
          <Button
            onClick={createDrawer.onOpen}
            severity="success"
          >
            + Новый пациент
          </Button>
        )}
      />
      <PatientsTable listData={listData} deleteData={deleteData} updateData={updateData}/>
      <PatientsCreateDrawer
        {...createDrawer}
      />
      <PatientsCreateDrawer
        {...updateData}
      />
    </div>
  )
}

export default PatientsGrid
