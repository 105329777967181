import React from 'react'
import { Avatar } from 'primereact/avatar'
import { Badge } from 'primereact/badge'
import Button from '../../../components/Button/Button'

export const Notification = ({

}: any) => {
  return (
    <div className={'flex justify-content-between gap-3'}>
      <Avatar icon="pi pi-user" size="large" shape="circle"/>
      <div className={'flex-1'}>
        <div className={'flex justify-content-between'}>
          <p>
            <span className={'font-semibold'}>Murod Abdujabborov</span>
            &nbsp;на&nbsp;
            <span className={'font-semibold '}>12:02 - 12:59</span>
          </p>
        </div>
        <p className={'text-400 mb-2'}>20 июл 2023</p>
        <div className={'flex gap-3'}>
          <Button label="Принять" rounded size={'small'} />
          <Button label="Отклонить" rounded severity="secondary" outlined size={'small'}/>
        </div>
      </div>
      <Badge value="" severity="danger"/>
    </div>
  )
}
