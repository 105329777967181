import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import { path, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import * as stateNames from '../../../constants/states'
import {
  passwordCreateSerializers,
} from '../SettingPerson/SettingPersonSerializers/SettingPersonSerializers'
import useUpdate from '../../../hooks/useUpdate'
import { usePromiseDispatch } from '../../../etc/reducers'
import SettingPasswordGrid from './components/SettingPasswordGrid'
import { passwordUpdateAction } from './settingProfileActions/settingPasswordActions'

const SettingPassword = props => {
  const updateData = useUpdate<any>({
    action: passwordUpdateAction,
    stateName: stateNames.PERSON_CREATE,
    serializer: passwordCreateSerializers,
  })
  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingPasswordGrid updateData={updateData}/>
    </PermissionWrapper>
  )
}

export default SettingPassword
