import React from 'react'
import { Skeleton } from 'primereact/skeleton'

const PieSkeleton = () => {
  return (
    <div className={'justify-content-center flex'}>
      <div className={'h-18rem w-18rem'}>
        <Skeleton shape="circle" size="100%"></Skeleton>
      </div>
    </div>
  )
}

export default PieSkeleton
