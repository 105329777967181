import { amiriFont } from 'constants/fontPdf'
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Container from 'components/Container'
import styled from 'styled-components'
import 'components/Table/table.scss'
import {
  pipe, map, path, sum, filter, groupBy, prop,
  reduce,
  flatten,
  curry,
  values,
  mergeLeft, isEmpty, omit, find, propEq, propOr, head, pluck
} from 'ramda'
import StatusBlock from 'components/UI/StatusBlock'
import { Button } from 'primereact/button'
import JsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { InputText } from 'primereact/inputtext'
import { numberFormat } from 'utils/numberFormat'
import { Tag } from 'primereact/tag'
import { TTeeth, TTeethTable, useServices } from '../ServicesProvider'
import { statusColor, TTeethStatuses } from '../../../../types/enums'
import { STATUS_TEETH } from '../../../../constants/constants'
import ButtonPrint from '../../../../components/Button/ButtonPrint'
import checkRole from '../../../../utils/checkRole'
import ServiceInvoice from './ServiceInvoice'

const StyledCont = styled(Container)`
  padding: 0px calc(35px - 3rem); 
`

const sumBy = prop => vals => reduce(
  (acc, item: Omit<TTeethTable, 'amount' | 'uniq'>) => {
    return mergeLeft({ amount: vals.length, uniq: `${item._id}-${item.teeth.id}-${item.status}` }, item)
  },
  {},
  vals
)

const groupSumBy = curry((groupOn, sumOn, vals) => {
  // @ts-ignore
  return values(map(sumBy(sumOn))(groupBy(prop(groupOn), vals)))
})

const ServiceTable = ({ itemData }) => {
  const {
    teeth, handleEditPriceTeeth, setOpenInvoiceBar, selectedTeeth,
    setSelectedTeeth
  } = useServices()
  const [selectAll, setSelectAll] = useState(null)
  const hasSelectedTeeth = isEmpty(selectedTeeth || [])
  useEffect(() => {
    if (!hasSelectedTeeth) {
      setOpenInvoiceBar(true)
    } else {
      setOpenInvoiceBar(false)
    }
  }, [hasSelectedTeeth])

  const acceptedTeeth = pipe(
    filter((item: TTeeth) => item.status === TTeethStatuses.SAVED || item.status === TTeethStatuses.PAID || item.status === TTeethStatuses.ACTIVE),
  )(teeth) as TTeeth[]
  const groupedTeeth = pipe(
    // @ts-ignore
    groupBy((item: TTeeth) => {
      return `${prop('id', item)}-${prop('status', item)}`
    }),
    // @ts-ignore
    values,
    map(reduce((acc, item: TTeeth) => {
      acc = [...item.services, ...acc].map((tethOb) => {
        return {
          ...omit(['status'], tethOb),
          status: tethOb?.status || item.status,
          teeth: {
            field: item.field,
            id: item.id,
            type: item.type,
            name: item.name
          }
        }
      })
      return acc
    }, [])),
    map(vals => groupSumBy('_id', 'services', vals)),
    flatten,
    map((i: TTeethTable) => mergeLeft({ customPrice: i.cost }, i))
  )(acceptedTeeth) as TTeethTable[]
  const newFormat = pipe(
    // @ts-ignore
    groupBy((item: TTeeth) => {
      return `${prop('id', item)}-${prop('status', item)}`
    }),
    // @ts-ignore
    values,
    map(reduce((acc, item: TTeeth) => {
      acc = [...item.services, ...acc].map((tethOb) => {
        return {
          ...omit(['status'], tethOb),
          status: tethOb?.status || item.status,
          teeth: {
            field: item.field,
            id: item.id,
            type: item.type,
            name: item.name
          }
        }
      })
      return acc
    }, [])),
    map(vals => groupSumBy('_id', 'services', vals)),
    flatten,
    map((i: TTeethTable) => mergeLeft({ customPrice: i.cost }, i)),
    // @ts-ignore
    groupBy(service => `${service.status}-${service.treatmentId || service._id}`),
    values,
    map((group: any) => ({
      ...group[0],
      // @ts-ignore
      status: head(group).status,
      // @ts-ignore
      treatmentId: head(group).treatmentId,
      // @ts-ignore
      clinic: head(group).clinic,
      // @ts-ignore
      type: head(group).type,
      // @ts-ignore
      teeth: head(group).teeth,
      // @ts-ignore
      uniq: head(group).uniq,
      // @ts-ignore
      customPrice: head(group).customPrice,
      // @ts-ignore
      cost: head(group).cost,
      // @ts-ignore
      amount: sum(pluck('amount', group)),
      // @ts-ignore
      teethMerge: group,
    }))
  )(acceptedTeeth) as any[]

  // @ts-ignore
  const totalPrice = pipe(
    map(path(['services', '0', 'cost'])),
    sum
  )(acceptedTeeth)

  const representativeBodyTemplate = (rowData: TTeethTable) => {
    const service = path(['name'], rowData)
    return (
      <React.Fragment>
        <div className={'flex'}>
          <StatusBlock>#{rowData.teeth.id}</StatusBlock>
           [D0D] {service}
        </div>
      </React.Fragment>
    )
  }

  const statusBody = (rowData: TTeethTable) => {
    const status = path(['status'], rowData)

    const statusText = pipe(
      find(propEq(status, '_id')),
      propOr('Сохранен', 'name')
    )(STATUS_TEETH) as string
    return (
      <div className={'flex'}>
        <Tag severity={statusColor(status)} value={statusText} style={{ whiteSpace: 'nowrap' }}/>
      </div>
    )
  }
  const serviceAmountTemplate = (rowData: TTeethTable) => {
    return (
      <span>
        x {rowData.amount}
      </span>
    )
  }

  const priceTemplate = (rowData: TTeethTable) => {
    const price = path(['cost'], rowData) * rowData.amount
    return (
      <span>
        {numberFormat(price, 'Сум')}
      </span>
    )
  }

  const onSelectAllChange = (event) => {
    const selectAll = event.checked
    if (selectAll) {
      setSelectedTeeth(groupedTeeth.filter(item => item.status !== TTeethStatuses.PAID))
      setSelectAll(true)
    } else {
      setSelectedTeeth([])
      setSelectAll(false)
    }
  }

  const onRowEditComplete1 = (e) => {
    let { newData } = e
    handleEditPriceTeeth(newData as TTeethTable)
  }

  const exportPdf = () => {
    const doc = new JsPDF()
    doc.addFileToVFS('Amiri-Regular.ttf', amiriFont)
    doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal')
    doc.setFont('Amiri')
    autoTable(doc, {
      styles: {
        font: 'Amiri'
      },
      head: [['Какое то имя', 'Количество', 'Цена']],
      body: groupedTeeth.map(i => {
        const price = path(['cost'], i) * i.amount
        return [
          `#${i._id} [D0D] ${i.name}`, `x ${i.amount}`, numberFormat(price, 'сум')
        ]
      })
    })
    doc.save('table.pdf')
  }
  const isSuperadmin = checkRole(['superadmin'])

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
      {isSuperadmin && <ButtonPrint>
        <ServiceInvoice data={newFormat} itemData={itemData} totalPrice={totalPrice}/>
      </ButtonPrint>}
    </div>
  )

  const priceEditor = (options) => {
    return <InputText
      type="text"
      value={options.value}
      onChange={(e) => {
        return options.editorCallback(e.target.value)
      }}
    />
  }

  const isSelectable = (data) => data?.status !== TTeethStatuses.PAID
  const isRowSelectable = (event) => (event.data ? isSelectable(event.data) : true)
  const rowClassName = (data) => (isSelectable(data) ? '' : 'p-disabled')
  return (
    <StyledCont>
      <div className="card">
        <DataTable
          header={header}
          value={newFormat}
          responsiveLayout="scroll"
          dataKey="uniq"
          editMode="row"
          rowClassName={rowClassName}
          rows={10}
          emptyMessage={'Процедуры не выбраны'}
          selectionMode={'checkbox'}
          loading={false}
          rowHover={true}
          // @ts-ignore
          selection={selectedTeeth}
          selectAll={selectAll}
          onSelectionChange={e => {
            setSelectedTeeth(e.value)
          }}
          onRowEditComplete={onRowEditComplete1}
          onSelectAllChange={onSelectAllChange}
          isDataSelectable={isRowSelectable}
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}/>
          <Column body={statusBody} headerStyle={{ width: '3em' }}/>
          <Column
            style={{ width: '40%' }}
            body={representativeBodyTemplate}
            header="Какое то имя"
            editor={({ rowData }) => representativeBodyTemplate(rowData)}
          />
          <Column body={serviceAmountTemplate} header="" editor={({ rowData }) => serviceAmountTemplate(rowData)} style={{ width: '20%' }} />
          <Column field={'customPrice'} body={priceTemplate} editor={priceEditor} header={numberFormat(totalPrice, 'Сум')} style={{ width: '30%' }} />
          <Column rowEditor headerStyle={{ minWidth: '8rem' }} bodyStyle={{ textAlign: 'right' }} style={{ width: '5%' }} />
        </DataTable>
      </div>
    </StyledCont>
  )
}

export default ServiceTable
