import useDebounce from 'hooks/useDebounce'
import React, { useEffect, useState } from 'react'
import { getParams } from 'utils/get'
import { addParamsRoute } from 'utils/route'
import InputTextUI from 'components/Form/InputTextUI'
import { useHistory } from 'react-router'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'

interface Props {
  searchKey?: string
}

const SearchUI = ({ searchKey, ...props }: Props) => {
  const history = useHistory()
  const searchParams = getParams(history, [searchKey])
  const search = searchParams?.[searchKey] || ''
  const [searchTerm, setSearchTerm] = useState(search)
  const debounceSearchTerm = useDebounce(searchTerm, 1000)
  useEffect(() => {
    addParamsRoute({ [searchKey]: debounceSearchTerm }, history)
  }, [debounceSearchTerm])

  const randomNumber = Math.random().toString()
  return (
    <div>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputTextUI
          {...props}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
          id={randomNumber}
        />
      </IconField>
    </div>
  )
}

SearchUI.defaultProps = {
  searchKey: 'search',
  placeholder: 'Поиск'
}

export default SearchUI
