import * as stateNames from 'constants/states'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import { useCreateModal, useDelete, useFetchList } from 'hooks'
import useUpdateModal from 'hooks/useUpdateModal'
import { find, map, pipe, propEq, propOr } from 'ramda'
import { WEEKS } from '../../../constants/constants'
import {
  personCreateAction,
  personDeleteAction,
  personListAction,
  personUpdateAction
} from './settingPersonActions/SettingPersonActions'
import SettingPersonGrid from './components/SettingPersonGrid'
import { settingPersonCreateSerializers } from './SettingPersonSerializers/SettingPersonSerializers'

const SettingPersonContainer = props => {
  const listData = useFetchList({
    action: personListAction,
    stateName: stateNames.PERSON_LIST
  })
  const createDrawer = useCreateModal({
    action: personCreateAction,
    serializer: settingPersonCreateSerializers,
    stateName: stateNames.PERSON_CREATE,
    successAction: personListAction
  })

  const updateData = useUpdateModal({
    listData,
    action: personUpdateAction,
    stateName: stateNames.PERSON_CREATE,
    serializer: settingPersonCreateSerializers,
    successAction: personListAction,
    initialValues: (data) => {
      return {
        ...data,
        clinicIds: propOr([], 'clinics', data),
        workingDays: pipe(
          propOr([], 'workingDays'),
          // @ts-ignore
          map((item) => find(propEq(item, '_id'), WEEKS))
        )(data)
      }
    }
  })

  const deleteData = useDelete({
    stateName: stateNames.PERSON_DELETE,
    action: personDeleteAction,
    successAction: personListAction
  })

  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingPersonGrid
        listData={listData}
        createDrawer={createDrawer}
        updateData={updateData}
        deleteData={deleteData}
      />
    </PermissionWrapper>
  )
}

export default SettingPersonContainer
