import React from 'react'
import { path } from 'ramda'
import PieChart from '../../../components/charts/PieChart'
import PieSkeleton from '../../../components/Loader/Skeleton/PieSkeleton'
import sumBy from '../../../utils/sumBy'

const ReportStatusChart = ({ statsData }) => {
  const arrayList = path(['data', 'data'], statsData) || []
  const loading = statsData.loading
  const notComplited = sumBy('notComplited', arrayList)
  const done = sumBy('done', arrayList)
  const paid = sumBy('paid', arrayList)
  const active = sumBy('active', arrayList)

  const total = notComplited + done + paid + active

  const notComplitedP = notComplited / total * 100
  const doneP = done / total * 100
  const paidP = paid / total * 100
  const activeP = active / total * 100

  const series = [
    {
      name: 'Статус',
      colorByPoint: true,
      data: [
        {
          name: 'Активный',
          sliced: true,
          selected: true,
          z: active,
          y: activeP
        },
        {
          name: 'Не выполнено',
          z: notComplited,
          y: notComplitedP
        },
        {
          name: 'Выполнено',
          z: done,
          y: doneP
        },
        {
          name: 'Оплачен',
          z: paid,
          y: paidP
        }
      ]
    }
  ]
  return loading ? (
    <PieSkeleton/>
  ) : (
    <PieChart listSeries={series}/>
  )
}

export default ReportStatusChart
