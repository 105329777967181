import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import { propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { useCreateModal, useDelete, useFetchList } from '../../hooks'
import * as stateNames from '../../constants/states'
import { getListParamsFromProps, getSerializedData } from '../../utils/get'
import useUpdateModal from '../../hooks/useUpdateModal'
import { TTeethService } from '../../types'
import SettingServicesGrid from './components/SettingServicesGrid'
import {
  servicesCreateAction, servicesDeleteAction,
  servicesListAction,
  servicesUpdateAction
} from './settingServicesActions/settingServicesActions'
import { fields } from './components/SettingServicesCreateDrawer'

const SettingServicesContainer = props => {
  const listData = useFetchList({
    action: servicesListAction,
    stateName: stateNames.SERVICES_LIST
  })
  const createDrawer = useCreateModal({
    serializer: (values) => ({
      ...getSerializedData(fields, values),
      imageUrl: values?.imageUrl?.baseUrl ? values?.imageUrl?.fileName : (values?.imageUrl ? values?.imageUrl.replace(/.*\//, '') : '')
    }),
    action: servicesCreateAction,
    successAction: servicesListAction,
    stateName: stateNames.SERVICES_CREATE,
  })
  const deleteData = useDelete({
    stateName: stateNames.SERVICES_DELETE,
    action: servicesDeleteAction,
    successAction: servicesListAction
  })
  const updateData = useUpdateModal({
    listData,
    action: servicesUpdateAction,
    stateName: stateNames.SERVICES_CREATE,
    serializer: (values) => ({
      ...getSerializedData(fields, values),
      imageUrl: values?.imageUrl?.baseUrl ? values?.imageUrl?.fileName : (values?.imageUrl ? values?.imageUrl.replace(/.*\//, '') : '')
    }),
    successAction: servicesListAction,
    initialValues: (data: TTeethService) => {
      const imageUrl = propOr('', 'imageUrl', data) as string
      return {
        ...data,
        imageUrl
      }
    }
  })

  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingServicesGrid
        listData={listData}
        createDrawer={createDrawer}
        deleteData={deleteData}
        updateData={updateData}
      />
    </PermissionWrapper>
  )
}

export default SettingServicesContainer
