import { PATIENTS_DETAIL_URL } from 'constants/routes'
import React from 'react'
import { Column } from 'primereact/column'
import { find, flatten, ifElse, isEmpty, map, path, pathOr, pick, pipe, propEq, propOr } from 'ramda'
import { TableUI } from 'components/Table'
import { getFullName } from 'utils/get'
import dateFormat from 'utils/dateFormat'
import { useRedirect } from 'hooks'
import { TPagination } from 'types'
import { Tag } from 'primereact/tag'
import { numberFormat } from '../../../utils/numberFormat'
import { scrollHeightBase } from '../../../components/Table/utils'
import { STATUS_TEETH } from '../../../constants/constants'
import { statusColor } from '../../../types/enums'

const NameBody = (item) => {
  return (
    <span>
      {getFullName(item)}
    </span>
  )
}
const statuses = [
  { id: 'notComplited', name: 'Не выполнено' },
  { id: 'paid', name: 'Оплачен' },
  { id: 'done', name: 'Выполнено' },
  { id: 'active', name: 'Активный' }
]
const PatientsTable = ({ listData, statsData }) => {
  const statsDataArr = pathOr([], ['data', 'data'], statsData)
  const value = pathOr([], ['data', 'data'], listData).map((item) => {
    return {
      ...item,
      // @ts-ignore
      statusData: pipe(
        find(propEq(item?.patientId, 'patientId')),
      )(statsDataArr)
    }
  })
  const statusBody = (rowData) => {
    return (
      <div className={'flex '}>
        {statuses.map((i, key) => {
          return (
            <Tag severity={statusColor(i.id)} value={`${i.name} - ${path(['statusData', i.id], rowData)}`} style={{ whiteSpace: 'nowrap' }} key={key} className={'m-2'}/>
          )
        })}
      </div>
    )
  }
  const pagination = path<TPagination>(['data', 'pagination'], listData)

  const gotDetail = useRedirect(PATIENTS_DETAIL_URL)

  return (
    <TableUI
      selectionMode={'single'}
      rowHover={true}
      className={'card'}
      scrollHeight={scrollHeightBase}
      pagination={pagination}
      onRowClick={({ data }) => gotDetail(data.patientId)}
      loading={statsData.loading || listData.loading}
      value={value}
    >
      <Column field="doctor" header="Доктор" body={(item) => getFullName(item.doctor)} />
      <Column field="email" header="ФИО" body={NameBody} />
      <Column field="phoneNumber" header="ТЕЛЕФОН" />
      <Column field="paid" header="Оплачено" body={(item) => numberFormat(item.paid)} />
      <Column field="debt" header="Долг" body={(item) => numberFormat(item.debt)} />
      <Column field="status" header="Статусы" body={statusBody} />
    </TableUI>
  )
}

export default PatientsTable
