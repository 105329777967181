import { join } from 'ramda'
import { timeToMinutes } from './date'

export const required = value => value ? undefined : 'Обязательное поле'

export const validateTime = (value, prohibitedTimeRanges) => {
  if (!value) {
    return 'Время обязательно для заполнения'
  }

  const [hours, minutes] = value.split(':').map(Number)
  const timeInMinutes = hours * 60 + minutes

  // const validRanges = [
  //   { start: 9 * 60, end: 12 * 60 }, // 9:00 AM to 12:00 PM
  //   { start: 13 * 60, end: 17 * 60 } // 1:00 PM to 5:00 PM
  // ]

  const isProhibited = prohibitedTimeRanges.some(range => timeInMinutes >= timeToMinutes(range.start) && timeInMinutes <= timeToMinutes(range.end))

  if (isProhibited) {
    return `Время находится в пределах запрещенных диапазонов (${join(', ', prohibitedTimeRanges.map(i => `с ${i.start} до ${i.end}`))})`
  }

  return undefined
}
