import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Content = styled('div')`
  font-size: 20px;
  line-height: normal;
  min-width: 220px;
  width: 100%;
`

const Label = styled('div')`
  color: #000000;
  font-weight: 600;
  margin-bottom: ${props => props.margin}px;
`

const Value = styled('span')`
  display:block;
`

const LabeledValue = props => {
  const { label, children, labelMargin, ...rest } = props
  return (
    <Content {...rest}>
      <Label margin={labelMargin}>{label}</Label>
      <Value>{children}</Value>
    </Content>
  )
}

LabeledValue.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  labelMargin: PropTypes.number
}

LabeledValue.defaultProps = {
  labelMargin: 20
}

export default LabeledValue
