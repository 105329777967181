import { PAYMENT_TYPES, STATUS_TEETH } from 'constants/constants'
import React from 'react'
import { ButtonLink, ButtonUI } from 'components/Button'
import ActionsBar from 'components/UI/ActionsBar'
import { Field } from 'react-final-form'
import { equals, filter, find, map, or, path, pipe, propEq, where, whereEq } from 'ramda'
import { UniversalStaticSelectField } from 'components/Form/Select'
import { TTeeth, useServices } from '../ServicesProvider'
import { TTeethStatuses } from '../../../../types/enums'
import checkRole from '../../../../utils/checkRole'

const CloseBtn = (props) => {
  return (
    <ButtonLink
      className={['text-50']}
      prefix={<i className="pi pi-arrow-left" />}
      text={'Вы добавили изменения в зубную карту'}
      {...props}
    />
  )
}
const isPaidOrSaved = or(propEq(TTeethStatuses.SAVED, 'status'), propEq(TTeethStatuses.PAID, 'status'))
const ServiceActionBar = ({ updateData, values }) => {
  const { openActionBar, teeth, setTeeth, setSelectedTeeth, selectedTeeth, setOpenActionBar, handleSaveTeeth, openInvoiceBar, setOpenInvoiceBar, handleDeleteSelectedTeeth } = useServices()
  const handleSubmitTeeth = () => {
    const acceptedTeeth = pipe(
      map((item: TTeeth) => {
        return {
          ...item,
          services: item.services.map(service => {
            const isEditService = Boolean(find(where({
              teeth: whereEq({
                id: item.id,
                name: item.name
              }),
              _id: equals(service._id)
            }), selectedTeeth))
            return {
              ...service,
              status: isEditService ? path(['status', '_id'], values) : (service?.status || item.status),
              treatmentId: service._id
            }
          }),
          status: item.status
        }
      })
    )(teeth) as TTeeth[]
    setTeeth(acceptedTeeth)
    setSelectedTeeth([])
    updateData.onSubmit({ teeth: acceptedTeeth })
    setOpenInvoiceBar(false)
  }
  console.warn(values)
  if (openActionBar) {
    return (
      <ActionsBar>
        <CloseBtn
          onClick={() => setOpenActionBar(false)}
        />
        <ButtonUI classNames={['']} severity="success" onClick={handleSaveTeeth}>
            Сохранить изменения
        </ButtonUI>
      </ActionsBar>
    )
  }
  const isSuperadmin = checkRole(['superadmin'])

  if (openInvoiceBar) {
    return (
      <ActionsBar>
        <CloseBtn
          onClick={() => setOpenInvoiceBar(false)}
        />
        <div className={'flex'}>
          <ButtonUI classNames={['mr-3']} severity="success" onClick={handleSubmitTeeth}>
            Создать инвойс
          </ButtonUI>
          {isSuperadmin && <div className={'w-18rem mr-3'}>
            <Field
              placeholder={'Тип оплаты'}
              name="paymentType"
              list={PAYMENT_TYPES}
              isClearable={true}
              typeSelect={'simple'}
              component={UniversalStaticSelectField}
            />
          </div>}
          <div className={'w-18rem'}>
            <Field
              placeholder={'Статус'}
              name="status"
              typeSelect={'simple'}
              list={STATUS_TEETH}
              isClearable={true}
              component={UniversalStaticSelectField}
            />
          </div>
          <ButtonUI label="Удалить" className="p-button-danger ml-3" onClick={handleDeleteSelectedTeeth}/>
        </div>
      </ActionsBar>
    )
  }
  return null
}

export default ServiceActionBar
