import { useHistory } from 'react-router-dom'
import { replaceParamsRoute } from 'utils/route'
import { getParamFromHistory } from 'utils/get'
import { TUseCustomModal, TUseModalParams } from 'types/hooks'
import useQuery from 'hooks/useQuery'

const mapStrToBoolean = str => Boolean(str) && str !== 'false'

export default (params: TUseModalParams): TUseCustomModal => {
  const {
    key = 'modal',
    autoClose = false
  } = params
  const history = useHistory()
  const query = useQuery(key)
  const open: boolean = mapStrToBoolean(getParamFromHistory(key, history))
  const onClose = () => {
    replaceParamsRoute({ [key]: false }, history)
  }

  const onOpen = (id) => {
    replaceParamsRoute({ [key]: id }, history)
  }

  const onSubmit = (event) => autoClose
  // @ts-ignore
    ? params.onSubmit(event).then(() => onClose())
  // @ts-ignore
    : params.onSubmit(event, onClose)

  // @ts-ignore
  return { onClose, onOpen, onSubmit, open, query }
}
