import {
  pipe,
  split,
  map,
  fromPairs,
  toPairs,
  head,
  join,
  mergeAll,
  test,
  isNil,
  prop,
  defaultTo,
  filter,
  without,
  sort,
  uniq,
  propEq,
  concat,
  is,
  find,
  curry,
  subtract,
  isEmpty,
  not, replace, union, mergeRight
} from 'ramda'
import { replaceParamsRoute } from './route'

const parseParams = url => {
  const [, search] = split('?', url)
  const searchToObject = pipe(
    split('&'),
    map(split('=')),
    fromPairs
  )
  return search ? searchToObject(search) : {}
}

const getSearchParam = (paramName, search) => {
  return prop(paramName, parseParams(search))
}

export const paramsToSearch = pipe(
  toPairs,
  map(join('=')),
  join('&')
)

const getPathnameFromUrl = pipe(
  split('?'),
  head
)

const appendParamsToUrl = curry((appendParams, url) => {
  const pathname = getPathnameFromUrl(url)
  const params = parseParams(url)
  const newParams = pipe(
    mergeRight(params),
    filter(
      pipe(
        isEmpty,
        not
      )
    )
  )(appendParams)
  return pathname + '?' + paramsToSearch(newParams)
})

const removeItemFromSelect = (search, key, value) => {
  const params = parseParams(search)
  const values = is(Array, value) ? map(String, value) : [String(value)]

  return pipe(
    prop(key),
    defaultTo(''),
    split(','),
    filter(item => item),
    without(values),
    uniq,
    sort(subtract),
    join(',')
  )(params)
}

const addItemToSelect = (url, key, value) => {
  const params = parseParams(url)
  const values = is(Array, value) ? map(String, value) : [String(value)]

  return pipe(
    prop(key),
    defaultTo(''),
    split(','),
    filter(item => item),
    concat(values),
    uniq,
    sort(subtract),
    join(',')
  )(params)
}

const getSortingType = (history, columnSortingName) => {
  const search = history.location.search
  const params = parseParams(search)
  const currentOrdering = prop('ordering', params)
  const columnType = currentOrdering
    ? pipe(
      split(','),
      filter(pipe(
        isEmpty, not
      )),
      map((column) => {
        const isStart = test(/^-/, column)
        const columnTrimStart = isStart ? replace(/-/, '', column) : column
        return { column: columnTrimStart, desc: test(/^-/, column) }
      }),
      find(propEq(columnSortingName, 'column')),
      prop('desc')
    )(currentOrdering)
    : undefined
  return isNil(columnType) ? null : columnType
}

const sortingURL = (history, columnSortingName) => {
  const search = history.location.search
  const params = parseParams(search)
  const currentOrdering = prop('ordering', params)
  const columnList = currentOrdering
    ? pipe(
      split(','),
      filter(pipe(
        isEmpty, not
      )),
      map((column) => {
        const isStart = test(/^-/, column)
        const columnTrimStart = isStart ? replace(/-/, '', column) : column
        return { column: columnTrimStart, desc: test(/^-/, column) }
      })
    )(currentOrdering)
    : []
  const columnSortingType = isEmpty(columnList)
    ? undefined
    : pipe(
      find(propEq(columnSortingName, 'column')),
      prop('desc')
    )(columnList)
  const columnSortingDesc = columnSortingType === undefined ? false : (columnSortingType ? undefined : true)
  const ordering = pipe(
    filter((item) => item.column !== columnSortingName),
    union([{ column: columnSortingName, desc: columnSortingDesc }]),
    filter(pipe(
      prop('desc'), isNil, not
    )),
    map((item) => {
      return prop('desc', item) ? '-' + prop('column', item) : prop('column', item)
    }),
    join(',')
  )(columnList)
  return replaceParamsRoute({ ordering }, history)
}

export {
  parseParams,
  getSearchParam,
  sortingURL,
  appendParamsToUrl,
  getSortingType,
  removeItemFromSelect,
  addItemToSelect
}
