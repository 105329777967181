import * as STATES from 'constants/states'
import { prop, propOr } from 'ramda'
import { useSelector } from 'react-redux'
import {
  profileAction,
} from 'containers/signIn/actions'
import React, { useEffect } from 'react'
import { getCookie } from 'utils/cookie'
import GlobalLoader from 'components/Loader/global'
import useFirebaseNotify from '../hooks/useFirebaseNotify'
import { usePromiseDispatch } from '../etc/reducers'

const AuthProvider = props => {
  const {
    children
  } = props
  // Dispatch
  const dispatch = usePromiseDispatch()

  // Data
  const signIn = useSelector(prop(STATES.SIGN_IN))
  const profile = useSelector(prop(STATES.PROFILE))
  const signInLoading = propOr(true, 'loading', signIn)
  const profileLoading = propOr(true, 'loading', profile)
  const { getNotifyToken } = useFirebaseNotify()

  const loading = signInLoading || profileLoading
  function subscribeNotification () {
    getNotifyToken()
      .then(firebaseToken => {
        if (firebaseToken) {
          return null
        }
      })
      .catch(err => console.log(err))
  }
  const tokenCookie = getCookie('token')

  const token = prop('token', signIn) || tokenCookie

  useEffect(() => {
    if (token) {
      dispatch(profileAction(token)).then((_) => {
        subscribeNotification()
      })
    }
  }, [token])

  return (
    <>
      {children}
      {loading && <GlobalLoader open />}
    </>
  )
}

export default AuthProvider
