import React from 'react'

interface Props {
  children: any
  className?: string[]
}
const TitleBlockProfile = ({ children, className = [] }: Props) => {
  return (
    <div className={['flex', 'justify-content-between', 'mt-5', 'px-3', ...className].join(' ')}>
      {children}
    </div>
  )
}

export default TitleBlockProfile
