import * as API from 'constants/api'
import { DATE_FORMAT_ISO_8601 } from 'constants/dateFormat'
import React, { forwardRef } from 'react'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DateUI from 'components/Form/DateUI'
import DrawerDialog from 'components/Drawer/DrawerDialog'
import { pick, prop, propEq, whereEq } from 'ramda'
import moment from 'moment'
import { InputTextUI } from 'components/Form'
import { UniversalSearchField } from 'components/Form/Select'
import { TAppointmentsItem, TOnSubmit, TOnSubmitCreate } from 'types'
import { DrawerBottomActions } from 'components/Drawer'
import { ButtonUI } from 'components/Button'
import { getFullName } from 'utils/get'
import { EventApi } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import { validateTime } from '../../../utils/formValidations'
import { createDate } from './CalendarEventsUtils'

export const fields = [
  'note',
  'date',
  'startTime',
  'endTime',
  'doctorId',
  'patientId',
  'clinicId',
]
interface Props {
  onSubmit: TOnSubmitCreate<{ value: {data: TAppointmentsItem} }> | TOnSubmit
  state: any
  open: boolean
  isUpdate?: boolean
  selectDateEvents?: EventApi[]
  loading: boolean
  onClose: () => void
  commit: (...args) => void
}
const CalendarCreateDrawer = forwardRef<InstanceType<typeof FullCalendar>, Props>(({ state, open, isUpdate, loading, onClose, commit, selectDateEvents, onSubmit }, calendarRef) => {
  const handleSubmitForm = (values) => {
    const dateValue = prop('date', values)
    const startTime = moment(prop('startTime', values), 'HH:mm')
    const endTime = moment(prop('endTime', values), 'HH:mm')
    // @ts-ignore
    const calendarApi = calendarRef?.current?.getApi()
    return onSubmit({
      ...values,
      date: moment(dateValue).format(DATE_FORMAT_ISO_8601),
      startTime: moment(startTime).format('HH:mm:ss'),
      endTime: moment(endTime).format('HH:mm:ss')
    }).then((res: {value: { data: TAppointmentsItem }}) => {
      const value = res?.value
      if (value?.data?._id) {
        if (isUpdate) {
          calendarApi.getEventById(value.data._id).remove()
          calendarApi.addEvent({
            id: value.data._id,
            title: prop('note', values),
            start: createDate(dateValue, startTime),
            end: createDate(dateValue, endTime),
            allDay: false,
            // @ts-ignore
            extendedProps: pick(['doctorId', 'clinicId', 'patientId'], value.data)
          })
        } else {
          calendarApi.addEvent({
            id: value.data._id,
            title: prop('note', values),
            start: createDate(dateValue, startTime),
            end: createDate(dateValue, endTime),
            allDay: false,
            // @ts-ignore
            extendedProps: pick(['doctorId', 'clinicId', 'patientId'], value.data)
          })
        }
      }
    })
  }
  const prohibitedTimeRanges = selectDateEvents.map(obj => {
    return {
      start: moment(obj.start).format('HH:mm'),
      end: moment(obj.end || obj.start).format('HH:mm')
    }
    // @ts-ignore
  }).filter((obj) => !propEq(state.initialValues?.startTime, 'start') || !propEq(state.initialValues?.endTime, 'end'))

  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={handleSubmitForm}
        initialValues={state.initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <>
                <div className="p-fluid">
                  <FieldWrapper>
                    <Field
                      required
                      component={InputTextUI}
                      name={'note'}
                      label={'Примечание'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      minDate={new Date()}
                      component={DateUI}
                      name={'date'}
                      label={'Дата'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      component={InputTextUI}
                      name={'startTime'}
                      type="time"
                      validate={(value) => validateTime(value, prohibitedTimeRanges)}
                      label={'Начало'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      component={InputTextUI}
                      name={'endTime'}
                      type="time"
                      validate={(value) => validateTime(value, prohibitedTimeRanges)}
                      label={'Конец'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      label="Доктор"
                      name="doctorId"
                      api={API.DOCTORS_LIST}
                      itemText={['email']}
                      getText={(obj) => getFullName(obj)}
                      component={UniversalSearchField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      label="Клиника"
                      name="clinicId"
                      api={API.CLINICS_LIST}
                      component={UniversalSearchField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      label="Пациент"
                      name="patientId"
                      api={API.PATIENTS_LIST}
                      itemText={['email']}
                      getText={getFullName}
                      component={UniversalSearchField}
                    />
                  </FieldWrapper>
                  <DrawerBottomActions>
                    <ButtonUI
                      type={'submit'}
                      loading={loading}
                      severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                    </ButtonUI>
                  </DrawerBottomActions>
                </div>
              </>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
})

export default CalendarCreateDrawer
