import * as STATES from 'constants/states'
import { PROFILE_AVATAR } from 'constants/states'
import React from 'react'
import { Divider } from 'primereact/divider'
import { useSelector } from 'react-redux'
import { path, prop } from 'ramda'
import ImageUploadField from 'components/Form/Upload/ImageUploadField'
import ProfileParagraf from 'components/Text/ProfileParagraf'
import TitleBlockProfile from 'components/UI/TitleBlockProfile'
import TitleSection from 'components/Text/TitleSection'
import { getFullName } from 'utils/get'
import styled from 'styled-components'

const Card = styled.div`
  
`
const Photo = styled.div`
  margin-right: 178px;
`

const SettingProfileGrid = props => {
  const profile = useSelector(prop(STATES.PROFILE))
  const data = path(['data', 'data'], profile)
  const phone = path(['phoneNumber'], data)
  const email = path(['email'], data)
  const userId = path(['_id'], data)
  const fullName = getFullName(data)
  return (
    <Card className={'p-5 w-full card'}>
      <h3>Мои данные</h3>
      <Divider />
      <div className={'flex align-items-center'}>
        <Photo className={'font-semibold text-3xl'}>
          Photo
        </Photo>
        <ImageUploadField url={`https://aqltish.uz/api/users/avatar/${userId}`} userId={userId}/>
      </div>
      <Divider />
      <ProfileParagraf>
        Вы можете загрузить файл максимум 5мб. Изоображение будет обрезано до
        256*256 пикселей.
      </ProfileParagraf>
      <TitleBlockProfile className={['mt-7']}>
        <TitleSection>
          Ф.И.О
        </TitleSection>
        <ProfileParagraf>{fullName}</ProfileParagraf>
      </TitleBlockProfile>
      <Divider />
      <TitleBlockProfile>
        <TitleSection>
          Телефон
        </TitleSection>
        <ProfileParagraf>{phone}</ProfileParagraf>
      </TitleBlockProfile>
      <Divider/>
      <ProfileParagraf>
        Ваше имя и фото будут отображаться в записи на прием виджет
      </ProfileParagraf>
      <TitleBlockProfile className={['mt-7']}>
        <TitleSection>
          EMAIL
        </TitleSection>
        <ProfileParagraf>{email}</ProfileParagraf>
      </TitleBlockProfile>
      <Divider/>
      <ProfileParagraf>
        Свяжитесь с нами по электронной почте или в чате, если вы
        хотите изменить ваш адрес электронной почты
      </ProfileParagraf>
    </Card>
  )
}

export default SettingProfileGrid
