import * as API from 'constants/api'
import axios from 'axios'
import { compose, curry, includes, isNil, path, prop } from 'ramda'
import { deleteCookie, getCookie } from './cookie'
import responseToJSON from './responseToJSON'

// Request unauthorized status
const UNAUTHORIZED = 401
const FORBIDDEN = 403

// GetSuccess
export const getPayloadFromSuccess = (res) => prop('data', res)

// GetError
export const getPayloadFromError = compose(data => !isNil(data) && Promise.reject(data), path(['response', 'data']))

// ErrorInterceptors
const errorInterceptors = curry((dispatch, error) => {
  const status = path(['response', 'status'], error)
  if (includes(status, [FORBIDDEN, UNAUTHORIZED]) && dispatch) {
    deleteCookie('token')
  }
  return Promise.reject(error)
})
const axiosRequest = ({ getState, dispatch, getToken }: any | unknown, noCamelCase = false): any => {
  const state = getState && getState()
  const token = path(['login', 'data', 'token'], state) || getCookie('token') || getToken
  axios.defaults.baseURL = API.ROOT_URL
  axios.defaults.timeout = 100000
  if (noCamelCase) {
    axios.defaults.transformResponse = [responseToJSON]
  }

  if (token) {
    axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : ''
  } else {
    axios.defaults.headers.common = {}
  }
  axios.interceptors.response.use(response => response, errorInterceptors(dispatch))
  return axios
}

export default axiosRequest
