import React from 'react'
import { useStore } from 'react-redux'
import PropTypes from 'prop-types'
import {
  getOption,
  getOptions,
  defaultGetText,
  defaultGetValue, createOption, getStaticOptions, getStaticOption
} from 'utils/searchField'
import MultiSearchField from './MultiSearchField'

const UniversalMultiSearchField = props => {
  const { api, params, getOptionsCustom, list, itemText, multi, isStatic, ...rest } = props
  const store = useStore()
  if (isStatic) {
    return (
      <MultiSearchField
        isStatic={isStatic}
        getText={(a) => {
          return defaultGetText(itemText)(a)
        }}
        getValue={defaultGetValue(['_id'])}
        getOptions={search => getStaticOptions(search, list)}
        getOption={id => getStaticOption(id, list)}
        isMulti={true}
        itemText={itemText}
        {...rest}
      />
    )
  }
  return (
    <MultiSearchField
      getText={(a) => {
        return defaultGetText(itemText)(a)
      }}
      getValue={defaultGetValue(['_id'])}
      getOptions={search => {
        if (getOptionsCustom) {
          return getOptionsCustom(store, { api, params, search })
        } else {
          return getOptions(store, { api, params, search })
        }
      }}
      getOption={getOption(store, { api, params })}
      isMulti={true}
      itemText={itemText}
      {...rest}
    />
  )
}

UniversalMultiSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  params: PropTypes.object,
  itemText: PropTypes.array,
}

UniversalMultiSearchField.defaultProps = {
  itemText: ['name'],
  multi: false
}

export default UniversalMultiSearchField
