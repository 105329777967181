import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { range } from 'ramda'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Divider } from 'primereact/divider'
import { Notification } from './notification'

type Filter = 'all' | 'unread' | 'read'
export const Notifications = () => {
  const [page, setPage] = useState(1)
  const [allData, setAllData] = useState([])
  const notifications = {
    next: null,
    isLoading: false,
    results: []
  }

  useEffect(() => {
    if (page === 1 && !notifications.isLoading) {
      setAllData((prevData) => [...prevData, ...notifications.results])
    } else {
      setAllData(notifications.results)
    }
  }, [notifications.next])

  return (
    <div className={'flex overflow-y-auto flex-1 flex-column'}>
      <ul className="list-none p-3 m-0">
        {range(1, 20).map(
          (value, key) => (
            <>
              <Notification value={value} key={key}/>
              <Divider/>
            </>
          )
        )}
      </ul>
      {notifications.isLoading ? (
        <div>
          <ProgressSpinner style={{ width: '1.3rem', height: '1.3rem' }}/>
        </div>
      ) : (
        <div style={{ width: '100%', height: '20px' }}/>
      )}
    </div>
  )
}

export default Notifications
