import * as stateNames from 'constants/states'
import * as actionTypes from 'constants/actionTypes'
import createThunkReducer from 'utils/createThunkReducer'

export default {
  [stateNames.SMS_CREATE]: createThunkReducer(actionTypes.SMS_CREATE),
  [stateNames.SMS_LIST]: createThunkReducer(actionTypes.SMS_LIST),
  [stateNames.SMS_DELETE]: createThunkReducer(actionTypes.SMS_DELETE),

  [stateNames.SMS_TEMPLATES_CREATE]: createThunkReducer(actionTypes.SMS_TEMPLATES_CREATE),
  [stateNames.SMS_TEMPLATES_DELETE]: createThunkReducer(actionTypes.SMS_TEMPLATES_DELETE),
  [stateNames.SMS_TEMPLATES_LIST]: createThunkReducer(actionTypes.SMS_TEMPLATES_LIST),
}
