import React from 'react'

const StatusBlock = ({ children }) => {
  return (
    <div
      className={'flex align-items-center justify-content-center mr-3'}
    >
      {children}
    </div>
  )
}

export default StatusBlock
