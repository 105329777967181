import * as stateNames from 'constants/states'
import * as actionTypes from 'constants/actionTypes'
import createThunkReducer from 'utils/createThunkReducer'

export default {
  [stateNames.CALENDAR_CREATE]: createThunkReducer(actionTypes.CALENDAR_CREATE),
  [stateNames.CALENDAR_CONFIRM]: createThunkReducer(actionTypes.CALENDAR_CONFIRM),
  [stateNames.CALENDAR_DOCTORS]: createThunkReducer(actionTypes.CALENDAR_DOCTORS),
  [stateNames.CALENDAR_LIST]: createThunkReducer(actionTypes.CALENDAR_LIST),
  [stateNames.CALENDAR_DETAIL]: createThunkReducer(actionTypes.CALENDAR_DETAIL),
  [stateNames.CALENDAR_DELETE]: createThunkReducer(actionTypes.CALENDAR_DELETE),
}
