import * as STATE from 'constants/states'
import { useTypedSelector } from 'etc/reducers'
import { flatten, map, path, pathOr, pipe, prop, propOr } from 'ramda'
import { getDataFromState } from 'utils/get'

// TODO
const getCodeNames = pipe(
  path<any[]>(['permissions']),
  map(prop('codename')),
)
const EMPTY_ARR = []
const useUserPerms = () => {
  const user = useTypedSelector(state => getDataFromState(STATE.USER_INFO, state))

  // TODO
  const userInfo = path<any>(['data'], user)
  const permissionList = pipe(
    pathOr(EMPTY_ARR, ['data', 'role', 'groups']),
    map(getCodeNames),
    flatten
  )(user)

  const isSuperuser = propOr(false, 'isSuperuser', userInfo)
  return [permissionList, isSuperuser]
}

export default useUserPerms
