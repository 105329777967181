import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import LabeledValue from 'components/Detail/LabeledValue'
import FlexBorderBottom from 'components/Detail/FlexBorderBottom'
import { path } from 'ramda'
import { getFullName } from '../../../../utils/get'
import dateFormat from '../../../../utils/dateFormat'

type Props = {
}
const PatientsLeftSide = ({ itemData }) => {
  const data = path(['data', 'data'], itemData)
  return (
    <Accordion activeIndex={0}>
      <AccordionTab header="Основная информация">
        <FlexBorderBottom>
          <LabeledValue label={'ФИО'}>
            {getFullName(data)}
          </LabeledValue>
        </FlexBorderBottom>
        <FlexBorderBottom>
          <LabeledValue label={'Дата рождения'}>
            {dateFormat(data?.birthdate)}
          </LabeledValue>
        </FlexBorderBottom>
      </AccordionTab>
    </Accordion>
  )
}

export default PatientsLeftSide
