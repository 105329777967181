import React from 'react'
import { divide, equals, path } from 'ramda'
import { useParams, useHistory } from 'react-router-dom'
import { usePromiseDispatch, useTypedSelector } from 'etc/reducers'
import { TGetDataFromState, TUseDeleteParams, TUseDelete } from 'types'
import {
  getDataFromState,
  getListParamsFromProps
} from 'utils/get'
import { closeConfirmDialogAction, openConfirmDialogAction } from 'components/ConfirmDialogUi/actions'
import { useFormError } from 'utils/form'
import { DEFAULT_PICK_PARAMS } from '../utils/isEquals'
import { getListParams } from './useFetchList'

const useDelete = (params: TUseDeleteParams): TUseDelete => {
  const {
    action,
    stateName,
    successAction,
    onSuccess,
    idKey = 'id',
    redirectUrl,
    modalParams,
    mapper = getListParams,
    pickParams = DEFAULT_PICK_PARAMS
  } = params

  const { mapResponseToFormError } = useFormError()
  const history = useHistory()
  const queryParams = useParams()
  const dispatch = usePromiseDispatch()
  const data = useTypedSelector<TGetDataFromState<any>>(state => getDataFromState(stateName, state), equals)

  const onConfirm = id => {
    dispatch(openConfirmDialogAction({ loading: true }))
    return dispatch(action(id))
      .then((data) => {
        dispatch(closeConfirmDialogAction())
        const paramId = path([idKey], queryParams)
        if (onSuccess) onSuccess(id)
        else if (successAction) dispatch(successAction(mapper(history, pickParams)))

        if (redirectUrl && paramId) history.replace(redirectUrl)

        return Promise.resolve(data)
      })
      .catch((error) => Promise.resolve(error)
        .then((error) => {
          dispatch(closeConfirmDialogAction())
          mapResponseToFormError(error)
        }
        ))
  }
  const onSubmit = id => {
    return dispatch(openConfirmDialogAction({
      onConfirm: () => onConfirm(id),
      ...modalParams
    }))
  }

  return { onSubmit, ...data }
}

export default useDelete
