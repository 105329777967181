import { Provider as StoreProvider } from 'react-redux'
import React, { useState } from 'react'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'assets/layout/layout.scss'
import 'primereact/resources/primereact.css'
import LayoutProvider from 'providers/layout'
import { momentConfig } from 'utils/moment'
import Routes from './routes/routes'
import store from './etc/store'
import { ToastProvider } from './context/ToastContext'
import { AppTheme, ThemeProvider } from './providers/theme/ThemeProvider'

// Moment Config
momentConfig()

// Component
const App = () => {
  return (
    <StoreProvider store={store()}>
      <AppTheme>
        <ThemeProvider>
          <ToastProvider>
            <LayoutProvider>
              <Routes />
            </LayoutProvider>
          </ToastProvider>
        </ThemeProvider>
      </AppTheme>
    </StoreProvider>
  )
}

export default App
