import React from 'react'
import { Avatar } from 'primereact/avatar'
import { filter, find, groupBy, map, pathOr, pipe, prop, propEq, values } from 'ramda'
import styled from 'styled-components'
import vrach from 'media/vrach.png'
import { GRADIENTS } from '../../../constants/constants'
import { numberFormat } from '../../../utils/numberFormat'
import { TTeeth } from '../../patients/services/ServicesProvider'
import sumBy from '../../../utils/sumBy'
import { getFullName } from '../../../utils/get'

const TotalValue = (props) => {
  return (
    <div className={'flex flex-column justify-content-center align-items-center min-w-min '} {...props}/>
  )
}

const TotalGrid = styled.div`
    display: grid;
    grid: 1fr/ min-content min-content min-content;

`

export const DoctorStatCard = ({
  data,
  index
}: any) => {
  return (
    <div className={'flex justify-content-between gap-3 card mb-0 text-0'} style={{ backgroundImage: GRADIENTS[getLastDigit(index)] }}>
      <Avatar image={vrach} size="large" shape="circle"/>
      <div className={'flex-1'}>
        <div className={'flex justify-content-between mb-4'}>
          <p className={'font-medium text-2xl'}>
            {data.doctor?.firstName ? getFullName(data.doctor) : 'Не указан'}
          </p>
        </div>
        <TotalGrid className={'gap-6'}>
          <TotalValue>
            <span className={'font-semibold'}>
              {numberFormat(data.paid)}
            </span>
            <span>
              Оплачено
            </span>
          </TotalValue>
          <TotalValue>
            <span className={'font-semibold'}>
              {numberFormat(data.debt)}
            </span>
            <span>
              Долг
            </span>
          </TotalValue>
          <TotalValue>
            <span className={'font-semibold'}>
              {numberFormat(data.paidCount)}
            </span>
            <span>
              Выполнено
            </span>
          </TotalValue>
        </TotalGrid>
      </div>
    </div>
  )
}

const Grid = styled.div`
 display: grid;
  grid: 1fr/ 1fr 1fr 1fr;
  gap: 30px;
`

function getLastDigit (number) {
  return Math.abs(number) % 10
}

export const DoctorStatGrid = ({
  statsData,
  listData
}: any) => {
  const value = pipe(
    pathOr([], ['data', 'data']),
    groupBy((item: any) => {
      return prop('doctorId', item)
    }),
    values,
    map(arr => {
      return {
        debt: sumBy('debt', arr),
        paid: sumBy('paid', arr),
        doctor: arr[0].doctor,
        paidCount: pipe(
          pathOr([], ['data', 'data']),
          filter(propEq(arr[0].doctorId, 'doctorId')),
          (arr: any) => sumBy('paid', arr)
        )(statsData)
      }
    })
  )(listData)
  return (
    <div className={'card'} style={{
      gridColumn: '1/3'
    }}>
      <Grid className={''} style={{
        gridColumn: '1/3'
      }}>
        {value.map((obj, index) => {
          return (
            <DoctorStatCard index={index} key={index} data={obj}/>
          )
        })}
      </Grid>
    </div>
  )
}
