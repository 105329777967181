import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'

const EmptyLayout = ({ children }) => {
  return (
    <PermissionWrapper permissions={['READ']}>
      {children}
    </PermissionWrapper>
  )
}

export default EmptyLayout
