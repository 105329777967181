import moment from 'moment'

export function getDaysDifference (startDate, endDate) {
  const diff = moment(endDate).diff(moment(startDate), 'days') + 1
  return diff
}

export const isDateInRange = (startDate, endDate, date) => {
  const startDateM = moment(startDate).startOf('day')
  const endDateM = moment(endDate).endOf('day')
  const checkDate = moment(date).startOf('day')
  return checkDate.isBetween(startDateM, endDateM, null, '[]')
}

export const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}
