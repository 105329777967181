import React from 'react'

const classes = ['flex', 'cursor-pointer', 'align-items-center']
const ButtonLink = ({ prefix, text, className = [], onClick }) => {
  return (
    <div className={[...classes, ...className].join(' ')} onClick={onClick}>
      <div className={'pr-4 flex align-items-center'}>
        {prefix}
      </div>
      <p>
        {text}
      </p>
    </div>
  )
}

export default ButtonLink
