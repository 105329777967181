import React from 'react'
import Drawer, { DrawerProps } from './Drawer'

const DrawerDialog = ({
  withHeader,
  children,
  onClose,
  open
}: DrawerProps) => {
  return (
    <Drawer
      withHeader={withHeader}
      onClose={onClose}
      open={open}
    >
      {children}
    </Drawer>
  )
}

export default DrawerDialog
