import { replace, length } from 'ramda'
import { numberWithoutSpaces } from './numberFormat'

const normalize = value => {
  const replaced = replace(',', '.', String(value))
  const output = replaced.replace(/[^\d.-]/g, '')

  const splitted = output.split('.')
  if (splitted.length > 2) {
    const [first, ...others] = splitted
    return first + '.' + others.filter(item => item).join('')
  }

  return output.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const normalizeNumber = (value) => {
  if (!value) return ''
  return normalize(value)
}

export const fieldNormalizeParams = {
  format: normalizeNumber,
  parse: numberWithoutSpaces
}

export const normalizePhone = (value) => {
  if (!value) return value
  const withoutSpaces = replace(/ /g, '', value)
  const onlyNumsAndSymbol = replace(/[^\d\W]/g, '', withoutSpaces)
  if (length(onlyNumsAndSymbol) <= 4) {
    // Не возможно стереть! (всегда 4 числа), но если Убрать пробел, можно стереть
    return `${onlyNumsAndSymbol.slice(0, 4)} `
  } else if (length(onlyNumsAndSymbol) <= 6) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)}`
  } else if (length(onlyNumsAndSymbol) <= 9) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)} ${onlyNumsAndSymbol.slice(6, 9)}`
  } else if (length(onlyNumsAndSymbol) <= 11) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)} ${onlyNumsAndSymbol.slice(6, 9)} ${onlyNumsAndSymbol.slice(9, 11)}`
  } else if (length(onlyNumsAndSymbol) <= 13 || length(onlyNumsAndSymbol) > 13) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)} ${onlyNumsAndSymbol.slice(6, 9)} ${onlyNumsAndSymbol.slice(9, 11)} ${onlyNumsAndSymbol.slice(11, 13)}`
  }
}

export const onlyNumsAndSymbol = (value) => {
  const withoutSpaces = replace(/ /g, '', value)
  return replace(/[^\d\W]/g, '', withoutSpaces)
}
