import {
  isEmpty,
  not,
  path,
  pathOr,
  pipe,
  intersection,
  defaultTo,
  map, flatten, uniq, includes
} from 'ramda'
import { useTypedSelector } from '../etc/reducers'
import * as STATE from '../constants/states'
import { getDataFromState } from './get'

export default (requiredPerms, permsFrom) => {
  const profile = useTypedSelector(state => getDataFromState(STATE.PROFILE, state))
  const permissionList = pipe(
    path(['data', 'data', 'roles']),
    defaultTo([]),
    map(pathOr([], ['permissions'])),
    flatten,
    uniq
  )(profile)
  const isAdmin = includes('*', permissionList)

  const mapPerms = permsFrom || permissionList

  const hasPerm = pipe(
    intersection(requiredPerms),
    isEmpty,
    not
  )(mapPerms)

  return (isAdmin || hasPerm)
}
