import React from 'react'
import PieChart from 'components/charts/PieChart'
import PieSkeleton from 'components/Loader/Skeleton/PieSkeleton'
import { pathOr } from 'ramda'
import sumBy from '../../../utils/sumBy'
import numberRound from '../../../utils/round'

const ReportDebtorsChart = ({ listData }) => {
  const data = pathOr([], ['data', 'data'], listData)

  const debtSum = sumBy(['debt'], data)
  const paidSum = sumBy(['paid'], data)

  const sumAll = debtSum + paidSum

  const debtSumP = numberRound(sumAll * debtSum / 100)
  const paidSumP = numberRound(sumAll * paidSum / 100)

  const series = [
    {
      name: 'Percentage',
      colorByPoint: true,
      data: [
        {
          name: 'Долг',
          z: debtSum,
          y: debtSumP
        },
        {
          name: 'Выплачено',
          z: paidSum,
          y: paidSumP
        }
      ]
    }
  ]
  return listData.loading ? (
    <PieSkeleton/>
  ) : (
    <PieChart listSeries={series}/>
  )
}

export default ReportDebtorsChart
