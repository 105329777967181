import React, { FC } from 'react'
import styled from 'styled-components'
import { prop } from 'ramda'
import mapIndexed from 'utils/mapIndexed'
import { BreadcrumbsTypes } from 'types'
import { Link } from 'react-router-dom'

const BreadcrumbsWrap = styled('div')`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 50px;
`

const LinkWrapper = styled('div')`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  &:hover {
    color: var(--primary-color);
  }
`

const Current = styled.h4`
  color: var(--primary-color);
  margin: 0;
`

type BreadcrumbsObject = {
  url: string
  title: string
}
const Breadcrumbs: FC<BreadcrumbsTypes> = props => {
  const { way = [], currentWay, style, styles } = props
  if (currentWay) {
    return (
      <BreadcrumbsWrap style={style}>
        {mapIndexed((item: BreadcrumbsObject, index) => {
          const url = prop('url', item)
          const title = prop('title', item)
          return (
            <LinkWrapper key={index}>
              <Link to={url}>
                <span>{title}</span>
              </Link>
            </LinkWrapper>
          )
        }, way)}
        <Current>{currentWay}</Current>
      </BreadcrumbsWrap>
    )
  }
  return null
}

Breadcrumbs.defaultProps = {
  withBorder: true
}

export default Breadcrumbs
