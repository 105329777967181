import { sprintf } from 'sprintf-js'
import axios, { getPayloadFromError, getPayloadFromSuccess } from '../../utils/axios'
import * as API from '../../constants/api'
import * as ACTION_TYPES from '../../constants/actionTypes'
import { PATIENTS_DELETE, PATIENTS_DETAIL, PATIENTS_UPDATE } from '../../constants/api'
import { PATIENTS_CALENDAR_LIST } from '../../constants/actionTypes'

export const patientsAction = params => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .post(API.PATIENTS_CREATE, params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.PATIENTS_CREATE}`,
      payload
    })
  }
}

export const patientsUpdateAction = (id, params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .put(sprintf(API.PATIENTS_UPDATE, id), params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.PATIENTS_CREATE}`,
      payload
    })
  }
}
export const patientsDeleteAction = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .delete(sprintf(API.PATIENTS_DELETE, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.PATIENTS_DELETE}`,
      payload
    })
  }
}

export const patientsDetailAction = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(sprintf(API.PATIENTS_DETAIL, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.PATIENTS_DETAIL}`,
      payload
    })
  }
}
export const patientsListAction = (params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.PATIENTS_LIST, { params })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.PATIENTS_LIST}`,
      payload
    })
  }
}

export const patientsCalendarListAction = (id) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState }, false)
      .get(API.CALENDAR_LIST, { params: { patientId: id } })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.PATIENTS_CALENDAR_LIST}`,
      payload
    })
  }
}
