import { TTeethBody, TTeethCrown, TTeethRoot, TUseTeethData, TUseTeethDataParams } from 'types'
import { TEETH_BODY_DEFAULT_PROPS, TEETH_DEFAULT_PROPS } from '../media/icons/teeth/teethData'

const useTeethData = ({
  id,
  name
}: TUseTeethDataParams): TUseTeethData => {
  const dataRoot = (field: string) => <TTeethRoot>({
    id,
    name,
    type: 'root',
    field,
    ...TEETH_DEFAULT_PROPS
  })

  const dataCrown = (field: string) => <TTeethCrown>({
    id,
    name,
    type: 'crown',
    field,
    ...TEETH_DEFAULT_PROPS
  })

  const dataBody = () => <TTeethBody>({
    id,
    name,
    type: 'body',
    ...TEETH_BODY_DEFAULT_PROPS
  })
  return {
    dataRoot,
    dataCrown,
    dataBody
  }
}

export default useTeethData
