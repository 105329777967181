import React from 'react'
import PatientsTable from './PatientsTable'

type Props = {
}
const PatientsRightSide = ({ }: Props) => {
  return (
    <PatientsTable/>
  )
}

export default PatientsRightSide
