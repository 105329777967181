import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  *:focus {
    outline: none !important;
  }

  *:active {
     outline:none !important;
  }
  
  html {
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow: unset;
    -webkit-appearance: none !important;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    overflow-y: auto;
  }
  
  :focus {
    outline: none !important;
  }
  
  p, dd, dl, figure, blockquote {
    margin: 0;
  }
  
  ul, ol{
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  th {
    font-weight: 500;
  }
  
  audio, video {
    display: block;
  }
  
  img {
    border: none;
  }
  
  
  iframe {
    border: none;
  }
  
  pre, code, kbd, samp {
    font-size: inherit;
  }
  
  a {
    background-color: transparent;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  
  a img{
    max-width: 100%;
    max-height: 100%;
  }
`
