import menus from 'constants/menus'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Container from 'components/Container'
import { append, path, prop } from 'ramda'
import { useHistory } from 'react-router'
import checkRole from '../../utils/checkRole'
import { OPEN_NOTIFICATION } from '../../constants/constants'
import useModal from '../../hooks/useModal'
import { appendParamsToUrl, paramsToSearch, parseParams } from '../../utils/url'
import { addParamsRoute } from '../../utils/route'

const Nav = styled('nav')`
  & i {
    margin-bottom: 4px;
    font-size: 30px;
  };
  .isActive {
    font-weight: 700;
    color: var(--primary-color);
  }
`

const navClasses = ['flex', 'flex-column', 'justify-content-center', 'align-items-center']

const AppBottomBar = ({ }) => {
  const modal = useModal({ key: OPEN_NOTIFICATION })
  const history = useHistory()
  const search = history.location.search
  const params = parseParams(search)
  return (
    <div className="layout-bottombar">
      <Container>
        <Nav className={'flex justify-content-around'}>
          {menus.map(menu => {
            const isRole = menu?.role ? checkRole(menu?.role) : true
            const query = menu?.withParams ? paramsToSearch({ ...params, ...prop('query', menu) }) : paramsToSearch(prop('query', menu))
            return isRole && (
              <NavLink
                to={query ? `${menu.url || ''}?${query}` : menu.url}
                exact={menu?.exact}
                className={isActive => {
                  if (path(['query', OPEN_NOTIFICATION], menu)) {
                    const classes = modal.open ? append('isActive', navClasses).join(' ') : navClasses.join(' ')
                    return classes
                  } else {
                    const classes = isActive ? append('isActive', navClasses).join(' ') : navClasses.join(' ')
                    return classes
                  }
                }}
                key={menu.key}
              >
                {menu.icon}
                <span>{menu.label}</span>
              </NavLink>
            )
          })}
        </Nav>
      </Container>
    </div>
  )
}

export default AppBottomBar
