import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { YMaps, Map, SearchControl, Placemark } from 'react-yandex-maps'
import Button from 'components/Button/Button'
import { Dialog } from 'primereact/dialog'
import { path } from 'ramda'
import { MapPin } from 'react-feather'
import Pin from '../../../media/icons/Pin'

const API_KEY = 'bc8fdb09-3efc-4819-8ca5-2c1d7f7708d2'

const ButtonUI = styled(Button)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`

const CloseButton = styled.div`
  cursor: pointer;
  padding: 8px;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
`

const Container = styled.div`
  height: 532px;
    position: relative;
`
const ModalWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  height: 560px;
`
const PinUI = styled.div`
  position:absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.palette.red};
  z-index: 10;
    & svg {
    }
`
const AddressBar = styled.div`
  max-width: ${props => props.loading ? '45px' : '700px'};
  transition: max-width 300ms;
  white-space: nowrap;
  position: absolute;
  z-index: 10;
  bottom: 90px;
  padding: 10px 20px;
  background: #fff;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 4px 15px 0 rgba(190, 195, 204, 0.77);
`
const LAT = 0
const LON = 1

const YandexMap = (props) => {
  const {
    onChange,
    initialPosition,
    open,
    onToggle,
    onAddressChange,
    addressValue,
    readOnly
  } = props

  const [loading, setLoading] = useState(false)
  const [map, setMap] = useState(initialPosition)

  const onAddress = (coords) => {
    setLoading(true)
    map.geocode(coords)
      .then((res) => {
        const firstGeoObject = res.geoObjects.get(0)
        onAddressChange(firstGeoObject.getAddressLine())

        const location = {
          lat: coords[LAT],
          lon: coords[LON]
        }
        onChange && onChange(location)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const onCenter = (val) => {
    const newCenter = path(['originalEvent', 'newCenter'], val)
    if (!readOnly) onAddress(newCenter)
  }

  return (
    <Dialog header={'Указать на карте'} visible={open} style={{ width: '600px', zIndex: 999 }} baseZIndex={996} maskStyle={{ zIndex: 900 }} onHide={onToggle} position={'top'}>
      <Container>
        <ModalWrapper>
          <MapWrapper>
            {!readOnly && (
              <PinUI>
                <Pin/>
              </PinUI>
            )}
            {!(!addressValue && !loading) && (
              <AddressBar loading={loading}>
                {loading ? '...' : addressValue}
              </AddressBar>
            )}

            {!readOnly && (
              <ButtonUI onClick={onToggle}>
                Сохранить
              </ButtonUI>
            )}

            <YMaps query={{ apikey: API_KEY, lang: 'ru_RU', mode: 'debug' }} preload={true}>
              <Map
                height="560px"
                width="100%"
                onLoad={setMap}
                modules={['geocode']}
                onBoundsChange={onCenter}
                defaultState={{ center: initialPosition, zoom: 12, controls: [] }}>
                <SearchControl options={{ float: 'left' }} />
                {readOnly && <Placemark geometry={initialPosition} />}
              </Map>
            </YMaps>
          </MapWrapper>
        </ModalWrapper>
      </Container>
    </Dialog>
  )
}

YandexMap.propTypes = {
  onChange: PropTypes.func,
  open: PropTypes.bool,
  readOnly: PropTypes.bool,
  onToggle: PropTypes.func,
  onAddressChange: PropTypes.func,
  addressValue: PropTypes.any,
  initialPosition: PropTypes.array
}

export default YandexMap
