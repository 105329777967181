export default (theme, params, type) => {
  const controlButton = (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: null,
    cursor: 'pointer',
    borderRadius: '38px',
    fontWeight: 700,
    height: params.height,
    padding: '0px 0px',
    '&:hover': {
    }
  })

  const controlButtonSimple = (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: null,
    cursor: 'pointer',
    borderRadius: '38px',
    border: 'none',
    fontWeight: 700,
    height: params.height,
    padding: '0px 0px 0px 40px',
    '&:hover': {
      border: 'none'
    }
  })
  return {
    control: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isFocused ? 'white' : theme.background.secondary,
      boxShadow: null,
      borderColor: '#ced4da',
      minHeight: '40px',
      '&:hover': {
      }
    }),
    indicatorSeparator: () => ({}),
    loadingIndicator: (provided, state) => ({
      ...provided,
      '& span': {
      }
    }),
    clearIndicator: provided => ({
      ...provided,
      display: 'flex',
      padding: '0'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      alignItems: 'center',
      padding: '0 12px',
      transition: 'color 300ms, transform 150ms',
      transform: params.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)'
    }),
    menuPortal: provided => ({
      ...provided,
      zIndex: 1500
    }),
    menu: provided => ({
      ...provided,
      border: '1px solid',
      borderColor: 'rgba(91, 101, 121, 0.12)',
      borderRadius: '10px',
      margin: '0',
      top: 'calc(100% + 4px)'
    }),
    menuList: provided => ({
      ...provided,
      padding: '7px'
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#EBECFC' : state.isFocused ? '#F5F6FD' : 'none',
      borderRadius: '10px',
      color: 'inherit',
      cursor: 'pointer',
      padding: '12px',
      '&:active': {
        background: '#F5F6FD'
      }
    }),
    valueContainer: (provided, state) => {
      const isMultiWithValues = state.hasValue && state.isMulti
      return {
        ...provided,
        padding: params.labelPrefix
          ? '0 20px 0px 170px'
          : isMultiWithValues
            ? '4px'
            : '0 20px'
      }
    },
    singleValue: provided => ({
      ...provided,
      fontSize: '16px',
      color: 'inherit'
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '16px',
      margin: '0'
    }),
    noOptionsMessage: provided => ({
      ...provided,
    }),
    loadingMessage: provided => ({
      ...provided,
    }),

    multiValue: (provided, state) => {
      const hasValueFocused = state.hasValue && params.menuIsOpen
      return {
        ...provided,
        backgroundColor: hasValueFocused ? '#FAFBFB' : '#FAFBFB',
        border: '1px solid transparent',
        borderRadius: '10px',
        margin: '4px',
        '&:hover': {
        }
      }
    },
    multiValueLabel: provided => ({
      ...provided,
      fontSize: 'inherit',
      padding: '7px 0',
      paddingLeft: '12px',
      paddingRight: '6px'
    }),
    multiValueRemove: provided => ({
      ...provided,
      alignSelf: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
      justifyContent: 'center',
      paddingLeft: '0',
      paddingRight: '0',
      marginRight: '12px',
      height: '20px',
      width: '20px',
      '&:hover': {
        backgroundColor: '#8591A3',
        color: 'white'
      },
      '& svg': {
        height: '16px',
        width: '16px'
      }
    })
  }
}
