import React from 'react'
import { path, propOr } from 'ramda'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Field } from 'react-final-form'
import { InputTextUI } from '../index'

const TimeListField = props => {
  const { fields, isUpdate, onCreate } = props
  const onAdd = () => isUpdate ? onCreate() : fields.push({})
  const onRemove = index => fields.remove(index)
  const values: [] = propOr([], 'value', fields)
  const startTemplate = (item, { rowIndex }) => {
    return (
      <Field
        component={InputTextUI}
        name={`workingTime[${rowIndex}].startTime`}
        type={'time'}
      />
    )
  }

  const endTemplate = (item, { rowIndex }) => {
    return (
      <Field
        component={InputTextUI}
        name={`workingTime[${rowIndex}].endTime`}
        type={'time'}
      />
    )
  }
  const DeleteButton = (item, { rowIndex }) => {
    return (
      <Button icon="pi pi-trash" className="p-row-editor-init p-link" type={'button'} onClick={() => onRemove(rowIndex)} />
    )
  }
  return (
    <div>
      <DataTable value={values} >
        <Column body={startTemplate} header={'Начало'} style={{ width: '45%' }} />
        <Column body={endTemplate} header={'Окончание'} style={{ width: '45%' }} />
        <Column header={<Button icon="pi pi-plus" type={'button'} onClick={onAdd} />} headerStyle={{ width: '10%', minWidth: '8rem' }} body={DeleteButton}/>
      </DataTable>
    </div>
  )
}

export default TimeListField
