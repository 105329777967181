import React, { Fragment, ReactNode, ReactElement, useEffect } from 'react'
import { onMessage } from 'firebase/messaging'
import { MessagePayload } from '@firebase/messaging'
import { useToast } from '../context/ToastContext'

import { messaging } from '../notification/firebase'
import checkRole from '../utils/checkRole'

interface Props {
  children: ReactNode
}
export default function NotificationWrapper (props: Props): ReactElement {
  const { children } = props
  const isDirector = checkRole(['superadmin'])

  const { toastRef } = useToast()

  useEffect(() => {
    if (isDirector) {
      onMessage(messaging, (payload: MessagePayload) => {
        toastRef.current.show({ severity: 'info', summary: payload?.notification?.title, detail: payload?.notification?.body, life: 3000 })
      })
    }
  }, [])

  return (
    <Fragment>
      {children}
    </Fragment>
  )
}
