import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Container from 'components/Container'
import styled from 'styled-components'
import { CustomerService } from './test'
import './table.scss'

const StyledCont = styled(Container)`
  padding: 0px calc(35px - 3rem); 
`

const Table = () => {
  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [customers, setCustomers] = useState(null)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedCustomers, setSelectedCustomers] = useState(null)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null
  })
  const customerService = new CustomerService()

  let loadLazyTimeout = null

  useEffect(() => {
    loadLazyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true)

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout)
    }

    // imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      customerService.getCustomersLarge({ lazyEvent: JSON.stringify(lazyParams) }).then(response => {
        setCustomers(response.data)
        setTotalRecords(response.data.length)
        setLoading(false)
      })
    }, Math.random() * 1000 + 250)
  }

  const onPage = (event) => {
    setLazyParams(event)
  }

  const onSort = (event) => {
    setLazyParams(event)
  }

  const onFilter = (event) => {
    event.first = 0
    setLazyParams(event)
  }

  const onSelectionChange = (event) => {
    const value = event.value
    setSelectedCustomers(value)
    setSelectAll(value.length === totalRecords)
  }

  const onSelectAllChange = (event) => {
    const selectAll = event.checked

    if (selectAll) {
      customerService.getCustomersLarge({}).then(response => {
        setSelectAll(true)
        setSelectedCustomers(response.data)
      })
    } else {
      setSelectAll(false)
      setSelectedCustomers([])
    }
  }

  return (
    <StyledCont>
      <div className="card">
        <DataTable
          value={customers}
          lazy
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          first={lazyParams.first}
          rows={10}
          totalRecords={totalRecords}
          onPage={onPage}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          onFilter={onFilter}
          // @ts-ignore
          filters={lazyParams.filters}
          loading={loading}
          selection={selectedCustomers}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
          <Column field="name" header="Name" sortable />
          <Column field="country.name" sortable header="Country" filterField="country.name" />
          <Column field="company" sortable header="Company" />
        </DataTable>
      </div>
    </StyledCont>
  )
}

export default Table
