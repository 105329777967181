import { filter, map, pipe, prop, propOr } from 'ramda'

type TTime = {
  startTime: string;
  endTime: string;
}
export const mapTimes = pipe(
  propOr([], 'workingTime'),
  map((item: TTime): TTime => {
    return {
      startTime: item.startTime,
      endTime: item.endTime
    }
  }),
  // @ts-ignore
  filter((item: TTime) => item?.startTime && item.endTime)
)
