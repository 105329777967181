import { useHistory } from 'react-router-dom'
import { replaceParamsRoute } from 'utils/route'
import { getBooleanFromHistory, getParamFromHistory } from 'utils/get'
import { TUseModal, TUseModalParams } from 'types/hooks'

export default (params: TUseModalParams): TUseModal => {
  const {
    key = 'modal',
    isBoolean = true,
    autoClose = false
  } = params
  const history = useHistory()

  const open: boolean | any = isBoolean ? getBooleanFromHistory(key, history) : getParamFromHistory(key, history)
  const onClose = () => {
    replaceParamsRoute({ [key]: false }, history)
  }

  const onOpen = () => {
    replaceParamsRoute({ [key]: true }, history)
  }

  const onSubmit = (event) => params.onSubmit && (autoClose
    ? params.onSubmit(event).then(() => onClose())
    : params.onSubmit(event, onClose)
  )

  return { onClose, onOpen, onSubmit, open }
}
