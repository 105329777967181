import { TTeethStatuses } from '../../../types/enums'

export const TEETH_ROOT_PROPS = {
  fill: '#FFF383',
  selectedFill: '#c5c6f7',
  pendingFill: '#6366F1',
  savedFill: '#f9c37d'
}

export const TEETH_CROWN_PROPS = {
  fill: 'transparent',
  selectedFill: '#c5c6f7',
  pendingFill: '#6366F1',
  savedFill: '#FF0000'
}

export const TEETH_BODY_PROPS = {
  selectedFill: '#c5c6f7',
  pendingFill: '#6366F1',
  savedFill: '#FF0000'
}

export const TEETH_DEFAULT_PROPS = {
  services: [],
  status: TTeethStatuses.SAVED,
}

export const TEETH_BODY_DEFAULT_PROPS = {
  services: [],
  status: null,
}
