import { BOTTOM_BAR_HEIGHT, TOP_BAR_HEIGHT } from 'constants/constants'
import React from 'react'
import AppBottomBar from 'components/AppBottomBar'
import styled from 'styled-components'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import NotificationContainer from '../../containers/notification/NotificationContainer'

const Wrapper = styled.div<{wrapperStyles?: any, fill?: boolean}>`
  padding: ${TOP_BAR_HEIGHT} 0px ${BOTTOM_BAR_HEIGHT} 0px;
  height: 100%;
  ${({ wrapperStyles }) => wrapperStyles}
`
interface Props {
  children: JSX.Element
  wrapperStyles?: any
  fill?: boolean
}
const Layout = ({ children, wrapperStyles, fill }: Props) => {
  return (
    <>
      <PermissionWrapper permissions={['READ']}>
        <NotificationContainer/>
        <Wrapper wrapperStyles={wrapperStyles} fill={fill}>
          {children}
        </Wrapper>
      </PermissionWrapper>
      <AppBottomBar
      />
    </>
  )
}

export default Layout
