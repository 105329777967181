import {
  isEmpty,
  not,
  path,
  pathOr,
  pipe,
  intersection,
  defaultTo,
  map, flatten, uniq, includes, pluck
} from 'ramda'
import { useTypedSelector } from '../etc/reducers'
import * as STATE from '../constants/states'
import { getDataFromState } from './get'

export default (requiredRoles, permsFrom) => {
  const profile = useTypedSelector(state => getDataFromState(STATE.PROFILE, state))
  const roleList = pipe(
    path(['data', 'data', 'roles']),
    defaultTo([]),
    pluck('name'),
    uniq
  )(profile)

  const hasRole = pipe(
    intersection(requiredRoles),
    isEmpty,
    not
  )(roleList)
  return (hasRole)
}
