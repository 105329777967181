import { getSerializedData } from 'utils/get'
import { path, prop } from 'ramda'
import { fields } from '../components/SettingClinicsCreateDrawer'

export const clinicsCreateSerializer = params => {
  const serialized = getSerializedData(fields, params)
  const endTime = prop('endTime', params)
  const startTime = prop('startTime', params)
  return {
    ...serialized,
    location: {
      type: 'point',
      address: path(['address', 'address'], params),
      coordinates: [path(['address', 'location', 'lat'], params), path(['address', 'location', 'lon'], params)]
    },
    workingTime: [{
      endTime,
      startTime
    }]
  }
}
