import { equals, path } from 'ramda'
import { useParams, useHistory } from 'react-router-dom'
import { usePromiseDispatch, useTypedSelector } from 'etc/reducers'
import { TGetDataFromState, TUseDeleteParams, TUseDelete } from 'types'
import {
  getDataFromState,
  getListParamsFromProps
} from 'utils/get'
// @ts-ignore
import { closeConfirmDialogAction, openConfirmDialogAction } from 'components/ConfirmDialogUi/actions'
import { useFormError } from 'utils/form'
import { useToast } from '../context/ToastContext'

const useConfirm = (params: TUseDeleteParams): {
  data: any;
  onSubmit: (id, item) => Promise<any>;
  failed: boolean;
  loading: boolean
} => {
  const {
    action,
    stateName,
    successAction,
    onSuccess,
    idKey = 'id',
    redirectUrl,
    modalParamsItem = () => ({}),
    toastParams,
    modalParams = { title: 'Подтверждение', message: 'Вы уверены?' }
  } = params
  const { mapResponseToFormError } = useFormError()
  const history = useHistory()
  const queryParams = useParams()
  const dispatch = usePromiseDispatch()
  const { toastRef } = useToast()

  const data = useTypedSelector<TGetDataFromState<any>>(state => getDataFromState(stateName, state), equals)

  const onConfirm = id => {
    dispatch(openConfirmDialogAction({ loading: true }))
    return dispatch(action(id))
      .then(() => dispatch(closeConfirmDialogAction()))
      .then(() => {
        const paramId = path([idKey], queryParams)
        const listParams = getListParamsFromProps({ history })
        toastRef.current.show({ severity: 'success', summary: 'Успех!', detail: '', life: 3000 })
        if (onSuccess) {
          onSuccess(id)
        } else if (paramId) { // @ts-ignore
          history.replace(redirectUrl)
          // @ts-ignore
        } else dispatch(successAction(listParams))
      })
      .catch((error) => Promise.resolve(error)
        .then((error) => {
          dispatch(closeConfirmDialogAction())
          mapResponseToFormError(error)
        }
        ))
  }

  const onSubmit = (id, item) => {
    return dispatch(openConfirmDialogAction({
      onConfirm: () => onConfirm(id),
      type: 'confirm',
      ...modalParams,
      ...modalParamsItem(item)
    }))
  }

  return { onSubmit, ...data }
}

export default useConfirm
