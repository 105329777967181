import { getSerializedData } from 'utils/get'
import { fields } from '../components/SettingPersonCreateDrawer'
import { mapTimes } from '../utils'

export const settingPersonCreateSerializers = params => {
  const serialized = getSerializedData(fields, params)
  return {
    ...serialized,
    workingTime: mapTimes(params)
  }
}

export const passwordCreateSerializers = params => {
  return params
}
