import React from 'react'
import { Route } from 'react-router-dom'
import AppTopBar from 'components/AppTopBar'
import ButtonUI from 'components/Button/Button'
import Container from 'components/Container'
import SubscriptionBlock from 'components/SubscriptionBlock'
import styled from 'styled-components'
import { settingRoutes } from 'routes'
import SettingMenu from './SettingMenu'

const LeftSide = styled.div`
  min-width: 371px;
  margin-right: 50px;
`

const RightSide = styled.div`
  
`

const SettingGrid = ({ onLogout }) => {
  return (
    <>
      <AppTopBar
        title={'Настройки'}
        rightActions={(
          <ButtonUI label="Выход" className="p-button-danger" onClick={onLogout} />
        )}
      />
      <Container className={'flex'}>
        <LeftSide className={'card'}>
          <SubscriptionBlock />
          <SettingMenu />
        </LeftSide>
        <RightSide className={'w-full'}>
          {settingRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route?.exact}
              >
                <route.layout>
                  <route.component />
                </route.layout>
              </Route>
            )
          })}
        </RightSide>
      </Container>
    </>
  )
}

export default SettingGrid
