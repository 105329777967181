import React, { useState, useEffect } from 'react'
import { DataTable, DataTableProps } from 'primereact/datatable'
import Container from 'components/Container'
import styled from 'styled-components'
import './table.scss'
import { useHistory } from 'react-router-dom'
import { Paginator } from 'primereact/paginator'
import { replaceParamsRoute } from '../../utils/route'
import useQuery from '../../hooks/useQuery'
import { TPagination } from '../../types'
import { scrollHeightBase } from './utils'

const StyledCont = styled(Container)`
  padding: 0px calc(35px - 3rem); 
`
type Props = {
  children?: any,
  pagination?: TPagination,
} & DataTableProps<any>
const TableUI = ({
  children,
  header,
  className = 'card',
  pagination,
  loading,
  value,
  style,
  ...props
}: Props) => {
  const history = useHistory()

  const firstQuery = Number(useQuery('first') || 0)

  const [first, setFirst] = useState(firstQuery)

  return (
    <StyledCont>
      <div className={className} style={style}>
        <DataTable
          value={value}
          lazy
          header={header}
          responsiveLayout="scroll"
          dataKey="_id"
          scrollable
          loading={loading}
          {...props}
        >
          {children}
        </DataTable>
        {pagination && <Paginator
          rows={pagination?.pageSize}
          totalRecords={pagination?.totalItems}
          first={first}
          onPageChange={(event) => {
            replaceParamsRoute({ page: Number(event.page) + 1, first: event.first }, history)
            setFirst(event.first)
          }}
        />}
      </div>
    </StyledCont>
  )
}

export default TableUI
