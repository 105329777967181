import * as stateNames from 'constants/states'
import React from 'react'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import { useDelete, useFetchItem } from '../../../hooks'
import { patientsDeleteAction, patientsDetailAction } from '../patientsActions'
import { PATIENTS_URL } from '../../../constants/routes'
import PatientsDetailGrid from './components/PatientsDetailGrid'

const Permission = (props) => (
  <PermissionWrapper
    permissions={['READ']}
    {...props}
  />
)
const PatientsDetailContainer = () => {
  const itemData = useFetchItem<any>({
    action: patientsDetailAction,
    stateName: stateNames.PATIENTS_DETAIL
  })

  const deleteData = useDelete({
    stateName: stateNames.PATIENTS_DELETE,
    action: patientsDeleteAction,
    redirectUrl: PATIENTS_URL
  })
  return (
    <>
      <Permission>
        <PatientsDetailGrid itemData={itemData} deleteData={deleteData} />
      </Permission>
    </>
  )
}

export default PatientsDetailContainer
