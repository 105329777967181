import { UPLOAD_FILE_TYPES } from 'constants/constants'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useFileUploads from './useFileUploads'

const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -999;
`

const Label = styled('label')`
  display: inline-block;
`

const Description = styled('div')`
  margin-bottom: 12px;
`

const ImageField = styled('div')`
  align-items: center;
  border: 1px solid #ced0dd;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 115px;
  transition: 200ms;
  overflow: hidden;
  width: 115px;
  &:hover {
    border-color: grey;
  }
`

const Image = styled('div')`
  background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
`

const Placeholder = styled('div')`
  color: lightgrey;
  font-weight: 500;
  text-align: center;
  & svg {
    color: #d3d5db;
    font-size: 38px;
    display: block;
    margin: 0 auto 7px;
  }
`
const Img = styled.img`
  width: 70px;
  height: 70px;
`
const ImageUploadField = props => {
  const {
    label,
    required,
    input: { name, value },
    meta,
    accept,
    onSuccess
  } = props
  const [state, onChange] = useFileUploads({ input: props.input, onSuccess })
  const { loading } = state
  const inputId = `imageInput-${name}`
  const src = (value?.baseUrl ? `${value?.baseUrl}/${value?.fileName}` : value) || ''
  return (
    <div>
      <Description>
        <Label required={required}>
          {label}
        </Label>
      </Description>
      <Input
        onChange={onChange}
        required={value ? false : required}
        type="file"
        id={inputId}
        accept={UPLOAD_FILE_TYPES.IMAGES}
      />
      <Label htmlFor={inputId}>
        <ImageField>
          {src ? (
            <Image url={src} />
          ) : (
            <Placeholder>
              <div>{loading ? 'loading...' : <i className="text-5xl text-color-secondary pi pi-camera"></i>}</div>
            </Placeholder>
          )}
        </ImageField>
      </Label>
    </div>
  )
}

ImageUploadField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  accept: PropTypes.string
}

export default ImageUploadField
