import React from 'react'
import routes from 'routes'
import MultiRouter from 'routes/MultiRouter/MultiRouter'
import { BrowserRouter } from 'react-router-dom'

// Component
const Routes = () => {
  return (
    <BrowserRouter>
      {routes.map((route, key) => (
        <MultiRouter key={key} {...route} />
      ))}
    </BrowserRouter>
  )
}

export default Routes
