// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken } from 'firebase/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBRCPxy0GYymL7bvUx9OHr8icsCfPkeJEQ',
  authDomain: 'aqtish-9a99b.firebaseapp.com',
  projectId: 'aqtish-9a99b',
  storageBucket: 'aqtish-9a99b.appspot.com',
  messagingSenderId: '185088501143',
  appId: '1:185088501143:web:885c584204fefd13cd90d2',
  measurementId: 'G-7F661NX4DL'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)
const analytics = getAnalytics(app)
