import * as API from 'constants/api'
import { CLINICS_LIST } from 'constants/api'
import React from 'react'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DateUI from 'components/Form/DateUI'
import { InputTextAreaUI, InputTextUI } from 'components/Form'
import { DrawerBottomActions, DrawerDialog } from 'components/Drawer'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { TOnSubmit } from '../../../types'
import { normalizePhone } from '../../../utils/normalize'
import { UniversalSearchField, UniversalStaticSelectField } from '../../../components/Form/Select'
import { ButtonUI } from '../../../components/Button'
import { getFullName } from '../../../utils/get'
import { GENDERS, USER_STATUS_LIST } from '../../../constants/constants'
import ClientListField from '../../../components/Form/FieldArray/ClientListField'

interface Props {
  open: boolean;
  initialValues?: Record<string, any>;
  onClose: () => void;
  onSubmit: TOnSubmit;
  loading: boolean;
  failed: boolean;
}

export const fields = [
  'firstName',
  'lastName',
  'gender',
  'address',
  'phoneNumber',
  'birthdate',
  'doctorId',
  'clinicId',
]
const PatientsCreateDrawer = (props: Props) => {
  const { open, onClose, onSubmit, initialValues, loading } = props
  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <>
                <div className="p-fluid">
                  <FieldWrapper>
                    <Field
                      component={InputTextUI}
                      name={'firstName'}
                      required
                      label={'Имя'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      component={InputTextUI}
                      name={'lastName'}
                      required
                      label={'Фамилия'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      component={InputTextAreaUI}
                      name={'address'}
                      label={'Адресс'}
                    />
                  </FieldWrapper>
                  <FieldArray
                    name="phoneNumbers"
                    component={ClientListField}
                  />
                  <FieldWrapper>
                    <Field
                      component={DateUI}
                      name={'birthdate'}
                      required
                      label={'День рождения'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      label="Гендер"
                      name="gender"
                      required
                      list={GENDERS}
                      component={UniversalStaticSelectField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      label="Доктор"
                      name="doctorId"
                      required
                      api={API.DOCTORS_LIST}
                      itemText={['email']}
                      getText={(obj) => getFullName(obj)}
                      component={UniversalSearchField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      label="Клиника"
                      required
                      name="clinicId"
                      api={API.CLINICS_LIST}
                      component={UniversalSearchField}
                    />
                  </FieldWrapper>
                  <DrawerBottomActions>
                    <ButtonUI
                      type={'submit'}
                      loading={loading}
                      severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                    </ButtonUI>
                  </DrawerBottomActions>
                </div>
              </>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
}

export default PatientsCreateDrawer
