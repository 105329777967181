import * as ROUTES from 'constants/routes'
import { SETTING_SMS_TEMPLATES_URL, SMS_MESSAGES_URL } from 'constants/routes'
import React from 'react'

export const MENU_KEYS = {}

export type MenusTypesObject = {
  label: string
  key: string
  url: string
  exact: boolean
  isAuth: boolean
  children?: JSX.Element
}

export type MenusSettingObject = {
  label: string
  menus: MenusTypesObject[]
}

export default [
  {
    label: 'Мой аккаунт',
    menus: [
      {
        label: 'Профиль',
        exact: true,
        url: ROUTES.SETTING_PROFILE_URL,
        isAuth: true
      },
      {
        label: 'Пароль',
        exact: true,
        url: ROUTES.SETTING_PASSWORD_URL,
        isAuth: true
      }
    ]
  },
  {
    label: 'Клиника',
    menus: [
      {
        label: 'Клиники',
        exact: true,
        url: ROUTES.SETTING_CLINICS_URL,
        isAuth: true
      },
      {
        label: 'SMS шаблоны',
        exact: true,
        url: ROUTES.SETTING_SMS_TEMPLATES_URL,
        isAuth: true
      },
      {
        label: 'Шаблоны сообщений',
        exact: true,
        url: ROUTES.SMS_MESSAGES_URL,
        isAuth: true
      }
    ]
  },
  {
    label: 'Пользователи',
    menus: [
      {
        label: 'Пользователи',
        exact: true,
        url: ROUTES.SETTING_PERSON_URL,
        isAuth: true
      },
      {
        label: 'Права доступа',
        exact: true,
        url: ROUTES.SETTING_ROLES_URL,
        isAuth: true
      }
    ]
  }
] as MenusSettingObject[]
