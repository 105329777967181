/* eslint-disable brace-style */
import { useHistory } from 'react-router-dom'
import { sprintf } from 'sprintf-js'
import { is, equals } from 'ramda'

export default redirectUrl => {
  const history = useHistory()

  return (value, actionType = 'push') => {
    // single value
    if (is(Array, value)) {
      if (equals(actionType, 'push')) {
        history.push(sprintf(redirectUrl, ...value))
      }
      if (equals(actionType, 'replace')) {
        history.replace(sprintf(redirectUrl, ...value))
      }
    } else if (value) {
      if (equals(actionType, 'push')) {
        history.push(sprintf(redirectUrl, value))
      }
      if (equals(actionType, 'replace')) {
        history.replace(sprintf(redirectUrl, value))
      }
    }
    // no value
    else {
      if (equals(actionType, 'push')) {
        history.push(redirectUrl)
      }
      if (equals(actionType, 'replace')) {
        history.replace(redirectUrl)
      }
    }
  }
}
