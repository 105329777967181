import { Divider } from 'primereact/divider'
import React from 'react'
import styled from 'styled-components'
import Container from 'components/Container'
import { BreadcrumbsTypes, Merge } from 'types'
import { is } from 'ramda'
import Breadcrumbs from '../Breadcrumbs'

const StyledCont = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  & h4 {
    margin-bottom: 0px;
    padding-right: 10px;
  }
  & > div {
    min-height: calc(70px - 20px);
  }
`

interface Props {
  breadcrumbs?: boolean;
  module?: any;
  title?: string | JSX.Element;
  leftActions?: JSX.Element;
  rightActions?: JSX.Element;
}
const AppTopBar = ({ title, way, leftActions, breadcrumbs, module, rightActions }: Merge<Props, BreadcrumbsTypes>) => {
  return (
    <div className={'layout-topbar'}>
      <StyledCont>
        <div className={'flex align-items-center'}>
          {breadcrumbs
            ? (
              <Breadcrumbs
                way={way}
                currentWay={title as string}
              />
            )
            : is(String, title)
              ? (
                <h4>{title}</h4>
              )
              : title}
          {leftActions && <Divider type={'solid'} layout="vertical"/>}
          <div className={'flex pl-1'}>
            {leftActions}
          </div>
        </div>
        {rightActions}
      </StyledCont>
      {module}
    </div>
  )
}

export default AppTopBar
