import React, { forwardRef } from 'react'
import { Button, ButtonProps } from 'primereact/button'

type Props = {
  classNames?: string[]
} & ButtonProps
const ButtonUI = forwardRef<HTMLElement, Props>(({ classNames = [], ...otherProps }, ref) => {
  return (
  // @ts-ignore
    <Button className={classNames.join(' ')} ref={ref} {...otherProps}/>
  )
})

export default ButtonUI
