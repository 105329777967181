import React from 'react'
import { Column } from 'primereact/column'
import TableUI from 'components/Table/TableUI'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import SearchUI from 'components/SearchUI/SearchUI'
import { path, pathOr } from 'ramda'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import { TPagination } from '../../../../types'

const Card = styled.div`
  
`
const Photo = styled.div`
  margin-right: 178px;
`

const SettingClinicsTable = ({
  listData, createDrawer, updateData,
  deleteData
}) => {
  const value = pathOr([], ['data', 'data'], listData)
  const pagination = path<TPagination>(['data', 'pagination'], listData)

  const header = () => {
    return (
      <div className="flex justify-content-between">
        {/* <Button */}
        {/*  onClick={createDrawer.onOpen} */}
        {/*  severity="success" */}
        {/* > */}
        {/*    + Новая клиника */}
        {/* </Button> */}
        <SearchUI/>
      </div>
    )
  }

  const OptionButton = (item) => {
    return (
      <Dropdown model={[
        {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            updateData.onOpen(item._id)
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => deleteData.onSubmit(item._id)
        }
      ]}/>
    )
  }

  return (
    <Card className={'w-full'}>
      <TableUI
        rowHover={true}
        loading={listData.loading}
        pagination={pagination}
        value={value}
        header={header()}
      >
        <Column field="name" header="Название" />
        <Column field="phoneNumber" header="Номер телефона" />
        <Column headerStyle={{ minWidth: '4rem' }} body={OptionButton} style={{ width: '5%' }} />
      </TableUI>
    </Card>
  )
}

export default SettingClinicsTable
