import React from 'react'
import styled, { withTheme, css } from 'styled-components'
import ReactSelect from 'react-select'

import { TSelectListItem } from 'types/constants'
import selectStyles from './selectStyles'
import MenuList from './MenuList'

const options = [
  // { value: 'cherry', label: 'Cherry' },
  // { value: 'banana', label: 'Banana' },
  // { value: 'apple', label: 'Apple' }
]

const SelectWrapper = styled('div')<{typeSelect: string, error: boolean}>`
  position: relative;
  min-width: ${({ typeSelect }) => typeSelect === 'simple' ? '100%' : (typeSelect === 'button') ? '150px' : '350px'};
`

const Sub = styled('div')`
  display: flex;
  width: min-content;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
  svg {
    max-width: 15px;
    max-height: 15px;
  }
`

const Prefix = styled(Sub)`
`

const noOptionsMessage = ({ inputValue }) => {
  if (inputValue) return `Не найдено "${inputValue}"`
  return 'Не найдено'
}

const loadingMessage = ({ inputValue }) => {
  return 'Загрузка'
}

const Select = props => {
  const {
    theme,
    label,
    prefix,
    labelPrefix,
    error,
    onChange,
    customOnChange,
    type,
    input,
    onMenuOpen,
    onMenuClose,
    required,
    height,
    isCreatable,
    onCreate,
    typeSelect,
    ...rest
  } = props
  const selectRef = React.useRef()
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)

  const handleMenuOpen = () => {
    setMenuIsOpen(true)
    if (typeof onMenuOpen === 'function') {
      onMenuOpen()
    }
  }

  const handleMenuClose = ev => {
    setMenuIsOpen(false)
    if (typeof onMenuClose === 'function') {
      onMenuClose()
    }
  }
  return (
    <SelectWrapper error={error} typeSelect={typeSelect}>
      {label && <div className={'mb-2'}>{label}</div>}
      {labelPrefix &&
        React.cloneElement(
          labelPrefix,
          menuIsOpen
            ? { onClick: handleMenuClose, htmlFor: rest.input.name }
            : { onClick: handleMenuOpen, htmlFor: 'fake' }
        )}
      {prefix && <Prefix>{prefix}</Prefix>}
      <ReactSelect
        {...input}
        ref={selectRef}
        options={options}
        label={labelPrefix || label}
        classNamePrefix={'select'}
        styles={selectStyles(theme, {
          error,
          labelPrefix,
          height,
          menuIsOpen
        }, typeSelect)}
        closeMenuOnSelect={!rest.isMulti}
        menuIsOpen={menuIsOpen}
        openMenuOnClick={type === 'select'}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        onChange={(option: TSelectListItem) => {
          if (customOnChange) {
            customOnChange(option, { input })
          } else {
            if (typeof onChange === 'function') {
              onChange(option)
            }
            if (typeof input.onChange === 'function') {
              input.onChange(option)
            }
          }
        }}
        components={{
          MenuList: menuListProps => <MenuList
            isCreatable={isCreatable}
            onCreate={onCreate}
            selectRef={selectRef}
            {...menuListProps}
          />
        }}
        {...rest}
        inputId={input.name}
      />
    </SelectWrapper>
  )
}

Select.defaultProps = {
  type: 'select',
  placeholder: 'Выберите из списка',
  isCreatable: false,
  isMulti: false
}

export default withTheme(Select)
