import React, { useRef, useState } from 'react'
import { Button, ButtonProps } from 'primereact/button'
import { useReactToPrint } from 'react-to-print'
import { Dialog } from 'primereact/dialog'

type Props = {
  classNames?: string[]
} & ButtonProps

const ButtonPrint = ({ children, classNames = [], ...otherProps }: Props) => {
  const componentRef = useRef<HTMLDivElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleOnBeforeGetContent = () => {
    setVisible(true)
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, 100)
    })
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'AwesomeFileName',
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true
  })

  return (
    <>
      <Dialog header="Header" visible={visible} style={{ width: '50vw' }} onHide={() => {
        if (!visible) return
        setVisible(false)
      }}>
        <div ref={componentRef}>
          {children}
        </div>
      </Dialog>
      <Button
        className={classNames.join(' ')}
        icon="pi pi-file-pdf"
        onClick={handlePrint}
        {...otherProps}
      />
    </>
  )
}

export default ButtonPrint
