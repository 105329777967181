import React, {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useReducer,
} from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { getToLocalStorageFilters } from './utils'

type TCalendarContext = {
    data: {
        count?: number
        next?: string | null
        previous?: string | null
        results?: any[]
    }
    commit: Dispatch<any>
    state: any
}

const CalendarListContext = createContext({
  commit: (value: any) => null,
  state: null,
})

const dateToString = (start, end) => {
  return start
    ? `${moment(start).format('yyyy-MM-dd')}T00:00:00.000Z,${moment(
      end
    ).format('yyyy-MM-dd')}T23:59:59.000Z`
    : ''
}

const actionReducer = (state, action) => ({ ...state, ...action })
export const CalendarListProvider = (props): ReactElement => {
  const { children, defaultValues } = props

  const DEFAULT_STATE = {
    weekendsVisible: true,
    activeEvent: null,
    activeEventData: {},
    initialValues: {},
    OPStyles: {},
    currentEvents: [],
    selectDateEvents: [],
    dateRange: {
      startDate: defaultValues?.dateRange?.startDate || '',
      endDate: defaultValues?.dateRange?.endDate || '',
    },
    currentDate: defaultValues?.currentDate || '',
    view: defaultValues?.view || 'dayGridMonth',
  }

  const [state, commit] = useReducer(actionReducer, DEFAULT_STATE)
  return (
    <CalendarListContext.Provider
      value={{ commit, state }}
    >
      {children}
    </CalendarListContext.Provider>
  )
}

export function useCalendarContext (): TCalendarContext {
  return useContext(CalendarListContext) as unknown as TCalendarContext
}
