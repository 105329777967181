import React, { createContext, useContext, useRef } from 'react'
import PropTypes from 'prop-types'

const ToastContext = createContext(null)

export function ToastProvider ({ children }) {
  const toastRef = useRef()

  return (
    <ToastContext.Provider value={{ toastRef }}>
      {children}
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export function useToast () {
  return useContext(ToastContext)
}
