import React from 'react'
import styled from 'styled-components'
import { path } from 'ramda'
import { InputTextUI } from '../index'

const LocationBlock = styled.div`
  display: flex;
    margin-top: 27px;
    margin-left: 15px;
  align-items: center;
  cursor: pointer;
    i {
    margin-right: 8px;
    margin-bottom: 2px;
  }
`
const LocationText = styled.div`
  font-style: normal;
    white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.96%;
  text-align: center;
  color: #2E384C;
`
const Wrapper = styled.div`
  position: relative;
`

const AddressField = props => {
  const {
    onIconClick,
    locationInputs,
    withFloat,
    name,
    label = 'Выберите адрес на карте',
    ...rest
  } = props

  const isLocation = path(['input', 'value', 'lat'], locationInputs)
  return (
    <div className="flex">
      <Wrapper>
        <InputTextUI name={name} readOnly label={label} {...rest} />
      </Wrapper>
      <LocationBlock onClick={onIconClick} withFloat={withFloat}>
        <i className="pi pi-map-marker"/>
        <LocationText>{isLocation ? 'Изменить локацию' : 'Указать на карте'}</LocationText>
      </LocationBlock>
    </div>
  )
}

// AddressField.propTypes = {
//   onIconClick: PropTypes.func,
//   input: PropTypes.any,
//   withFloat: PropTypes.bool
// }

export default AddressField
