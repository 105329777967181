import React from 'react'
import { Column } from 'primereact/column'
import { path, pathOr } from 'ramda'
import { TableUI } from 'components/Table'
import { ButtonUI } from 'components/Button'
import { sprintf } from 'sprintf-js'
import styled from 'styled-components'
import { getFullName } from '../../../utils/get'
import dateFormat from '../../../utils/dateFormat'
import Dropdown from '../../../components/Dropdown/Dropdown'
import { useRedirect } from '../../../hooks'
import { PATIENTS_DETAIL_FILES_URL, PATIENTS_DETAIL_URL } from '../../../constants/routes'
import { TPagination } from '../../../types'
import { scrollHeightBase } from '../../../components/Table/utils'

const NameBody = (item) => {
  return (
    <span>
      {getFullName(item)}
    </span>
  )
}

const Img = styled.img`
    width: 15px;
    height: 15px;
    &:not(:last-child) {
        margin-right: 5px;
    }
`
const FilesBody = (item) => {
  const fies = item?.files || []
  return (
    <div>
      {fies.map(file => <Img src={file} key={file}/>)}
    </div>
  )
}
const PatientsTable = ({ listData, deleteData, updateData }) => {
  const value = pathOr([], ['data', 'data'], listData)
  const pagination = path<TPagination>(['data', 'pagination'], listData)

  const OptionButton = (item) => {
    return (
      <Dropdown model={[
        {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            updateData.onOpen(item._id)
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => deleteData.onSubmit(item._id)
        }
      ]}/>
    )
  }

  const gotDetail = useRedirect(PATIENTS_DETAIL_URL)

  return (
    <TableUI
      selectionMode={'single'}
      rowHover={true}
      scrollHeight={scrollHeightBase}
      pagination={pagination}
      onRowClick={({ data }) => gotDetail(data._id)}
      loading={listData.loading}
      value={value}
    >
      <Column field="email" header="ФИО" body={NameBody} />
      <Column field="phoneNumber" header="ТЕЛЕФОН" />
      <Column field="email" header="ДАТА РОЖДЕНИЯ" body={(item) => dateFormat(item.birthdate)} />
      <Column field="files" header="Файлы" body={FilesBody} />
      <Column headerStyle={{ minWidth: '4rem' }} body={OptionButton} style={{ width: '5%' }} />
    </TableUI>
  )
}

export default PatientsTable
