import React from 'react'
import { TGetDataFromState, TGetDataPagination, TUseCreateModal, TUseDelete, TUseUpdateModal } from 'types'
import SettingPersonCreateDrawer from './SettingPersonCreateDrawer'
import SettingPersonTable from './SettingPersonTable'

interface Props {
  createDrawer: TUseCreateModal<any>
  listData: TGetDataFromState<TGetDataPagination<any>>
  updateData: TUseUpdateModal<any>
  deleteData: TUseDelete
}
const SettingPersonGrid = ({ createDrawer, updateData, listData, deleteData }: Props) => {
  return (
    <>
      <SettingPersonTable
        deleteData={deleteData}
        createDrawer={createDrawer}
        updateData={updateData}
        listData={listData}
      />
      <SettingPersonCreateDrawer
        {...createDrawer}
      />
      <SettingPersonCreateDrawer
        {...updateData}
      />
    </>

  )
}

export default SettingPersonGrid
