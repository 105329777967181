import React, { FC } from 'react'
import { ReactDefaultTypes, SvgIconOptional } from 'types/svgImages'
import SvgIcons from './SvgIcon'

const Pin: FC<SvgIconOptional & ReactDefaultTypes> = props => (
  <SvgIcons width="37" height="49" viewBox="0 0 37 49" fill="none" {...props}>
    <g fill="#d64a54" fillRule="evenodd">
      <path id="b" d="M18.089 0C9.133.09 1.592 6.677.357 15.488-.878 24.3 4.565 32.681 13.156 35.198L18.089 49l4.933-13.72c8.738-2.406 14.332-10.865 13.084-19.785C34.86 6.575 27.156-.05 18.09 0zm0 27.767c-5.45 0-9.867-4.388-9.867-9.8 0-5.413 4.418-9.8 9.867-9.8 5.45 0 9.867 4.387 9.867 9.8 0 2.599-1.04 5.091-2.89 6.93a9.9 9.9 0 0 1-6.977 2.87z" />
    </g>
  </SvgIcons>
)

export default Pin
