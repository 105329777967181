import React from 'react'
import { Toast } from 'primereact/toast'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useToast } from '../context/ToastContext'
import ConfirmDialogUi from '../components/ConfirmDialogUi'
import NotificationContainer from '../containers/notification/NotificationContainer'
import AuthProvider from './AuthProvider'
import NotificationWrapper from './NotificationWrapper'

const LayoutProvider = props => {
  const {
    children
  } = props
  const { toastRef } = useToast()
  return (
    <>
      <Toast ref={toastRef} position="top-left" />
      <ConfirmDialog />
      <ConfirmDialogUi />
      <AuthProvider>
        <NotificationWrapper>
          {children}
        </NotificationWrapper>
      </AuthProvider>
    </>
  )
}

export default LayoutProvider
