import equal from 'react-fast-compare'
import { getDataFromState, getListParamsFromProps } from 'utils/get'
import { useFormError } from 'utils/form'
import { useTypedSelector, usePromiseDispatch } from 'etc/reducers'
import { TUseCreateModalParams, TUseCreateModal } from 'types/hooks'
import { TGetDataFromState } from 'types'
import { useHistory } from 'react-router-dom'
import { prop } from 'ramda'
import { useToast } from '../context/ToastContext'
import useModal from './useModal'

export const onOpenModal = ({ value, params, history, onOpen }) => {
  onOpen()
  if (prop('onOpen', params)) {
    params.onOpen(params.key, value, history)
  }
}

export const onCloseModal = ({ onClose, params, history }) => {
  onClose()
  if (params.onClose) {
    params.onClose(params.key, history)
  }
}

const useCreateModal = <T>(params: TUseCreateModalParams): TUseCreateModal<T> => {
  const {
    key = 'createModal',
    action,
    stateName,
    onSuccess,
    isBoolean,
    successAction,
    autoClose,
    serializer = (data) => data
    // serializer = toSnakeCase
  } = params

  const { mapResponseToFormError } = useFormError()
  const { toastRef } = useToast()
  const { open, onOpen, onClose } = useModal({ isBoolean, key, autoClose })

  const history = useHistory()

  if (!stateName) {
    throw Error('useCreateModal hook requires stateName!')
  }

  const dispatch = usePromiseDispatch()
  const state = useTypedSelector<TGetDataFromState<T>>(state => getDataFromState(stateName, state), equal)
  const onSubmit = (values: Record<string, any>) => {
    const serializeValues = serializer(values)
    return dispatch(action(serializeValues))
      .then(data => {
        const listParams = getListParamsFromProps({ history })
        toastRef.current.show({ severity: 'success', summary: 'Успех', detail: '', life: 3000 })
        if (onSuccess) onSuccess(data, values)
        else if (successAction) dispatch(successAction(listParams))
        onClose()
        return data
      })
      .catch(mapResponseToFormError)
  }

  return {
    open,
    onOpen,
    onClose,
    onSubmit,
    ...state
  }
}

export default useCreateModal
