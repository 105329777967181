import * as stateNames from 'constants/states'
import React, { useEffect } from 'react'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import { useFetchItem } from 'hooks'
import { Field, Form } from 'react-final-form'
import { path, prop } from 'ramda'
import equal from 'react-fast-compare'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { patientsDetailAction, patientsUpdateAction } from '../patientsActions'
import useUpdate from '../../../hooks/useUpdate'
import { getDataFromState, getSerializedData } from '../../../utils/get'
import { fields } from '../components/PatientsCreateDrawer'
import { usePromiseDispatch, useTypedSelector } from '../../../etc/reducers'
import { TGetDataFromState } from '../../../types'
import PatientsFilesGrid from './components/PatientsFilesGrid'

const Permission = (props) => (
  <PermissionWrapper
    permissions={['READ']}
    {...props}
  />
)
const PatientsFilesContainer = () => {
  const dispatch = usePromiseDispatch()
  const paramsRoute: Record<string, any> = useParams()
  const itemData = useTypedSelector<TGetDataFromState<any>>(state => getDataFromState(stateNames.PATIENTS_DETAIL, state), equal)
  const updateData = useUpdate<any>({
    action: patientsUpdateAction,
    initialValues: {
      ...getSerializedData(fields, path(['data', 'data'], itemData))
    },
    stateName: stateNames.PATIENTS_CREATE
  })
  return (
    <>
      <Permission>
        <Form
          onSubmit={() => null}
          render={({ handleSubmit, values, form }) => {
            useEffect(
              () => {
                dispatch(patientsDetailAction(prop('id', paramsRoute))).then(({ value }) => {
                  form.initialize(getSerializedData(['files', ...fields], path(['data'], value)))
                })
              },
              []
            )
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name={'files'}
                  // @ts-ignore
                  component={PatientsFilesGrid}
                  updateData={updateData}
                  itemData={itemData}
                  values={values}
                />
              </form>
            )
          }}
        />
      </Permission>
    </>
  )
}

export default PatientsFilesContainer
