import { getToken } from 'firebase/messaging'
import { messaging } from '../notification/firebase'
import { useToast } from '../context/ToastContext'

async function getMessagingToken (): Promise<string> {
  try {
    await Notification.requestPermission()
    return await getToken(messaging, {
      vapidKey: 'BEMIHRj89LyBWS1KVyIgxVMcYjMsOVl76lqfIEPvhZ-h69GgNc92SWIaS7LLcIEf64fn0Wh5INO7UeWw1qa2sy4'
    })
  } catch (error) {
    return await Promise.reject(error)
  }
}

interface UseFirebaseNotify {
  getNotifyToken: () => Promise<string>
}

export default function useFirebaseNotify (): UseFirebaseNotify {
  const { toastRef } = useToast()

  function getNotifyToken (): Promise<string> {
    const hasNoAccess = !('Notification' in window)
    const isGranted = Notification.permission === 'granted'
    const isDenied = Notification.permission === 'denied'

    if (hasNoAccess) {
      toastRef.current.show({ severity: 'warning', summary: 'Внимание', detail: 'Ваш браузер не поддерживает уведомления' })
      return
    }

    if (isGranted || !isDenied) {
      return getMessagingToken()
    }
    toastRef.current.show({ severity: 'warning', summary: 'Внимание', detail: 'Уведомления отключены' })

    return Promise.resolve('')
  }

  return { getNotifyToken }
}
