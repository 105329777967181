import React, { useEffect, useRef, useState } from 'react'
import { FileUpload } from 'primereact/fileupload'
import styled from 'styled-components'
import { path } from 'ramda'
import { useDispatch } from 'react-redux'
import { sprintf } from 'sprintf-js'
import { getCookie } from '../../../utils/cookie'
import { useToast } from '../../../context/ToastContext'
import axios from '../../../utils/axios'
import { avatarAction } from '../../../containers/setting/SettingProfile/settingProfileActions/settingProfileActions'
import * as API from '../../../constants/api'

const ImageField = styled('div')`
  align-items: center;
  border: 1px solid #ced0dd;
  border-radius: 50%;
  background: rgba(79, 202, 241, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 115px;
  transition: 200ms;
  overflow: hidden;
  width: 115px;
  &:hover {
    border-color: grey;
  }
`
const Placeholder = styled('div')`
  color: lightgrey;
  font-weight: 500;
  text-align: center;
    & img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
`

const ImageUploadField = props => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const fileUploadRef = useRef(null)
  const { toastRef } = useToast()
  const imageRef = useRef()

  const uploadInvoice = async (invoiceFile) => {
    let formData = new FormData()
    formData.append('avatar', invoiceFile)
    setLoading(true)
    return axios({ dispatch }).post(props.url, formData).then(() => {
      toastRef.current.show({ severity: 'info', summary: 'Success', detail: 'Файл заагружен' })
      // Удаляем src, чтобы перезагрузить изображение
      // @ts-ignore
      imageRef.current.src = ''
      // Небольшая задержка для удаления src (например, через setTimeout)
      setTimeout(() => {
        // @ts-ignore
        imageRef.current.src = `${API.ROOT_URL}/${sprintf(API.USERS_AVATAR, props.userId)}`
      }, 1) // Задержка в 100 миллисекунд
      fileUploadRef.current.clear()
    }).catch(({ response }) => {
      toastRef.current.show({ severity: 'error', summary: 'Error', detail: response?.data?.message })
      fileUploadRef.current.clear()
    }).finally(() => setLoading(false))
  }

  const invoiceUploadHandler = ({ files }) => {
    const [file] = files
    return uploadInvoice(file)
  }
  return (
    <div className={'flex align-items-center'}>
      <ImageField className={'mr-8'}>
        <Placeholder>
          <img src={`${API.ROOT_URL}/${sprintf(API.USERS_AVATAR, props.userId)}`} ref={imageRef} />
        </Placeholder>
      </ImageField>
      <FileUpload
        mode="basic"
        name="file"
        ref={fileUploadRef}
        customUpload={true}
        disabled={loading}
        accept="image/*"
        maxFileSize={1000000}
        uploadHandler={invoiceUploadHandler}
      />
    </div>
  )
}

export default ImageUploadField
