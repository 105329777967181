import * as API from 'constants/api'
import { USER_STATUS_LIST, WEEKS } from 'constants/constants'
import React from 'react'
import arrayMutators from 'final-form-arrays'
import FieldWrapper from 'components/Form/FieldWrapper'
import { Field, Form } from 'react-final-form'
import DrawerDialog from 'components/Drawer/DrawerDialog'
import { InputTextUI } from 'components/Form'
import { TOnSubmit } from 'types'
import { normalizePhone } from 'utils/normalize'
import UniversalMultiSelectField from 'components/Form/Select/UniversalMultiSelectField'
import { FieldArray } from 'react-final-form-arrays'
import TimeListField from 'components/Form/FieldArray/TimeListField'
import { DrawerBottomActions } from 'components/Drawer'
import { ButtonUI } from 'components/Button'
import { join, pipe, pluck, prop, propOr } from 'ramda'
import { UniversalStaticSelectField } from '../../../../components/Form/Select'
import ImagesUrlField from '../../../../components/Form/ImagesUrlField'

interface Props {
  loading: boolean;
  open: boolean;
  initialValues?: any;
  onSubmit: TOnSubmit;
  onClose: () => void;
}

export const fields = [
  'firstName',
  'lastName',
  'doctorPhotos',
  'status',
  'parentName',
  'phoneNumber',
  'password',
  'email',
  'doctorId',
  'roles',
  'clinicIds',
  'workingDays'
]
const SettingPersonCreateDrawer = ({ ...otherProps }: Props) => {
  const { open, onClose, onSubmit, loading, initialValues } = otherProps
  return (
    <DrawerDialog
      withHeader={true}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, values }) => {
          const clinicIds = propOr([], 'clinicIds', values)
          const clinicIdsToParams = pipe(
            pluck('_id'),
            join(',')
            // @ts-ignore
          )(clinicIds)
          return (
            <form onSubmit={handleSubmit}>
              <>
                <div className="p-fluid">
                  <FieldWrapper>
                    <Field
                      component={InputTextUI}
                      name={'firstName'}
                      label={'Имя'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      component={InputTextUI}
                      name={'lastName'}
                      label={'Фамилия'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      component={InputTextUI}
                      name={'parentName'}
                      label={'Отчество'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      autoComplete="off"
                      name={'phoneNumber'}
                      parse={normalizePhone}
                      defaultValue={'+998'}
                      component={InputTextUI}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      required
                      component={InputTextUI}
                      name={'password'}
                      label={'Пароль'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      component={InputTextUI}
                      name={'email'}
                      label={'Email'}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      placeholder={'Статус'}
                      name="status"
                      list={USER_STATUS_LIST}
                      component={UniversalStaticSelectField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      label="Клиники"
                      name="clinicIds"
                      api={API.CLINICS_LIST}
                      component={UniversalMultiSelectField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      label="Права"
                      name="roles"
                      api={API.ROLES_LIST}
                      component={UniversalMultiSelectField}
                      parent={clinicIds}
                      params={{ clinicIds: clinicIdsToParams }}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      isStatic={true}
                      label="Рабочие дни"
                      name="workingDays"
                      list={WEEKS}
                      component={UniversalMultiSelectField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <FieldArray
                      name="workingTime"
                      component={TimeListField}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      label={'Фотогалерея'}
                      name="doctorPhotos"
                      component={ImagesUrlField}
                    />
                  </FieldWrapper>
                  <DrawerBottomActions>
                    <ButtonUI
                      type={'submit'}
                      loading={loading}
                      severity="success" size="large" classNames={['text-xl justify-content-center']} mt-auto>
                      Сохранить
                    </ButtonUI>
                  </DrawerBottomActions>
                </div>
              </>
            </form>
          )
        }}
      />
    </DrawerDialog>
  )
}

export default SettingPersonCreateDrawer
