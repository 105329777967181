import toNumber from 'utils/toNumber'

interface NumberFormatOptions {
  localeMatcher?: string;
  style?: string;
  currency?: string;
  currencyDisplay?: string;
  currencySign?: string;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

export function numberFormat (value: number | string, suffix?: string, options?: NumberFormatOptions): string {
  const formatter = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 2,
    ...options
  })

  const number = toNumber(value)
  const formattedNumber = formatter.format(number)

  if (number && suffix) return `${formattedNumber} ${suffix}`
  if (number) return formattedNumber
  return suffix ? `0 ${suffix}` : '0'
}

export const numberWithoutSpaces = value => {
  if (value) {
    return String(value).replace(/ /g, '')
  }
  return value
}
