import PermissionWrapper from 'components/Perms/PermissionWrapper'
import React from 'react'
import { path } from 'ramda'
import { useCreateModal, useDelete, useFetchList } from '../../../hooks'
import * as stateNames from '../../../constants/states'
import useUpdateModal from '../../../hooks/useUpdateModal'
import SettingClinicsGrid from './components/SettingClinicsGrid'
import {
  clinicsCreateAction, clinicsDeleteAction,
  clinicsListAction,
  clinicsUpdateAction
} from './settingClinicsActions/settingClinicsActions'
import { clinicsCreateSerializer } from './settingClinicsActions/clinicsSerializer'

const SettingClinicsContainer = props => {
  const listData = useFetchList({
    action: clinicsListAction,
    stateName: stateNames.CLINICS_LIST
  })
  const createDrawer = useCreateModal({
    serializer: (values) => clinicsCreateSerializer(values),
    action: clinicsCreateAction,
    successAction: clinicsListAction,
    stateName: stateNames.CLINICS_CREATE,
  })

  const deleteData = useDelete({
    stateName: stateNames.CLINICS_DELETE,
    action: clinicsDeleteAction,
    successAction: clinicsListAction
  })

  const updateData = useUpdateModal({
    listData,
    initialValues: (data) => {
      return {
        ...data,
        address: {
          location: {
            lat: path(['location', 'coordinates', '0'], data),
            lon: path(['location', 'coordinates', '1'], data),
          },
          address: path(['location', 'address'], data)
        },
        endTime: path(['workingTime', '0', 'endTime'], data),
        startTime: path(['workingTime', '0', 'startTime'], data)
      }
    },
    action: clinicsUpdateAction,
    stateName: stateNames.CLINICS_CREATE,
    serializer: (values) => clinicsCreateSerializer(values),
    successAction: clinicsListAction
  })

  return (
    <PermissionWrapper permissions={['READ']}>
      <SettingClinicsGrid
        listData={listData}
        createDrawer={createDrawer}
        deleteData={deleteData}
        updateData={updateData}
      />
    </PermissionWrapper>
  )
}

export default SettingClinicsContainer
